import React, { useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons"
import { ContratoDetalleSeguimientoContext } from "context/contrato/seguimiento-detalle/ContratoDetalleSeguimientoContext";
import { useModalConfirmar } from "store/global";

const ModalConfirmar = ({ title = "Mi Legajo Digital", mensaje = "¿Está seguro de realizar la acción seleccionada?", loading = false, onClickBtnCancelar, onClickBtnConfirmar }) => {

    const visible = useModalConfirmar(state => state.visible);
    const cerrarModal = useModalConfirmar(state => state.cerrarModal);

    useEffect(() => {
        return () => {
            cerrarModal();
        }
    }, []);

    const BotonesFooter = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <Button onClick={onClickBtnCancelar} disabled={loading} style={{ padding: '0px 40px' }}>
                    No
                </Button>
                <Button type="primary" loading={loading} onClick={onClickBtnConfirmar} style={{ padding: '0px 40px' }}>
                    Si
                </Button>
            </div>
        )
    }

    return (
        <Modal
            title="Mi Legajo Digital"
            open={visible}
            onCancel={onClickBtnCancelar}
            footer={<BotonesFooter />}
        >
            <QuestionCircleOutlined className="milegajo-icono-pregunta" />
            <span className="milegajo-texto-pregunta">{mensaje}</span>
        </Modal >
    );
}

export default ModalConfirmar;