import { useAtom } from "jotai";
import { asistenciaSeleccionadaAtom, expositorAtom, openModalParticipantesAtom, participantesAtom } from "../store/Store";
import { AsistenciaService } from "services/notificacion";
import { notification } from "antd";
import produce from "immer";

const useModalDetalleRegistroAsistencia = () => {

    const [open, setOpen] = useAtom(openModalParticipantesAtom);
    const [participantes, setParticipantes] = useAtom(participantesAtom);
    const [asistenciaSeleccionada, setAsistenciaSeleccionada] = useAtom(asistenciaSeleccionadaAtom);
    const [expositor, setExpositor] = useAtom(expositorAtom);

    const abrirModal = (asistencia) => {
        setAsistenciaSeleccionada(asistencia);
        setOpen(true);
    }

    const cerrarModal = () => {
        setAsistenciaSeleccionada(undefined);
        setOpen(false);
    }

    const fetchParticipantes = async () => {
        const participantes = await AsistenciaService.listarParticipantes(asistenciaSeleccionada.id);
        setParticipantes(participantes);
    };

    const fetchExpositor = async () => {
        const expositor = await AsistenciaService.buscarExpositor(asistenciaSeleccionada.id);
        setExpositor(expositor);
    }

    const notificarExpositor = async () => {
        try {
            await AsistenciaService.notificarExpositor(expositor.id);
            setExpositor(
                produce((draft) => {
                    draft.estado = "PENDIENTE";
                })
            );
            notification.success({
                message: "Se ha notificado el registro de asistencia al expositor."
            });
        } catch (error) {
            notification.error({
                message: "No se ha podido notificar el registro de asistencia. Intente nuevamente."
            });
        }

    }

    return {
        open,
        participantes,
        asistenciaSeleccionada,
        expositor,
        notificarExpositor,
        fetchParticipantes,
        fetchExpositor,
        abrirModal,
        cerrarModal
    }

}

export default useModalDetalleRegistroAsistencia;