import React, { useEffect } from 'react';
import { Provider as JotaiProvider } from "jotai";
import { Breadcrumb, Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { HomeOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import styled from '@emotion/styled';
import { useFiltroCrearPage } from './common/hooks';
import { AccionDocumento } from 'enums';

const FiltroCrearPage = () => {
    return (
        <JotaiProvider>
            <Container>
                <Ruta />
                <Formulario />
            </Container>
        </JotaiProvider>
    );
};

const Formulario = () => {
    const [form] = Form.useForm();
    const {
        tiposDocumento,
        setFormInstance,
        fetchTiposDocumento
    } = useFiltroCrearPage();

    useEffect(() => {
        setFormInstance(form);
        fetchTiposDocumento();
    }, []);

    return (
        <div style={{
            background: "white",
            padding: "20px",
            borderRadius: "10px",
            marginTop: "10px"
        }}>

            <Form form={form} layout='vertical' autoComplete='off'>
                <Form.Item label="Tipo" name="tipo" required>
                    <Select
                        options={[
                            {
                                value: 'tipo-documento',
                                label: 'Tipo Documento',
                            }
                        ]} />
                </Form.Item>
                <Form.Item label="Descripción" name="Descripción del filtro" required>
                    <Input />
                </Form.Item>
                <Typography.Title level={5} type="secondary">
                    Selecciona los permisos
                </Typography.Title>
                <Form.List name="permisos">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row key={key} gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'tipoDocumentoId']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Seleccione un documento',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                options={tiposDocumento}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'acciones']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Seleccione al menos un permiso',
                                                },
                                            ]}
                                        >
                                            <Select
                                                mode="multiple"
                                                options={[
                                                    {
                                                        value: AccionDocumento.LISTAR,
                                                        label: 'Listar',
                                                    },
                                                    {
                                                        value: AccionDocumento.VER,
                                                        label: 'Ver',
                                                    },
                                                    {
                                                        value: AccionDocumento.APROBAR,
                                                        label: 'Aprobar',
                                                    },
                                                    {
                                                        value: AccionDocumento.SUBIR,
                                                        label: 'Subir',
                                                    },
                                                    {
                                                        value: AccionDocumento.DESCARGAR,
                                                        label: 'Descargar',
                                                    },
                                                ]}
                                                // tagRender={tagRender}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Agregar Permiso
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </div>
    )
}

const Container = styled.div`
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
`

const Ruta = () => {
    return (
        <Breadcrumb style={{ marginBottom: "10px" }}>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Seguridad
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Filtro
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Crear
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default FiltroCrearPage;