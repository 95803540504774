import { useAtom } from "jotai";
import { formInstanceAtom, tiposDocumentoAtom } from "../store/Store";
import TipoDocumentoService from "services/TipoDocumentoService";

const useFiltroCrearPage = () => {

    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [tiposDocumento, setTiposDocumento] = useAtom(tiposDocumentoAtom);

    const fetchTiposDocumento = async () => {
        const tiposDocumento = await TipoDocumentoService.listarV2();
        setTiposDocumento(tiposDocumento.map(tipoDocumento => {
            return {
                label: tipoDocumento.nombre,
                value: tipoDocumento.id
            }
        }))
        console.log("tiposDocumento", tiposDocumento);

    }

    return {
        tiposDocumento,
        fetchTiposDocumento,
        setFormInstance
    }

}

export default useFiltroCrearPage;