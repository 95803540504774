import React, { useState, useContext } from "react";
import { Dropdown, Menu, notification, Modal } from "antd";
import {
  SelectOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  CloseOutlined,
  CaretDownOutlined,
  UnorderedListOutlined,
  ExclamationCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { useModalAsignarCarpeta } from "../../../../../store/global";
import { useAtom } from "jotai";
import { ModalAsignarCarpeta } from "components/Modal/ModalAsignarCarpeta";
import {
  categoriaAtom,
  documentoSeleccionadoAtom,
  enviosAtom,
  visibleModalAsignarCarpetaAtom,
  codigosOpcionesAtom,
} from "../../ListarSeguimientoStore";
import { saveAs } from "file-saver";
import { SecurityContext } from "context/SecurityContextProvider";
import DetalleEnvioService from "services/DetalleEnvioService";
import { EnvioService } from "services/EnvioService";
import { EstadoProcesoEnvio } from "enums/EstadoProcesoEnvio";
import { totalPaginacionEnviosAtom } from "store/local/seguimiento/SeguimientoStore";
import { OPCIONES } from "enums";
const { confirm } = Modal;

const MenuAcciones = ({ envio }) => {
  const { setDatosEnvio } = useContext(SecurityContext);
  const [, setCategoria] = useAtom(categoriaAtom);
  let history = useHistory();
  const [envios, setEnvios] = useAtom(enviosAtom);
  const [totalPaginacion, setTotalPaginacion] = useAtom(totalPaginacionEnviosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
  const [, setVisibleModalAsignarCarpetaAtom] = useAtom(visibleModalAsignarCarpetaAtom);
  const [codigosOpciones] = useAtom(codigosOpcionesAtom);

  const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-detalle":
        onClickMenuVerDetalle();
        break;

      case "generar-reporte-excel":
        onClickMenuGenerarReporteExcel();
        break;

      case "generar-reporte-pdf":
        onClickMenuGenerarReportePdf();
        break;

      case "eliminar":
        onClickMenuEliminar();
        break;

      case "asignar-carpeta":
        onClickMenuAsignarCarpeta();
        break;

      default:
        console.error("No se encuentra esa opción");
    }
  };

  const onClickMenuVerDetalle = () => {
    setDocumentoSeleccionado(envio);
    setCategoria(envio.categoriaDocumento.nombre);

    switch (envio.estado) {
      case EstadoProcesoEnvio.REGISTRADO:
        if (envio.categoriaDocumento.nombre === "DOCUMENTO LABORAL") {
          setDatosEnvio(
            envio.id,
            envio.tipoDocumentoNombre,
            envio.descripcion,
            envio.tipoDocumentoId,
            envio.flujo,
            envio.formatoCargaId,
            null,
            envio.listaTipoDocumentos
          );
          return history.push("/documento-laboral/listarEnvioNotificacion");
        } else if (envio.categoriaDocumento.nombre === "CONTRATOS") {
          return history.push(`/contrato/seguimiento/${envio.id}/detalle`);
        }
      case EstadoProcesoEnvio.PENDIENTE_NOTIFICAR:
        return history.push(`/contrato/seguimiento/${envio.id}/detalle`);

      default:
        return history.push(
          `/notificacion/seguimiento/detalle/${envio.categoriaDocumento.nombre
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
            .replace(/\s/g, "_")}/${envio.id}`
        );
    }
  };

  const onClickMenuAsignarCarpeta = () => {
    setDocumentoSeleccionado(envio);
    setVisibleModalAsignarCarpetaAtom(true);
  };

  const onClickMenuGenerarReporteExcel = async () => {
    try {
      //setLoading(true);
      //setFilaSeleccionada(envio.id);
      const formData = new FormData();
      formData.append("envioId", envio.id);
      formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

      let respuesta = await DetalleEnvioService.descargarExcel(formData);
      const filename = respuesta.headers.filename;

      saveAs(respuesta.data, filename);

      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.error(error);
    }
  };

  const onClickMenuGenerarReportePdf = async () => {
    try {
      //setLoading(true);
      //setFilaSeleccionada(envio.id);
      const formData = new FormData();
      formData.append("envioId", envio.id);
      formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

      let respuesta = await DetalleEnvioService.descargarPDF(formData);
      const filename = respuesta.headers.filename;

      saveAs(respuesta.data, filename);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.error(error);
    }
  };

  function onClickMenuEliminar() {
    confirm({
      title: "Estas seguro de inhabilitar el registro seleccionado?",
      icon: <ExclamationCircleOutlined />,
      okText: "si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleOnClickInhabilitarEnvio(envio.id);
      },
    });
  }

  async function handleOnClickInhabilitarEnvio(envioId) {
    await EnvioService.inhabilitar(envioId);
    const _envios = envios.filter((envio) => envio.id !== envioId);
    console.log("Después de eliminar ", _envios);
    setEnvios(_envios);
    setTotalPaginacion(totalPaginacion - 1);
    notification.success({
      message: "Operación exitosa",
      description: "Se inhabilito el registro correctamente.",
    });
  }

  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES.BOTON_VER_DETALLE) && (
            <Menu.Item key="ver-detalle">
              <SelectOutlined /> Ver Detalle
            </Menu.Item>
          )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES.BOTON_GENERAR_REPORTE_EXCEL) && (
            <Menu.Item key="generar-reporte-excel">
              <FileExcelOutlined /> Generar Reporte Excel
            </Menu.Item>
          )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES.BOTON_GENERAR_REPORTE_PDF) && (
            <Menu.Item key="generar-reporte-pdf">
              <FilePdfOutlined /> Generar Reporte PDF
            </Menu.Item>
          )}
        {codigosOpciones &&
          codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES.BOTON_ASIGNAR_CARPETA) && (
            <Menu.Item key="asignar-carpeta">
              <SelectOutlined />
              Asignar Carpeta
            </Menu.Item>
          )}
        {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_CONSULTAR_POR_NOTIFICACIONES.BOTON_ELIMINAR) && (
          <Menu.Item key="eliminar">
            <CloseOutlined /> Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight" arrow>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};

export default MenuAcciones;
