import UsuarioService from "services/UsuarioService";
import { sesionesAtom } from "../store/store";
import { useAtom } from "jotai";
import { notification } from "antd";
import produce from "immer";

const useSesionesPage = () => {

    const [sesiones, setSesiones] = useAtom(sesionesAtom);

    const fetchSesiones = async () => {
        const usuario = await UsuarioService.listarSesiones();
        setSesiones(usuario.sesiones);
    }

    const cerrarSesion = async (sesion) => {
        await UsuarioService.cerrarSesion({ token: sesion.token });

        setSesiones(
            produce((draft) => {
                const indice = draft.findIndex((item) => item.token === sesion.token);
                draft.splice(indice, 1);
            })
        );

        notification.success({
            message: 'Acción exitosa',
            description: 'La sesión fue cerrada.'
        })
    }

    return {
        sesiones,
        cerrarSesion,
        fetchSesiones
    };
}
export default useSesionesPage;