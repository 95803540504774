import { atom } from "jotai";

export const estaPaginaPdfCargadaAtom = atom(false);
export const paginaPdfAtom = atom({ alto: 0, ancho: 0 });
export const controlPdfDefaultAtom = atom({ alto: 30, ancho: 150 });
export const posicionControlEnMovimientoAtom = atom({ left: 0, top: 0 });
export const componentesAtom = atom([]);
export const zoomAtom = atom(1);
export const controlSeleccionadoAtom = atom(undefined);
export const visibleDrawerPropiedadesAtom = atom(false);
export const formularioAtom = atom(undefined);
export const cargosAtom = atom([]);
export const sedesAtom = atom([]);
export const usuariosAtom = atom([]);
export const formulariosAtom = atom([]);

export const openDrawerFlujoFirmasAtom = atom(false);