import React, { useState } from "react";
import { Form, DatePicker, Select, Row, Col, Button } from "antd";
import ColaboradorService from "services/ColaboradorService";
import { useAtom } from "jotai";
import EvaluacionService from "services/EvaluacionService";
import { evaluacionesFiltradasAtom, loadingAtom } from "../EvaluacionSeguimientoStore";
import moment from "moment";
import { EstadoSolicitudAusencia } from "enums/EstadoSolicitudAusencia";
import { saveAs } from "file-saver";

const FiltroConsultaSolicitudes = () => {

    const [colaboradores, setColaboradores] = useState(undefined);
    const [, setEvaluacionesFiltradas] = useAtom(evaluacionesFiltradasAtom);
    const [loading, setLoading] = useAtom(loadingAtom);
    const [filtro, setFiltro] = useState({
        numeroDocumentoIdentidad: null,
        fechaInicio: null,
        fechaFin: null,
        estadoAprobacionJefe: null,
        estadoAprobacionRH: null
    });

    const [formFiltro] = Form.useForm();
    const { RangePicker } = DatePicker;

    const onSearchColaboradores = async (value) => {
        if (value === "") {
            setColaboradores([]);
            return;
        }
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim(),
        });
        setColaboradores(respuesta.colaboradores);
    };

    const actualizarTabla = async (formulario) => {
        setLoading(true)
        const respuesta = await EvaluacionService.listarConFiltro(formulario.colaborador,
            formulario.meses ? formulario.meses[0]?.format("DD/MM/YYYY 00:00") : undefined,
            formulario.meses ? formulario.meses[1]?.format("DD/MM/YYYY 00:00") : undefined,
            formulario.estadoJefeInmediato,
            formulario.estado);
        setFiltro({
            numeroDocumentoIdentidad: formulario.colaborador,
            fechaInicio: formulario.meses ? formulario.meses[0]?.format("DD/MM/YYYY 00:00") : null,
            fechaFin: formulario.meses ? formulario.meses[1]?.format("DD/MM/YYYY 00:00") : null,
            estadoAprobacionJefe: formulario.estadoJefeInmediato,
            estadoAprobacionRH: formulario.estadoAprobacionRH
        });
        setEvaluacionesFiltradas(respuesta);
        setLoading(false)
    }

    const onClickBtnExportar = async () => {
        setLoading(true);
        const response =
            await EvaluacionService.exportar(
                filtro.colaborador,
                filtro.fechaInicio,
                filtro.fechaFin,
                filtro.estadoAprobacionJefe,
                filtro.estadoAprobacionRH);
        setLoading(false);
        saveAs(response.url);

    }

    return (
        <Form
            layout="vertical"
            form={formFiltro}
            onFinish={actualizarTabla}
            initialValues={{
                estado: "",
                estadoJefeInmediato: "",
                meses: [moment().startOf("year"), moment().endOf("year")]
            }}>
            <Row gutter={16}>
                <Col span={5}>
                    <Form.Item label="Colaborador" name="colaborador" >
                        <Select
                            style={{ width: "250px" }}
                            allowClear
                            showSearch
                            placeholder="Ingrese el nombre del colaborador"
                            onSearch={onSearchColaboradores}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={() => formFiltro.submit()}
                        >
                            {colaboradores !== undefined && colaboradores?.map((colaborador) => (
                                <Select.Option key={colaborador.numeroDocumentoIdentidad} value={colaborador.numeroDocumentoIdentidad}>
                                    {colaborador.nombreCompleto}
                                </Select.Option>

                            ))}
                        </Select>

                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Por meses" name="meses">
                        <RangePicker picker="month" format={"MM/YYYY"} onChange={() => formFiltro.submit()} />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Estado Aprobación Jefe Inmediato" name="estadoJefeInmediato" >
                        <Select style={{ width: "250px" }} placeholder="Seleccion el estado" onChange={() => formFiltro.submit()} >
                            <Select.Option value="">TODOS</Select.Option>
                            <Select.Option value={EstadoSolicitudAusencia.PENDIENTE}>{EstadoSolicitudAusencia.PENDIENTE}</Select.Option>
                            <Select.Option value={EstadoSolicitudAusencia.APROBADO}>{EstadoSolicitudAusencia.APROBADO}</Select.Option>
                            <Select.Option value={EstadoSolicitudAusencia.RECHAZADO}>{EstadoSolicitudAusencia.RECHAZADO}</Select.Option>

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Estado Aprobación Gestión Humana" name="estado" >
                        <Select style={{ width: "250px" }} placeholder="Seleccion el estado" onChange={() => formFiltro.submit()} >
                            <Select.Option value="">TODOS</Select.Option>
                            <Select.Option value={EstadoSolicitudAusencia.PENDIENTE}>{EstadoSolicitudAusencia.PENDIENTE}</Select.Option>
                            <Select.Option value={EstadoSolicitudAusencia.APROBADO}>{EstadoSolicitudAusencia.APROBADO}</Select.Option>
                            <Select.Option value={EstadoSolicitudAusencia.RECHAZADO}>{EstadoSolicitudAusencia.RECHAZADO}</Select.Option>

                        </Select>
                    </Form.Item>
                </Col>

                <Col span={4}>
                    <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center" }}>
                        <Button type="primary" loading={loading} onClick={onClickBtnExportar}>Exportar</Button>
                    </div>
                </Col>

            </Row>
        </Form>
    )

}
export default FiltroConsultaSolicitudes;