import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { Container } from "components/Container";
import { UnorderedListOutlined, ToolOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Row, Select, Table } from "antd";
import { Botones } from "./botones";
import { TablaTipoAbsentismo } from "./tabla-tipoAbsentismo";
import { openDrawerConfigurarDetalleReglaAtom, openDrawerConfigurarReglaAtom, reglaSeleccionadaAtom, tipoAbsentismoSeleccionadoAtom } from "./ListarTipoAbsentismoStore";
import { ReglasVacacionesService } from "services/VacacionesService";
import SedeService from "services/SedeService";
import { DrawerConfigurarColor } from "./components";

const ListarTipoAbsentismoPage = () => {
  return (
    <JotaiProvider>
      <Container icono={<UnorderedListOutlined />} titulo="Tipo Absentismo" botonExtra={<Botones />}>
        <TablaTipoAbsentismo />
        <DrawerReglas />
        <DrawerConfigurarColor />
      </Container>
    </JotaiProvider>
  )
}

const DrawerReglas = () => {

  const [form] = Form.useForm();
  const [openDrawerConfigurarRegla, setOpenDrawerConfigurarRegla] = useAtom(openDrawerConfigurarReglaAtom);
  const [openDrawerConfigurarDetalleRegla, setOpenDrawerConfigurarDetalleRegla] = useAtom(openDrawerConfigurarDetalleReglaAtom);
  const [tipoAbsentismoSeleccionado] = useAtom(tipoAbsentismoSeleccionadoAtom);
  const [reglaSeleccionada, setReglaSeleccionada] = useAtom(reglaSeleccionadaAtom);
  const [reglas, setReglas] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [identificadoresFiltro, setIdentificadoresFiltro] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (openDrawerConfigurarRegla) {
        const reglas = await ReglasVacacionesService.listar();
        setReglas(reglas.data);
      }
    }
    fetchData();
  }, [openDrawerConfigurarRegla]);

  useEffect(() => {
    const fetchData = async () => {
      if (openDrawerConfigurarDetalleRegla) {
        const sedes = await SedeService.listar();
        setSedes(sedes.map(sede => {
          return {
            value: sede.id,
            label: sede.nombre
          }
        }));


      }
    }
    fetchData();
  }, [openDrawerConfigurarDetalleRegla])

  useEffect(() => {
    const fetchFiltros = async () => {
      if (sedes.length > 0) {
        const filtro = await ReglasVacacionesService.listarFiltros({ regla: reglaSeleccionada.regla });
        form.setFieldValue("identificadores", filtro.data);
        // setIdentificadoresFiltro(filtro.data);
      }
    }
    fetchFiltros();
  }, [sedes])

  const onClickBtnConfigurarRegla = (regla) => {
    setReglaSeleccionada(regla);
    setOpenDrawerConfigurarDetalleRegla(true);
  }

  const columnasReglas = [
    {
      title: 'Regla',
      dataIndex: 'regla',
    },
    {
      title: '',
      align: 'center',
      width: '5%',
      render:
        (regla) =>
          <Button
            type="text"
            icon={<ToolOutlined style={{ color: "gray" }}
              onClick={() => onClickBtnConfigurarRegla(regla)} />}
          />

    },
  ];

  const onClickBtnGuardar = () => {
    form
      .validateFields()
      .then(async (datosFormulario) => {
        const request = {
          regla: reglaSeleccionada.regla,
          ...datosFormulario
        }
        await ReglasVacacionesService.actualizarFiltro(request);
        setOpenDrawerConfigurarDetalleRegla(false);
        // form.resetFields();
        // onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  }

  return (
    <Drawer
      title={`Configurar Regla - ${tipoAbsentismoSeleccionado?.descripcion}`}
      width={520}
      closable={false}
      onClose={() => setOpenDrawerConfigurarRegla(false)}
      open={openDrawerConfigurarRegla}>

      {/* <Button type="primary" onClick={() => setOpenDrawerConfigurarDetalleRegla(true)}>
        Two-level drawer
      </Button> */}

      <Table
        rowKey="regla"
        dataSource={reglas}
        columns={columnasReglas}
        size="small"
        bordered={true}
        pagination={false}
      />

      <Drawer
        title="Configurar"
        width={320}
        closable={false}
        onClose={() => setOpenDrawerConfigurarDetalleRegla(false)}
        open={openDrawerConfigurarDetalleRegla}
      >
        <Form
          form={form}
          layout="vertical"
        >
          {/* <Form.Item
            name="campo"
            label="Campo"
          >
            <Input />
          </Form.Item> */}
          <Form.Item name="campo" initialValue="SEDE" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="identificadores" label="Centro de Costo">
            <Select
              mode="multiple"
              options={sedes}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Row>
            <Button type="primary" onClick={onClickBtnGuardar}>Guardar</Button>
          </Row>
        </Form>
      </Drawer>
    </Drawer>
  )
}

export default ListarTipoAbsentismoPage;