import { useAtom } from "jotai";
import { tiposDocumentoAtom } from "../store/Store";
import TipoDocumentoService from "services/TipoDocumentoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

const useTiposDocumento = () => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [tiposDocumento, setTiposDocumento] = useAtom(tiposDocumentoAtom);

    const fetchTiposDocumento = async () => {
        const tiposDocumento =
            await TipoDocumentoService
                .listarPorUsuarioYCategoria(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                    getUsuarioAutenticado().login,
                    CategoriaDocumento.FLUJO_FIRMA.id);

        setTiposDocumento(tiposDocumento.map((tipoDocumento) => ({
            label: tipoDocumento.nombre,
            value: tipoDocumento.id,
            tienePlantillaPdf: tipoDocumento.tienePlantillaPdf
        })))
    };

    return {
        tiposDocumento,
        fetchTiposDocumento,
    }

}

export default useTiposDocumento;