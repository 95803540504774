import { useState } from "react";
import UsuarioService from "services/UsuarioService";

const usePermisos = () => {
    const [opciones, setOpciones] = useState([]);
    const [permisosDocumentos, setPermisosDocumentos] = useState([]);

    const fetchOpciones = async () => {
        const opciones = await UsuarioService.listarOpciones();
        setOpciones(opciones);
    }

    const fetchPermisosDocumentos = async () => {
        const permisosDocumentos = await UsuarioService.listarPermisosDocumentos();
        setPermisosDocumentos(permisosDocumentos);
    }

    const existeOpcion = (opcion) => {
        return opciones.includes(opcion);
    }

    const existePermiso = (tipoDocumentoId, permiso) => {
        const permisos = permisosDocumentos.find(permiso => permiso.tipoDocumentoId === tipoDocumentoId);
        return permisos.includes(permiso);
    }

    return {
        opciones,
        permisosDocumentos,
        fetchOpciones,
        fetchPermisosDocumentos,
        existeOpcion,
        existePermiso
    };
};

export default usePermisos;
