import React, { useContext, useEffect, useState } from "react";
import { Table, Space, Tag } from "antd";
import * as atoms from "../ListarCarpetasLegajosStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import CarpetaService from "services/CarpetaService";
import { MenuAcciones } from "./MenuAcciones";
import { FolderFilled, FolderTwoTone } from "@ant-design/icons";


const TablaCarpetasLegajos = () => {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [carpetasLegajos, setCarpetasLegajos] = useAtom(atoms.carpetasLegajos);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const _carpetasLegajos = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                setCarpetasLegajos(_carpetasLegajos);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetch();
    }, []);

    const columnasTablaCarpeta = [
        {
            title: "Nombre",
            render: (fila) => {
                if (fila.nivel === 0) {
                    return (
                        <Space size={fila.nivel * 12}>
                            <span></span>
                            <span>
                                <FolderFilled style={{ fontSize: '24px', color: '#F7D358' }} />
                                {" "}{fila.descripcion}
                            </span>
                        </Space>
                    )
                } else if (fila.nivel === 1) {
                    return (
                        <Space size={fila.nivel * 12}>
                            <span></span>
                            <span>
                                <FolderTwoTone style={{ fontSize: '21px', color: '#F7D358' }} twoToneColor='#F7D358' />
                                {" "}{fila.descripcion}
                            </span>
                        </Space>
                    )
                }

                return (
                    <Space size={fila.nivel * 12}>
                        <span></span>
                        <FolderTwoTone style={{ fontSize: '21px', color: '#F7D358' }} twoToneColor='#F7D358' />
                        {fila.descripcion}
                    </Space>
                )
            }
        },
        {
            title: "Ocultar Colaborador",
            align: "center",
            render: (_, fila) => {
                if (fila.ocultar) {
                    return <Tag color="error">SI</Tag>
                }
                return <Tag>NO</Tag>
            }

        },
        {
            title: "Acciones",
            key: "acciones",
            align: "center",
            render: (fila) =>
                <MenuAcciones
                    carpeta={fila}
                    carpetas={carpetasLegajos}
                    setCarpetas={setCarpetasLegajos}
                />
        },

    ];
    return (
        <Table
            loading={loading}
            rowKey={(carpeta) => carpeta.id}
            columns={columnasTablaCarpeta}
            dataSource={carpetasLegajos}
            pagination={false}


        />
    )
}


export default TablaCarpetasLegajos;