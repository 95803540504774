export const TipoControl = {
  EFORM_PDF: "EFORM_PDF",
  ETIQUETA: "etiqueta",
  CAJA_TEXTO: "cajatexto",
  LISTA: "lista",
  CHECKBOX: "checkbox",
  RADIO_BUTTON: "radiobutton",
  IMAGEN: "imagen",
  SIGNATURE: "signature",
  SIGNATURE_DATA: "signaturedata", //deprecado: se eliminará
  FECHA: "fecha",
  FORMULA: "formula",
  PARAMETRO: "parametro",
};
