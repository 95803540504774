import { Drawer } from 'antd';
import { useAtom } from 'jotai';
import React from 'react';
import { openDrawerFlujoFirmasAtom } from '../DisenarFormularioPdfStore';

const DrawerFlujoFirmas = () => {

    const [open, setOpen] = useAtom(openDrawerFlujoFirmasAtom);

    return (
        <Drawer title="Flujo de firmas" placement="right" onClose={() => setOpen(false)} open={open} >
            <p>Establece el flujo de firmas</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Drawer >
    );
};

export default DrawerFlujoFirmas;