import React, { useEffect } from "react";
import { Table } from "antd";
import { useCargarVacaciones, usePaginacion } from "../../hooks";
import MenuAcciones from "./MenuAcciones";
import { useAtom } from "jotai";
import { loadingAtom } from "../../ControlVacacionalStorage";

const TablaControlVacaciones = () => {
  const [loading] = useAtom(loadingAtom);
  const { vacaciones } = useCargarVacaciones();
  const { totalRegistros, paginaActual, tamanioPaginado, filtrosSeleccionados, listarVacaciones, onCambiarPaginacion } = usePaginacion();

  useEffect(() => {
    listarVacaciones(filtrosSeleccionados, 1);
  }, []);

  const columnas = [
    {
      title: "Documento de Identidad",
      dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
      align: "center",
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador", "nombreCompleto"],
      align: "center",
    },
    {
      title: "Fecha Ingreso",
      dataIndex: "fechaIngreso",
      align: "center",
    },
    {
      title: "Vacaciones Disponibles",
      dataIndex: "vacacionesDisponibles",
      align: "center",
    },
    {
      title: "Acciones",
      align: "center",
      render: (text, fila) => <MenuAcciones colaborador={fila} />,
    },
  ];

  return (
    <Table
      rowKey={(vacaciones) => vacaciones.id}
      dataSource={vacaciones}
      columns={columnas}
      loading={loading}
      pagination={{
        pageSize: tamanioPaginado,
        total: totalRegistros,
        current: paginaActual,
        showTotal: (total) => `Total ${total} registros`,
        onChange: (page, pageSize) => onCambiarPaginacion(page, pageSize),
      }}
      style={{
        marginTop: "10px"
      }}
    />
  );
};

export default TablaControlVacaciones;
