"use client";

import React,{useState} from "react";
import { Form, Select } from "antd";
import { useModalCrearBeneficioExterno } from "../hooks";
// import { ModalForm, ProFormGroup, ProFormSelect, ProFormText } from "@ant-design/pro-components";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  notification,
  Upload
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Headers, TipoUsuario } from "enums";
import ImgCrop from "antd-img-crop";
import ColaboradorService from "services/ColaboradorService";
import { LoginService } from "services/LoginService";
import { fileListAtom} from "../store/store";
import { useAtom } from "jotai";

const ModalCrearBeneficioExterno = () => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useAtom(fileListAtom);
  const [texto, setTexto] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const { open, setOpen, crearBeneficioExterno, loading } = useModalCrearBeneficioExterno();

  const datos = {
    uid: texto,
  };

  const props = {
    name: "file",
    multiple: true,
    action:
      process.env.REACT_APP_ENDPOINT +
      "comunicaciones-cultura/beneficioExterno/cargarLogo",
    headers: {
      ["authorization"]: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
      [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN,
    },
    data: datos,
    beforeUpload(file) {
      setTexto(file.uid);
      return file;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Modal
      title="Crear Beneficio Externo"
      open={open}
      onCancel={() => setOpen(false)}
      // footer={[
      //   <Button disabled={loading} onClick={() => setOpen(false)}> Cancelar</Button>,
      //   <Button
      //       type="primary"
      //       htmlType="submit"
      //       loading={loading}
      //     >
      //       Aceptar
      //     </Button>
      // ]}
      footer={null}
      centered
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onFinish={crearBeneficioExterno}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el nombre ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label="Url"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese url",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
          <Form.Item
              label="Logo"
              name="logo"
            >
            <ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
                  <Upload {...props} maxCount={1} onChange={onChange} accept={["image/*"]}>
                    <Button block>
                      Subir
                    </Button>
                  </Upload>
            </ImgCrop>
            </Form.Item>
            
          </Col>
         </Row> 
        <Row justify="center" style={{ marginBottom: 0 }}>
          <Space>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  // loading={loading}
                >
                  Aceptar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button onClick={() => setOpen(false)}> Cancelar</Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalCrearBeneficioExterno;
