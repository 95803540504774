import React from "react";
import { FilterServer } from "components/MultiFiltro";
import { usePaginacion } from "../../hooks";
import { useAtom } from "jotai";
import { loadingAtom } from "../../ControlVacacionalStorage";
import { Loading3QuartersOutlined } from "@ant-design/icons"

const FiltroTablaControlVacaciones = () => {
  const { onFiltrar } = usePaginacion();
  // const [loading] = useAtom(loadingAtom);

  const filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [
      { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
      { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
      { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" },
    ],
  };

  // if (loading) {
  //   return <Loading3QuartersOutlined spin />;
  // }

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar los colaboradores"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      filtroSeleccionadoInicial={{ id: "apellidosNombres", titulo: "Apellidos y Nombres", tipo: "texto" }}
    />
  );
};

export default FiltroTablaControlVacaciones;
