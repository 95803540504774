import React, { useContext } from "react";

import { Menu, Space } from "antd";

import {
    CalendarOutlined,
    FormOutlined,
    SyncOutlined,
    BellOutlined,
    FolderOutlined,
    BookOutlined,
    BankOutlined,
    UserOutlined,
    SoundOutlined,
    LineChartOutlined,
    FileSearchOutlined,
    FileDoneOutlined,
    SettingOutlined,
    SafetyCertificateOutlined,
    UnorderedListOutlined,
    FileTextOutlined,
    FundOutlined,
    CloudUploadOutlined,
    SafetyOutlined,
    ProfileOutlined,
    LayoutOutlined,
    TeamOutlined,
    CommentOutlined,
    ContainerOutlined,
    UsergroupAddOutlined,
    FileOutlined,
    BranchesOutlined,
    DownloadOutlined,
    NotificationOutlined,
    WechatOutlined,
    CarOutlined,
    FieldTimeOutlined,
    SendOutlined,
    TrophyOutlined,
    MessageOutlined,
    OrderedListOutlined
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import {
    categoriaSeguimientoAtom,
    periodoAtom,
    tipoDocumentoSeguimientoAtom,
    paginaSeguimientoAtom,
} from "store/local/seguimiento/SeguimientoStore";
import { useAtom } from "jotai";
const { SubMenu } = Menu;

export function MenuPrincipal({ opciones }) {
    const [, setCategoriaListar] = useAtom(categoriaSeguimientoAtom);
    const [, setTipoDocumentoListar] = useAtom(tipoDocumentoSeguimientoAtom);
    const [, setPaginaSeguimiento] = useAtom(paginaSeguimientoAtom);
    const [, setPeriodoListar] = useAtom(periodoAtom);
    // const { getOpciones } = useContext(SecurityContext);
    let history = useHistory();
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    function handleOnClick(e) {
        switch (e.key) {
            case "mantenimiento.colaborador":
                history.push("/personal/trabajador");
                break;

            case "mantenimiento.sede":
                history.push("/sede");
                break;

            case "mantenimiento.cargo":
                history.push("/cargo");
                break;

            case "mantenimiento.usuario":
                history.push("/usuario");
                break;

            case "mantenimiento.seccion":
                history.push("/seccion");
                break;

            case "mantenimiento.subsede":
                history.push("/subsede");
                break;

            case "mantenimiento.perfil":
                history.push("/perfil");
                break;

            case "mantenimiento.tipoPlanilla":
                history.push("/tipoPlanilla");
                break;

            case "notificacion.crear":
                history.push("/documento-laboral/envio");
                break;

            case "notificacion.seguimiento.formularios":
                //history.push("/notificacion/formulario/seguimiento");
                history.push("/notificacion/formulario/notificar");
                break;

            case "notificacion.documentosPorAprobar":
                history.push("/documento-laboral-porAprobar");
                break;

            case "contrato.crear":
                history.push("/contrato/crear");
                break;

            case "contrato.seguimiento":
                history.push("/contrato/seguimiento");
                break;

            case "contrato.registrarPlantilla":
                history.push("/contrato/plantilla-contrato/listar");
                break;

            case "contrato.seguimiento.renovacioncontratos":
                history.push("/contrato/seguimiento/renovacion-contratos");
                break;

            case "explomin.contrato.seguimiento":
                history.push("/explomin/contrato/bandeja/aprobacion/administracion-personal");
                break;

            case "contrato.seguimiento.contratosaprobados":
                history.push("/explomin/contrato/bandeja/aprobacion/reclutamiento-y-seleccion");
                //history.push("/contrato/seguimiento/contratos-aprobados");
                break;

            case "legajos.consultas":
                history.push("/legajos");
                break;

            case "legajo.v2.consultas":
                history.push("/v2/legajos");
                break;

            case "legajos.documentos":
                history.push("/legajos/documento");
                break;

            case "mantenimiento.rol":
                history.push("/rol");
                break;

            case "configuracion.eliminarDatos":
                history.push("/eliminarDatos");
                break;

            case "configuracion.formularios":
                history.push("/configuracion/formularios/listar");
                break;

            case "configuracion.misCertificados":
                history.push("/configuracion/misCertificados");
                break;

            case "configuracion.certificadosDigitales":
                history.push("/configuracion/certificadosDigitales");
                break;

            case "configuracion.tipoDocumentos":
                history.push("/configuracion/tipo-documentos/listar");
                break;

            case "configuracion.carpetasLegajos":
                history.push("/configuracion/carpetas-legajos/listar");
                break;

            case "configuracion.MiFirmaHolografa":
                history.push("/configuracion/firmas-holografas/listar");
                break;
            case "configuracion.verficarDatosColaborador":
                history.push("/configuracion/verificar-datos-colaborador");
                break;
            case "configuracion.plantillaEnvioDocumentos":
                history.push("/configuracion/plantilla-envio-documentos/listar");
                break;

            case "configuracion.onBoardingIndividual":
                history.push("/notificacion/onboarding");
                break;

            case "notificacion.onboarding.masivo":
                history.push("/notificacion/onboarding-masivo");
                break;

            case "notificacion.asistencia.laredo":
                history.push("/notificacion/asistencia/laredo");
                break;

            case "configuracion.tipoAbsentismo":
                history.push("/tipoAbsentismo");
                break;

            case "notificacion.comunicados.crear":
                history.push("/notificacion/comunicado/notificar");
                break;

            case "notificacion.seguimiento.v2":
                setCategoriaListar(null);
                setTipoDocumentoListar(null);
                setPeriodoListar(null); /*setPaginaSeguimiento(null);*/
                history.push("/notificacion/seguimiento");
                break;

            case "notificacion.firma-documento-generico.listar":
                history.push("/notificacion/firma-documento-generico/listar");
                break;

            case "notificacion.firma-documento-generico.crear":
                history.push("/notificacion/firma-documento-generico/crear");
                break;

            case "notificacion.firma-documento-generico.bandeja-aprobador":
                history.push("/notificacion/firma-documento-generico/mis-documentos");
                break;

            case "consulta.documento":
                history.push("/consulta-documento");
                break;

            case "notificacion.completar":
                history.push("/notificacion-por-completar");
                break;

            case "notificacion.evaluacion":
                history.push("/notificacion/evaluacion/notificar");
                break;

            case "legajos.observacionesColaborador":
                history.push("/listar-observaciones-colaboradores");
                break;

            case "legajos.consultaOnboarding":
                history.push("/Onboarding/consulta");
                break;

            case "legajos.seguimientoOnboarding":
                history.push("/onboarding/seguimiento");
                break;

            case "legajos.reportes":
                history.push("/listar-reportes");
                break;

            case "legajos.descargamasiva":
                history.push("/consultar/descarga-masiva");
                break;

            case "legajos.consultaVacaciones":
                history.push("/solicitud-vacaciones/consulta");
                break;

            case "ausencias.solicitudes":
                history.push("/ausencias/solicitudes");
                break;

            case "vacaciones.control-vacacional":
                history.push("/ausencias/vacaciones");
                break;

            case "legajos.seguimientoEvaluaciones":
                history.push("/evaluacion/seguimiento");
                break;

            case "consulta.seguimientoContrato":
                history.push("/contratos-vencidos/seguimiento");
                break;

            case "reclutamiento-y-seleccion.postulantes":
                history.push("/personal/postulante");
                break;

            case "reclutamiento-y-seleccion.plantillas":
                history.push("/reclutamiento-y-seleccion/plantillas");
                break;

            case "reclutamiento-y-seleccion.procesos":
                history.push("/reclutamiento-y-seleccion/procesos");
                break;

            case "reclutamiento-y-seleccion.convocatoria":
                history.push("/reclutamiento-y-seleccion/convocatorias");
                break;

            case "reclutamiento-y-seleccion.solicitud-personal.solicitudes":
                history.push("/reclutamiento-y-seleccion/solicitud-personal/solicitudes");
                break;

            case "reclutamiento-y-seleccion.solicitud-personal.perfiles":
                history.push("/reclutamiento-y-seleccion/solicitud-personal/perfiles");
                break;

            case "reclutamiento-y-seleccion.solicitud-personal.entrevistas":
                history.push("/reclutamiento-y-seleccion/solicitud-personal/entrevistas");
                break;

            case "reclutamiento-y-seleccion.solicitud-personal.reportes":
                history.push("/reclutamiento-y-seleccion/solicitud-personal/reportes");
                break;

            case "explomin.contrato.seguimiento.administracionpersonal":
                history.push("/explomin/contrato/bandeja/aprobacion/administracion-personal");
                break;

            case "explomin.contrato.seguimiento.rys":
                history.push("/explomin/contrato/bandeja/aprobacion/reclutamiento-y-seleccion");
                break;

            case "legajos.carga":
                history.push("/listar-legajosCargados");
                break;

            case "asistencia.turnos":
                history.push("/asistencia/turnos");
                break;

            case "asistencia.asignacion-turnos":
                history.push("/asistencia/asignacion-turnos");
                break;

            case "asistencia.punto-marcacion":
                history.push("/asistencia/punto-marcacion");
                break;

            case "consultar.bandejatareas":
                history.push("/consultar/bandeja-tareas");
                break;

            case "comunicacion-cultura.publicaciones":
                history.push("/comunicaciones-cultura/publicaciones");
                break;

            case "comunicacion-cultura.reconocimientos":
                history.push("/comunicaciones-cultura/reconocimientos");
                break;

            case "comunicacion-cultura.beneficios-externos":
                history.push("/comunicaciones-cultura/beneficios-externos");
                break;

            case "notificacion.documento.flujo-firma":
                history.push("/notificacion/flujo-firma");
                break;

            case "explomin.contrato.seguimiento.tiempo.servicio":
                history.push("/explomin/contrato/bandeja/seguimento/tiempo-servicio");
                break;

            default:
                console.error("No existe ningún item con el key: ", e.key);
        }
    }

    function tieneAcceso(codigo) {
        return opciones?.includes(codigo);
    }

    function mostrarMenuItem(codigo, nombre, icono) {
        if (tieneAcceso(codigo)) {
            return (
                <Menu.Item key={codigo}>
                    <Space>
                        {icono}
                        {nombre}
                    </Space>
                </Menu.Item>
            );
        }
        return "";
    }

    return (
        <Menu mode="horizontal" theme="light" onClick={handleOnClick}>
            {tieneAcceso("mantenimiento") && (
                <SubMenu key="mantenimiento" icon={<SyncOutlined />} title="Mantenimiento">
                    {mostrarMenuItem("mantenimiento.perfil", "Perfil", <ProfileOutlined />)}
                    {mostrarMenuItem("mantenimiento.sede", "Centro de costo", <BankOutlined />)}
                    {mostrarMenuItem("mantenimiento.subsede", "Subcentro de costo", <BankOutlined />)}
                    {mostrarMenuItem("mantenimiento.seccion", "Sección", <LayoutOutlined />)}
                    {mostrarMenuItem("mantenimiento.tipoPlanilla", "Tipo Planilla", <TeamOutlined />)}
                    {mostrarMenuItem("mantenimiento.cargo", "Cargo", <BookOutlined />)}
                    {mostrarMenuItem("mantenimiento.colaborador", "Colaborador", <UserOutlined />)}
                    {mostrarMenuItem("mantenimiento.usuario", "Usuario", <UserOutlined />)}
                    {mostrarMenuItem("mantenimiento.rol", "Rol", <UserOutlined />)}
                </SubMenu>
            )}

            {tieneAcceso("notificacion") && (
                <SubMenu key="notificaciones" icon={<BellOutlined />} title="Notificaciones">
                    {mostrarMenuItem("notificacion.crear", "Notificar Documentos Laborales", <FileDoneOutlined />)}

                    {mostrarMenuItem(
                        "notificacion.seguimiento.formularios",
                        "Notificar Formularios",
                        <LineChartOutlined />
                    )}

                    {mostrarMenuItem(
                        "notificacion.documentosPorAprobar",
                        "Documentos por Aprobar",
                        <FileDoneOutlined />
                    )}

                    {mostrarMenuItem("notificacion.comunicados.crear", "Notificar Comunicados", <SoundOutlined />)}

                    {mostrarMenuItem("notificacion.completar", "Notificación por completar", <FileSearchOutlined />)}

                    {mostrarMenuItem(
                        "configuracion.onBoardingIndividual",
                        "Notificar Onboarding",
                        <ContainerOutlined />
                    )}

                    {mostrarMenuItem(
                        "notificacion.onboarding.masivo",
                        "Notificar Onboarding Masivo",
                        <SendOutlined />
                    )}

                    {mostrarMenuItem(
                        "notificacion.asistencia.laredo",
                        "Notificar Asistencia",
                        <OrderedListOutlined />
                    )}

                    {mostrarMenuItem(
                        "notificacion.documento.flujo-firma",
                        "Flujo de Firma de Documentos",
                        <TeamOutlined />
                    )}

                    {mostrarMenuItem("notificacion.evaluacion", "Notificar Evaluación", <FormOutlined />)}

                    {tieneAcceso("notificacion.firma-documento-generico") && (
                        <Menu.ItemGroup title="Firma Documentos Genérica">
                            {mostrarMenuItem(
                                "notificacion.firma-documento-generico.crear",
                                "Cargar Documento",
                                <CloudUploadOutlined />
                            )}
                            {mostrarMenuItem(
                                "notificacion.firma-documento-generico.listar",
                                "Listar Documentos",
                                <UnorderedListOutlined />
                            )}
                            {mostrarMenuItem(
                                "notificacion.firma-documento-generico.bandeja-aprobador",
                                "Mis Documentos",
                                <FormOutlined />
                            )}
                        </Menu.ItemGroup>
                    )}
                </SubMenu>
            )}

            {tieneAcceso("contrato") && (
                <SubMenu key="contratos" icon={<FileDoneOutlined />} title="Contratos">
                    {mostrarMenuItem("contrato.crear", "Crear", <FileTextOutlined />)}
                    {mostrarMenuItem("contrato.seguimiento", "Seguimiento", <FundOutlined />)}
                    {mostrarMenuItem("contrato.registrarPlantilla", "Registrar Plantillas", <FileTextOutlined />)}
                    {mostrarMenuItem(
                        "contrato.seguimiento.renovacioncontratos",
                        "Seguimiento Renovación de Contratos",
                        <FileTextOutlined />
                    )}
                    {mostrarMenuItem(
                        "explomin.contrato.seguimiento.administracionpersonal",
                        "Bandeja de Aprobación Administración Personal",
                        <FileTextOutlined />
                    )}
                    {mostrarMenuItem(
                        "explomin.contrato.seguimiento.rys",
                        "Bandeja de Aprobación Reclutamiento y Selección",
                        <FileTextOutlined />
                    )}
                    {mostrarMenuItem(
                        "explomin.contrato.seguimiento.tiempo.servicio",
                        "Bandeja de Seguimiento Tiempo Servicio",
                        <FileTextOutlined />
                    )}
                </SubMenu>
            )}

            {tieneAcceso("legajos") && (
                <SubMenu key="consultar" icon={<FolderOutlined />} title="Consultar">
                    {/* {mostrarMenuItem("legajos.consultas", "Consulta Legajos", <FileSearchOutlined />)} */}

                    {mostrarMenuItem(
                        "notificacion.seguimiento.v2",
                        <React.Fragment>Notificaciones</React.Fragment>,
                        <LineChartOutlined />
                    )}

                    {mostrarMenuItem(
                        "consulta.documento",
                        <React.Fragment>Documentos</React.Fragment>,
                        <FileSearchOutlined />
                    )}

                    {mostrarMenuItem(
                        "legajo.v2.consultas",
                        <React.Fragment>Legajo del Colaborador</React.Fragment>,
                        <FileSearchOutlined />
                    )}

                    {mostrarMenuItem(
                        "legajos.observacionesColaborador",
                        "Observaciones del Colaborador",
                        <CommentOutlined />
                    )}
                    {mostrarMenuItem("legajos.reportes", "Reportes", <ProfileOutlined />)}
                    {mostrarMenuItem("legajos.descargamasiva", "Descarga Masiva", <DownloadOutlined />)}
                    {mostrarMenuItem("consultar.bandejatareas", "Bandeja de Tareas", <FormOutlined />)}
                    {(tieneAcceso("legajos.consultaOnboarding") || tieneAcceso("legajos.seguimientoOnboarding")) && (
                        <Menu.ItemGroup title="ONBOARDING">
                            {mostrarMenuItem(
                                "legajos.seguimientoOnboarding",
                                "Seguimiento Onboarding",
                                <UnorderedListOutlined />
                            )}
                            {mostrarMenuItem(
                                "legajos.consultaOnboarding",
                                "Bandeja de Aprobación",
                                <FileDoneOutlined />
                            )}
                        </Menu.ItemGroup>
                    )}
                    {/* {(tieneAcceso("ausencias.solicitudes") || tieneAcceso("vacaciones.control-vacacional")) && (
            <Menu.ItemGroup title="VACACIONES Y AUSENCIAS">
              {mostrarMenuItem("ausencias.solicitudes", "Seguimiento Solicitudes", <FileDoneOutlined />)}              
              {mostrarMenuItem("vacaciones.control-vacacional", "Control de Vacaciones", <CalendarOutlined />)}
            </Menu.ItemGroup>
          )} */}
                    {tieneAcceso("legajos.seguimientoEvaluaciones") && (
                        <Menu.ItemGroup title="EVALUACIÓN">
                            {mostrarMenuItem("legajos.seguimientoEvaluaciones", "Seguimiento Evaluaciones", <FileDoneOutlined />)}
                        </Menu.ItemGroup>
                    )}
                    {tieneAcceso("consulta.seguimientoContrato") && (
                        <Menu.ItemGroup title="CONTRATOS VENCIDOS">
                            {mostrarMenuItem("consulta.seguimientoContrato", "Seguimiento de Contratos", <FileDoneOutlined />)}
                        </Menu.ItemGroup>
                    )}
                    {tieneAcceso("legajos.carga") && (
                        <Menu.ItemGroup title="CARGA DE LEGAJOS">
                            {mostrarMenuItem("legajos.carga", "Cargar", <FileDoneOutlined />)}
                        </Menu.ItemGroup>
                    )}
                </SubMenu>
            )}

            {tieneAcceso("configuracion") && (
                <SubMenu key="configuracion" icon={<SettingOutlined />} title="Configuración">
                    {mostrarMenuItem(
                        "configuracion.misCertificados",
                        "Mis certificados",
                        <SafetyCertificateOutlined />
                    )}
                    {mostrarMenuItem("configuracion.MiFirmaHolografa", "Mis firmas Hológrafas", <SafetyOutlined />)}
                    {mostrarMenuItem(
                        "configuracion.certificadosDigitales",
                        "Certificados digitales",
                        <SafetyCertificateOutlined />
                    )}
                    {mostrarMenuItem("configuracion.tipoDocumentos", "Tipo Documentos", <FileTextOutlined />)}
                    {mostrarMenuItem("configuracion.carpetasLegajos", "Carpetas Legajos", <FolderOutlined />)}
                    {mostrarMenuItem(
                        "configuracion.verficarDatosColaborador",
                        "Verificación Datos Colaborador",
                        <FolderOutlined />
                    )}
                    {mostrarMenuItem("configuracion.formularios", "Formularios", <FormOutlined />)}
                    {mostrarMenuItem(
                        "configuracion.plantillaEnvioDocumentos",
                        "Plantilla Onboarding",
                        <ProfileOutlined />
                    )}
                    {/* {mostrarMenuItem("configuracion.tipoAbsentismo", "Tipo Absentismo", <FileTextOutlined />)} */}
                </SubMenu>
            )}

            {/* {tieneAcceso("solicitudPersonal") && (
        <SubMenu key="solicitudPersonal" icon={<SettingOutlined />} title="Solicitud Personal">
          {mostrarMenuItem("solicitudPersonal.perfil", "Perfil", <FileTextOutlined />)}
        </SubMenu>
      )} */}

            {tieneAcceso("reclutamiento-y-seleccion") && (
                <SubMenu
                    key="reclutamiento-y-seleccion"
                    icon={<UsergroupAddOutlined />}
                    title="Reclutamiento y Selección"
                >
                    {mostrarMenuItem("reclutamiento-y-seleccion.postulantes", "Postulantes", <UserOutlined />)}
                    {mostrarMenuItem(
                        "reclutamiento-y-seleccion.convocatoria",
                        "Convocatoria",
                        <NotificationOutlined />
                    )}
                    {mostrarMenuItem("reclutamiento-y-seleccion.plantillas", "Plantillas", <FileOutlined />)}
                    {mostrarMenuItem("reclutamiento-y-seleccion.procesos", "Procesos", <BranchesOutlined />)}
                    {/* {mostrarMenuItem("solicitudPersonal.perfil", "Perfil", <FileTextOutlined />)} */}
                    {(tieneAcceso("reclutamiento-y-seleccion.solicitud-personal.solicitudes") ||
                        tieneAcceso("reclutamiento-y-seleccion.solicitud-personal.perfiles") ||
                        tieneAcceso("reclutamiento-y-seleccion.solicitud-personal.entrevistas")) && (
                            <Menu.ItemGroup title="SOLICITUD PERSONAL">
                                {/* {mostrarMenuItem("consulta.perfil", "Perfil", <FileDoneOutlined />)} */}
                                {mostrarMenuItem(
                                    "reclutamiento-y-seleccion.solicitud-personal.perfiles",
                                    "Perfiles",
                                    <FileTextOutlined />
                                )}
                                {mostrarMenuItem(
                                    "reclutamiento-y-seleccion.solicitud-personal.solicitudes",
                                    "Solicitudes",
                                    <FileDoneOutlined />
                                )}
                                {mostrarMenuItem(
                                    "reclutamiento-y-seleccion.solicitud-personal.entrevistas",
                                    "Entrevistas",
                                    <WechatOutlined />
                                )}
                                {mostrarMenuItem(
                                    "reclutamiento-y-seleccion.solicitud-personal.reportes",
                                    "Reportes",
                                    <LineChartOutlined />
                                )}
                            </Menu.ItemGroup>
                        )}
                </SubMenu>
            )}

            {tieneAcceso("vacaciones-y-ausencias") && (
                <SubMenu key="vacaciones-y-ausencias" icon={<CarOutlined />} title="Vacaciones y Ausencias">
                    {tieneAcceso("configuracion.tipoAbsentismo") && (
                        <Menu.ItemGroup title="CONFIGURACIÓN">
                            {mostrarMenuItem("configuracion.tipoAbsentismo", "Tipo Absentismo", <FileTextOutlined />)}
                        </Menu.ItemGroup>
                    )}

                    {(tieneAcceso("ausencias.solicitudes") || tieneAcceso("vacaciones.control-vacacional")) && (
                        <Menu.ItemGroup title="SEGUIMIENTO">
                            {mostrarMenuItem("ausencias.solicitudes", "Seguimiento Solicitudes", <FileDoneOutlined />)}
                            {mostrarMenuItem(
                                "vacaciones.control-vacacional",
                                "Control de Vacaciones",
                                <CalendarOutlined />
                            )}
                        </Menu.ItemGroup>
                    )}
                </SubMenu>
            )}

            {tieneAcceso("comunicacion-cultura") && (
                <SubMenu key="comunicacion-cultura" icon={<MessageOutlined />} title="Comunicación y Cultura">
                    {tieneAcceso("comunicacion-cultura.publicaciones") && (
                        mostrarMenuItem("comunicacion-cultura.publicaciones", "Publicaciones", <FileTextOutlined />)
                    )}
                    {tieneAcceso("comunicacion-cultura.reconocimientos") && (
                        mostrarMenuItem("comunicacion-cultura.reconocimientos", "Reconocimientos", <TrophyOutlined />)
                    )}

                    {tieneAcceso("comunicacion-cultura.beneficios-externos") && (
                        mostrarMenuItem("comunicacion-cultura.beneficios-externos", "Beneficios externos", <SafetyCertificateOutlined />)
                    )}
                </SubMenu>
            )}

            {tieneAcceso("asistencia") && (
                <SubMenu key="asistencia" icon={<FieldTimeOutlined />} title="Asistencia">
                    {tieneAcceso("asistencia.turnos") && (
                        mostrarMenuItem("asistencia.turnos", "Turnos", <FileTextOutlined />)
                    )}
                    {tieneAcceso("asistencia.asignacion-turnos") && (
                        mostrarMenuItem("asistencia.asignacion-turnos", "Asignación de turnos", <FileTextOutlined />)
                    )}
                    {/* {tieneAcceso("asistencia.horarios") && (
                        mostrarMenuItem("asistencia.horarios", "Horarios", <FileTextOutlined />)
                    )}
                    {tieneAcceso("asistencia.punto-marcacion") && (
                        mostrarMenuItem("asistencia.punto-marcacion", "Punto Marcación", <FileTextOutlined />)
                    )} */}
                </SubMenu>
            )}
        </Menu>
    );
}
