import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { PlusOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import { Button, Space, Image, Table, Tooltip } from "antd";
import useBeneficiosExternos from "./hooks/useBeneficiosExternos";
import useModalCrearBeneficioExterno from "./hooks/useModalCrearBeneficioExterno";
import { ModalCrearBeneficioExterno } from "./components";
import useModalEditarBeneficioExterno from "./hooks/useModalEditarBeneficioExterno";
import useModalEliminarBeneficioExterno from "./hooks/useModalEliminarBeneficioExterno";
import ModalEditarBeneficioExterno from "./components/ModalEditarBeneficioExterno";

const BeneficiosExternosPage = () => {

    return (
        <JotaiProvider>
            <Container titulo="Bandeja de Beneficios Externos" botonExtra={<Botones/>}>
            <ListaBeneficiosExternos/>
            <ModalCrearBeneficioExterno />
            <ModalEditarBeneficioExterno />
            </Container>
        </JotaiProvider>

    );
}

const Botones = () => {
    const { setOpen: setOpenModalCrearBeneficioExterno } = useModalCrearBeneficioExterno();
    return (
        <Button key="crear-beneficio-externo" type="primary" icon={<PlusOutlined />}  onClick={() => setOpenModalCrearBeneficioExterno(true)}>
          Crear Beneficio Externo
        </Button>
      );
}
const ListaBeneficiosExternos = () => {

    const { loading, beneficiosExternos, fetchBeneficiosExternos } = useBeneficiosExternos();

    useEffect(() => {
        const inicializar = () => {
            // if (open) {
                fetchBeneficiosExternos();
                // setFormInstance(form);
            // }
        }
        inicializar();
    }, []);

    //const { setOpen: setOpenModalCrearProveedor } = useModalCrearProveedor();

    const columnas = [
        {
            title: 'Nombre',
            dataIndex: 'nombre',
        },
        {
            title: 'Url',
            dataIndex: 'url',
        },
        {
            title: 'Logo',
            align: 'center',
            width: '20%',
            render: (fila) =>(
                <div>
                    {fila?.logo ? (
								<Image
									src={fila.logo}
									alt="Logo Beneficio"
									style={{
									height: "100%",
									width: "40px", // 🔹 Hacer que sea cuadrada antes de aplicar border-radius
									borderRadius: "25%", // 🔹 Hace la imagen circular
									objectFit: "cover", // 🔹 Evita deformaciones si la imagen no es cuadrada
									border: "2px solid #ddd" // 🔹 (Opcional) Agrega un borde suave
									}}
								/>
							):"-"}
                </div>
                
            )
        },
        {
            title: 'Acciones',
            align: 'center',
            width: '10%',
            render: (fila) => <MenuAcciones beneficioExterno={fila} />
        },
    ];

    return (
        <Table
            rowKey={(beneficiosExternos) => beneficiosExternos.id}
            dataSource={beneficiosExternos}
            columns={columnas}
        />
    )
}

const MenuAcciones = ({ beneficioExterno }) => {

    const { abrirModal: abrirModalEditar } = useModalEditarBeneficioExterno();
    const { abrirModal: abrirModalEliminar } = useModalEliminarBeneficioExterno();

    return (
        <Space>
            <Tooltip title="Editar" placement='bottom'>
                <Button type='text' shape="circle" icon={<EditOutlined />} onClick={() => abrirModalEditar(beneficioExterno.id)} />
            </Tooltip>
            <Tooltip title="Eliminar" placement='bottom'>
                <Button type='text' shape="circle" icon={<DeleteOutlined />} onClick={() => abrirModalEliminar(beneficioExterno)} />
            </Tooltip>
        </Space>
    )
}
export default BeneficiosExternosPage;