import { useAtom } from "jotai";
import { notification } from "antd";
import { formInstanceAtom, openModalEditarBeneficioExternoAtom, 
    beneficioExternoIdSelecionadoAtom, fileListAtom, loadAtom } from "../store/store";
import { BeneficiosExternosService } from "services/comunicacion-cultura/BeneficiosExternosService";
import { LoginService } from "services/LoginService";
// import { useNotificacion } from "@/stores/global";

import useBeneficiosExternos from "./useBeneficiosExternos";

const useModalEditarBeneficioExterno = () => {

    const [open, setOpen] = useAtom(openModalEditarBeneficioExternoAtom);
    const [fileList, setFileList] = useAtom(fileListAtom);
    const [load, setLoad] = useAtom(loadAtom);
    const [beneficioExternoIdSeleccionado, setBeneficioExternoIdSeleccionado] = useAtom(beneficioExternoIdSelecionadoAtom);
    //const notificacion = useNotificacion((state) => state.notificacion);

    const { fetchBeneficiosExternos } = useBeneficiosExternos();

    const actualizarBeneficioExterno = async (formulario) => {
        setLoad(true);
        let usuarioModificacion = {
            nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
            numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
          } 
          formulario.usuarioModificacion = usuarioModificacion;
          formulario.logo = fileList[0]?.uid;
        console.log("actualizarBeneficioExterno::", formulario);
        //formulario.id, { ...formulario }
        await BeneficiosExternosService.actualizar(formulario);
        
        setBeneficioExternoIdSeleccionado(undefined);
        fetchBeneficiosExternos();

        notification.success({
            message: "Beneficio externo actualizado",
            description: "El beneficio externo ha sido modificado con los cambios proporcionados."
        })
        setLoad(false);
        setOpen(false);
        return true;
    }

    const cargarDatosIniciales = async (isOpen, form) => {
        setOpen(isOpen)
        if (isOpen && beneficioExternoIdSeleccionado) {
            const response = await BeneficiosExternosService.buscarPorId(beneficioExternoIdSeleccionado);
            form.setFieldsValue({ ...response });
        }
    }

    const abrirModal = async (beneficioExternoId) => {
        console.log("entre a abrir el modal", beneficioExternoId);
        setBeneficioExternoIdSeleccionado(beneficioExternoId);
        setOpen(true);
    }

    return {
        open,
        setOpen,
        abrirModal,
        cargarDatosIniciales,
        actualizarBeneficioExterno
    };


}

export default useModalEditarBeneficioExterno;