import React,{useState, useEffect, useContext} from "react";
import { Input, Form, DatePicker, Select, Modal, message } from "antd";
import {
    visibleModalTransferirProyectoAtom,
    contratoSeleccionadoAtom
} from "../../BandejaAprobacionAdministracionPersonalStore";
import { useAtom, useSetAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";
import ColaboradorService from "services/ColaboradorService";
import {TransferirContratoService } from "services/contratos";
import moment from "moment"

const ModalTransferencia = () => {
    const [visible, setVisible] = useAtom(visibleModalTransferirProyectoAtom);
    const [sedes, setSedes] = useState([]);
    const [subsedes, setSubsedes] = useState([]);
    const [secciones, setSecciones] = useState([]);
    const [cargos, setCargos] = useState([]);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [formModalTransferir] = Form.useForm();
    const [sedeSeleccionada, setSedeSeleccionada] = useState(undefined);
    const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(undefined);
    const [seccionSeleccionada, setSeccionSeleccionada] = useState(undefined);
    const [cargoSeleccionado, setCargoSeleccionado] = useState(undefined);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [responsablesRenovarContrato, setresponsablesRenovarContrato] = useState([]);
    const [ responsableRenovarContratoSeleccionado,setresponsableRenovarContratoSeleccionado] = useState(undefined);
    const [administradoresObra, setAdministradoresObra] = useState([]);
    const [administradorObraSeleccionado, setAdministradorObraSeleccionado] = useState(undefined);
    const [ocultarAdminObra, setOcultarAdminObra] = useState(false);
    const [ocultarTipoServicio, setOcultarTipoServicio] = useState(false);
    const [loadTransferir, setLoadTransferir] = useState(false);
    useEffect(() => {
        const cargarDatos = async () => {
            const campoEnviado = await ColaboradorService.camposColaboradorListarNuevo(
                getUsuarioAutenticado().empresaSeleccionada.id
            );
            setSedes(campoEnviado.SEDE);
            setSubsedes(campoEnviado.SUBSEDE);
            setSecciones(campoEnviado.SECCION);
            setCargos(campoEnviado.CARGO);
        };
        cargarDatos();
    }, []);

    const handleOnCancel = () => {
        setVisible(false);
        formModalTransferir.resetFields();
    };

    const procesarCambios = (campoEnviado) => {
        if (campoEnviado.CARGO != null) {
            setCargos(campoEnviado.CARGO);
            formModalTransferir.setFieldsValue({ cargo: { id: "" } });
        }
        if (campoEnviado.SECCION != null) {
            setSecciones(campoEnviado.SECCION);
            formModalTransferir.setFieldsValue({ seccion: "" });
        }
        if (campoEnviado.SEDE != null) {
            setSedes(campoEnviado.SEDE);
            formModalTransferir.setFieldsValue({ sede: { id: "" } });
        }
        if (campoEnviado.SUBSEDE != null) {
            setSubsedes(campoEnviado.SUBSEDE);
            formModalTransferir.setFieldsValue({ area: "" });
        }
    };

    const onSelectSede = async (value, option) => {
        const sede = {
            id: option.value,
            nombre: option.children,
        };
        setSedeSeleccionada(sede);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SEDE
        );
        procesarCambios(campoEnviado);

        if (option.children === "PRINCIPAL") {
            setOcultarTipoServicio(true);
            setOcultarAdminObra(true);
          } else {
            setOcultarTipoServicio(false);
            setOcultarAdminObra(false);
          }
    };

    const onSelectSubsede = async (value, option) => {
        const subsede = {
            id: option.value,
            nombre: option.children,
        };
        setSubsedeSeleccionada(subsede);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SUBSEDE
        );
        procesarCambios(campoEnviado);
    };

    const onSelectSeccion = async (value, option) => {
        const seccion = {
            id: option.value,
            nombre: option.children,
        };
        setSeccionSeleccionada(seccion);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SECCION
        );
        procesarCambios(campoEnviado);
    };

    const onSelectCargo = async (value, option) => {
        const cargo = {
            id: option.value,
            nombre: option.children,
        };
        setCargoSeleccionado(cargo);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.CARGO
        );
        procesarCambios(campoEnviado);
    };

    const onClickBtnTransferir = () => {
        formModalTransferir
            .validateFields()
            .then(async (formulario) => {
                setLoadTransferir(true);
                console.log("seguimientoContratoId::", documentoSeleccionado.id);
                try {
                    await TransferirContratoService.transferir({
                        seguimientoContratoId: documentoSeleccionado.id,
                        sede: sedeSeleccionada.id,
                        subsede: subsedeSeleccionada.id,
                        seccion: seccionSeleccionada.id,
                        cargo: cargoSeleccionado.id,
                        sueldo: formulario.sueldo,
                        //fechaInicioContrato: formulario.fechaInicioContrato.format("DD/MM/YYYY"),
                        //fechaFinContrato: formulario.fechaFinContrato.format("DD/MM/YYYY"),
                        // fechaInicioContrato: formulario.fechaInicioContrato,
                        // fechaFinContrato: formulario.fechaFinContrato,
                        fechaInicioContratoAux: moment(formulario.fechaInicioContrato).format("DD/MM/YYYY"),
                        fechaFinContratoAux: moment(formulario.fechaFinContrato).format("DD/MM/YYYY"),
                        tipoServicio: formulario.tipoServicio,
                        administradorObraId : administradorObraSeleccionado,
                        responsableRenovarContratoId : responsableRenovarContratoSeleccionado
                    });
                    setVisible(false);
                    setLoadTransferir(false);
                    message.success("Se ha generado un nuevo contrato de transferencia");
                } catch (error) {
                    setLoadTransferir(false);
                    console.error(error);
                }
            })
            .catch((info) => {
                setLoadTransferir(false);
                console.log("Validate Failed:", info);
            });
    };

    const onSeleccionarResponsableRenovacionContrato = (value) => {
        setresponsableRenovarContratoSeleccionado(value);
      };

    const onSearchColaboradorResponsableContrato = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
          filtro: value.trim(),
          login: getUsuarioAutenticado().login,
        });
    
        if (value === "") {
            formModalTransferir.setFieldsValue({
            responsableRenovarContratoId: "",
          });
          setresponsableRenovarContratoSeleccionado(undefined);
          setresponsablesRenovarContrato([]);
        } else {
          setresponsablesRenovarContrato(respuesta.colaboradores);
        }
      };

      const onSeleccionarAdministradorObra = (value) => {
        setAdministradorObraSeleccionado(value);
      };
    
      const onSearchAdministradorObra = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
          filtro: value.trim(),
          login: getUsuarioAutenticado().login,
        });
    
        if (value === "") {
            formModalTransferir.setFieldsValue({
            responsableRenovarContratoId: "",
          });
          setAdministradorObraSeleccionado(undefined);
          setAdministradoresObra([]);
        } else {
          setAdministradoresObra(respuesta.colaboradores);
        }
      };

    return (
        <Modal
            title="Transferir Proyecto"
            open={visible}
            onCancel={handleOnCancel}
            onOk={onClickBtnTransferir}
            confirmLoading={loadTransferir} // 🔥 Agrega loading al botón "OK"
        >
            <Form layout="vertical" form={formModalTransferir}>
                <Form.Item
                    label="Sede"
                    name="sede"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSede}>
                        {sedes.length > 0 &&
                            sedes.map((sede) => {
                                return (
                                    <Select.Option key={sede.id} value={sede.id}>
                                        {sede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Proyecto"
                    name="proyecto"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSeccion}>
                        {secciones.length > 0 &&
                            secciones.map((seccion) => {
                                return (
                                    <Select.Option key={seccion.id} value={seccion.id}>
                                        {seccion.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Area"
                    name="area"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSubsede}>
                        {subsedes.length > 0 &&
                            subsedes.map((subsede) => {
                                return (
                                    <Select.Option key={subsede.id} value={subsede.id}>
                                        {subsede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Cargo"
                    name="cargo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectCargo}>
                        {cargos.length > 0 &&
                            cargos.map((cargo) => {
                                return (
                                    <Select.Option key={cargo.id} value={cargo.id}>
                                        {cargo.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    label="Proyecto"
                    name="proyecto"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSede}>
                        {sedes.length > 0 &&
                            sedes.map((sede) => {
                                return (
                                    <Select.Option key={sede.id} value={sede.id}>
                                        {sede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item> */}
                {/* <Form.Item
                    label="Area"
                    name="area"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSubsede}>
                        {subsedes.length > 0 &&
                            subsedes.map((subsede) => {
                                return (
                                    <Select.Option key={subsede.id} value={subsede.id}>
                                        {subsede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Cargo"
                    name="cargo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectCargo}>
                        {cargos.length > 0 &&
                            cargos.map((cargo) => {
                                return (
                                    <Select.Option key={cargo.id} value={cargo.id}>
                                        {cargo.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    label="Sueldo"
                    name="sueldo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {!ocultarTipoServicio && (
                    <Form.Item
                    label="Tipo Servicio"
                    name="tipoServicio"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                value: "SERVICIO TECNICO DE MUESTRERIA",
                                label: "SERVICIO TECNICO DE MUESTRERIA",
                            },
                            {
                                value: "PERFORACIÓN DE DIAMANTINA",
                                label: "PERFORACIÓN DE DIAMANTINA",
                            },
                            {
                                value: "PERFORACIÓN DE AIRE REVERSO",
                                label: "PERFORACIÓN DE AIRE REVERSO",
                            },
                            {
                                value: "PERFORACIÓN POZOS DE AGUA",
                                label: "PERFORACIÓN POZOS DE AGUA",
                            },
                            {
                                value: "PERFORACIÓN GEOTECNIA",
                                label: "PERFORACIÓN GEOTECNIA",
                              },
                        ]}
                    />
                </Form.Item>
                )}
                
                <Form.Item
                    label="Fecha Inicio Contrato"
                    name="fechaInicioContrato"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                    label="Fecha Fin Contrato"
                    name="fechaFinContrato"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                    name="responsableRenovarContratoId"
                    label="Responsable de Renovación de Contrato"
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={onSearchColaboradorResponsableContrato}
                      onChange={onSeleccionarResponsableRenovacionContrato}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {responsablesRenovarContrato !== undefined &&
                        responsablesRenovarContrato.map((colaborador) => {
                          return (
                            <Select.Option
                              key={colaborador.id}
                              value={colaborador.id}
                            >
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="administradorObraId"
                    label="Administrador de Obra"
                    hidden={ocultarAdminObra}
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={onSearchAdministradorObra}
                      onChange={onSeleccionarAdministradorObra}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {administradoresObra !== undefined &&
                        administradoresObra.map((colaborador) => {
                          return (
                            <Select.Option
                              key={colaborador.id}
                              value={colaborador.id}
                            >
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalTransferencia;
