import React, { useState, useEffect } from "react";
import { ContainerCrear } from "../../../../components/ContainerCrear";
import {
    Select,
    Form,
    Input,
    Row,
    Col,
    Button,
    notification,
    Space,
    Upload,
    message,
    Modal,
    Typography,
    Table,
    Descriptions,
    Switch,
} from "antd";
import {
    UploadOutlined,
    CheckOutlined,
    LoadingOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { useContext } from "react";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { useHistory } from "react-router-dom";
import { PlantillaContratoService } from "services/PlantillaContratoService";
import { TipoFirmaElectronica } from "../../../../enum/TipoFirmaElectronica";

export function PlantillaContratoCrearPage() {
    const [archivos, setArchivos] = useState([]);
    const [archivosList, setArchivosList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [formularioCrear] = Form.useForm();
    const [visibleModal, setVisibleModal] = useState(false);
    const [usuariosFirmantes, setUsuariosFirmantes] = useState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState([]);
    const [usuarioIdSeleccionado, setUsuarioIdSeleccionado] = useState([]);
    const [tipoFirmaDescripcion, setTipofirmaDescripcion] = useState("");
    const [tipoFirmaValue, setTipofirmaValue] = useState("");
    const [visibleInfoModal, setVisibleInfoModal] = useState(false);
    const [verificarCampos, setVerificarCampos] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [cont, setCont] = useState(1);
    let history = useHistory();
    const [camposValidos, setCamposValidos] = useState(undefined);

    const requiereFirmaRepresentanteLegal = Form.useWatch('requiereFirmaRepresentanteLegal', formularioCrear);

    const { Option } = Select;


    useEffect(() => {
        async function cargarDatos() {
            const usuarios = await PlantillaContratoService.listarUsuariosFirmantes(
                getUsuarioAutenticado().empresaSeleccionada.id
            );
            setUsuariosFirmantes(usuarios);
        }
        cargarDatos();
    }, []);

    // const datos = {
    //     organizacionId: getUsuarioAutenticado().organizacionId,
    //     empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
    // };

    const props = {
        onRemove: (file) => {
            setArchivos([]);
            setArchivosList([]);
            return {
                archivos: [],
            };
        },
        beforeUpload: (file) => {
            setArchivos(file);
            return false;
        },
        onChange: ({ file, fileList }) => {
            if (file.status !== "uploading") {
                setArchivosList(fileList);
                setArchivos(file);
                if (fileList.length == 0) {
                    formularioCrear.setFieldsValue({ archivo: undefined });
                    setCamposValidos(undefined);
                } else {
                    validar(file);
                    formularioCrear.setFieldsValue({ archivo: file });
                }
            }
        },
    };

    const validar = async (file) => {
        if (verificarCampos) {
            setVisibleInfoModal(true);
            setCamposValidos([]);
            const formData = new FormData();
            formData.append("archivo", file);
            const data = await PlantillaContratoService.validarNuevo(formData);
            if (data.length > 0) {
                setTimeout(function () {
                    setCamposValidos(data);
                }, 300);
            }
        } else setCamposValidos([true]);
    };

    async function handleOnClickSubirPlantillaContrato(plantilla) {
        if (plantilla.requiereFirmaRepresentanteLegal && usuarios.length > 0) {
            message.error("Debe Agregar un usuario");
            return;
        }

        let usuariosEnviadosTodos = [];
        //const usuariosEnviados;
        for (let i = 0; i < usuarios.length; i++) {
            const usuariosEnviados = {
                id: usuarios[i].usuarioId,
                orden: usuarios[i].key,
                nombreCompleto: usuarios[i].nombreCompleto,
                tipoFirma: usuarios[i].tipoFirmaValor,
            };
            usuariosEnviadosTodos.push(usuariosEnviados);
        }

        try {
            if (camposValidos !== undefined && camposValidos.length > 0 && !camposValidos.includes(false)) {
                setLoading(true);
                const plantillaContratoEnviada = {
                    nombre: plantilla.nombre,
                    empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
                    organizacionId: getUsuarioAutenticado().organizacionId,
                    usuarioCreacion: getUsuarioAutenticado().login,
                    usuariosFirmantes: usuariosEnviadosTodos,
                    requiereFirmaHolografaColaborador: plantilla.requiereFirmaHolografaColaborador,
                    requiereFirmaRepresentanteLegal: plantilla.requiereFirmaRepresentanteLegal
                };

                const formData = new FormData();
                formData.append("archivo", archivos);
                formData.append(
                    "plantillaContratoEnviada",
                    new Blob([JSON.stringify(plantillaContratoEnviada)], {
                        type: "application/json",
                    })
                );

                await PlantillaContratoService.insertarNuevo(formData);
                notification.success({
                    message: "Operación exitosa",
                    description: "Se registró la plantila contrato correctamente.",
                });
                setLoading(false);
                history.push("/contrato/plantilla-contrato/listar");
            } else {
                message.error("La plantilla no cuenta con los campos predefinidos.");
            }
        } catch (error) {
            console.error(error);
            notification.error({
                message: "No se puede registrar la plantilla contrato",
                description: error.response.data.mensaje,
            });
            setLoading(false);
        }
        // } else {
        //     message.error("Debe Agregar un usuario");
        // }
    }
    async function handleOnFinishAgregarFirmante(formulario) {
        if (usuarios.find((u) => u.usuarioId === usuarioIdSeleccionado) === undefined) {
            const nuevoUsuario = {
                key: cont,
                nombreCompleto: usuarioSeleccionado,
                tipoFirma: tipoFirmaDescripcion,
                usuarioId: usuarioIdSeleccionado,
                tipoFirmaValor: tipoFirmaValue,
            };
            formularioCrear.setFieldsValue({ firmante: usuarioIdSeleccionado });
            setUsuarios([...usuarios, nuevoUsuario]);
            setCont(cont + 1);
            setVisibleModal(false);
            setUsuarioSeleccionado([]);
            setUsuarioIdSeleccionado([]);
            setTipofirmaDescripcion("");
            setTipofirmaValue("");
        } else {
            message.error("El usuario firmante ya ha sido agregado.");
        }
    }

    function handleOnClickCancelar() {
        history.push("/contrato/plantilla-contrato/listar");
    }

    function handleOnClickQuitar(key) {
        let usuarioNuevo = usuarios.filter((usuario) => usuario.key !== key);
        let indiceNuevo = 0;
        usuarioNuevo.forEach((u) => {
            indiceNuevo += 1;
            u.key = indiceNuevo;
        });
        setUsuarios(usuarioNuevo);
        setCont(usuarios.length);
    }

    function handleOnclickAgregarFirmante() {
        setVisibleModal(true);
    }
    function cerrarModal() {
        setVisibleModal(false);
    }

    function handleOnChangeTipoFirma(value) {
        if (value === TipoFirmaElectronica.CON_APROBACION) {
            setTipofirmaDescripcion("firma con aprobación");
            setTipofirmaValue(TipoFirmaElectronica.CON_APROBACION);
        } else if (value === TipoFirmaElectronica.AUTOMATIZADA) {
            setTipofirmaDescripcion("firma automatizada");
            setTipofirmaValue(TipoFirmaElectronica.AUTOMATIZADA);
        }
    }

    function handleOnChangeUsuarioFirmante(value) {
        let usuarioNombrecompleto = usuariosFirmantes.find((obj) => obj.id === value).nombreCompleto;
        let usuarioId = usuariosFirmantes.find((obj) => obj.id === value).id;
        setUsuarioSeleccionado(usuarioNombrecompleto);
        setUsuarioIdSeleccionado(usuarioId);
    }

    const columnas = [
        {
            title: "N°",
            dataIndex: "key",
            key: "key",
        },

        {
            title: "Usuario",
            dataIndex: "nombreCompleto",
            key: "nombreCompleto",
        },
        {
            title: "Tipo Firma",
            dataIndex: "tipoFirma",
            key: "tipoFirma",
        },
        {
            title: "Acciones",
            //dataIndex:"acciones",
            render: (fila) => (
                <React.Fragment>
                    {usuarios.length >= 1 ? <a onClick={() => handleOnClickQuitar(fila.key)}>Quitar</a> : null}
                </React.Fragment>
            ),
        },
    ];
    // usuarios = [
    //   {
    //     key: '1',
    //     usuario: usuarioSeleccionado,
    //     tipoFirma: tipoFirma,
    //   },

    // ];

    const onClickBoton = () => {
        setVisibleInfoModal(true);
    };

    const onClickVerificar = (value) => {
        formularioCrear.setFieldsValue({ archivo: undefined });
        setArchivos([]);
        setArchivosList([]);
        setVerificarCampos(value);
    };

    return (
        <ContainerCrear
            titulo="Crear Plantilla Contrato"
            descripcion="Crear un plantilla contrato dentro de tu organización permitirá notificar los contratos a los colaboradores."
            colSpan={12}
        >
            <Form layout="vertical" size="large" onFinish={handleOnClickSubirPlantillaContrato} form={formularioCrear}>
                <Form.Item
                    label="Descripción"
                    name="nombre"
                    rules={[
                        {
                            required: true,
                            message: "Ingrese la descripción de la plantilla de Contrato",
                        },
                    ]}
                >
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item
                    label="Subir Archivo"
                    name="archivo"
                    valuePropName="archivo"
                    rules={[
                        {
                            required: true,
                            message: "subir el archivo de la plantilla de Contrato",
                        },
                    ]}
                >
                    <Space direction="vertical" size={20}>
                        <Row /*style={{float:"right"}}*/>
                            <Switch
                                onChange={onClickVerificar}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={verificarCampos}
                            />
                            <div style={{ paddingLeft: "15px" }}> Verificar campos obligatorios </div>
                        </Row>
                        <Row>
                            <Upload
                                {...props}
                                maxCount={1}
                                fileList={archivosList}
                                accept={
                                    ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                }
                            >
                                <Button icon={<UploadOutlined />}>Subir</Button>
                            </Upload>
                        </Row>
                        {verificarCampos && (
                            <Row>
                                <a
                                    style={{ textDecoration: "underline" }}
                                    onClick={onClickBoton}
                                    className="custom-color-element"
                                >
                                    Ver campos predefinidos para plantilla de contrato
                                </a>
                            </Row>
                        )}
                    </Space>
                </Form.Item>

                <Form.Item
                    label="¿Requiere Firma Holografa Colaborador?"
                    name="requiereFirmaHolografaColaborador"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label="¿Requiere Firma Representante Legal?"
                    name="requiereFirmaRepresentanteLegal"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                {requiereFirmaRepresentanteLegal &&
                    <>
                        <Form.Item
                            label="Firmante"
                            name="firmante"
                            rules={[
                                {
                                    required: true,
                                    message: "Seleccionar firmante",
                                },
                            ]}
                        >
                            <Button onClick={handleOnclickAgregarFirmante}>Agregar Firmante</Button>
                        </Form.Item>

                        <Form.Item>
                            {usuarios.length > 0 && (
                                <Table rowKey={(fila) => fila.key} columns={columnas} dataSource={usuarios} />
                            )}
                        </Form.Item>
                    </>
                }

                <Row justify="center">
                    <Space>
                        <Col>
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="default" onClick={handleOnClickCancelar}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                                    Crear plantilla contrato
                                </Button>
                            </Form.Item>
                        </Col>
                    </Space>
                </Row>
            </Form>

            <Modal
                visible={visibleModal}
                title="Agregar Usuario Firmante"
                //onOk={this.handleOk}
                onCancel={cerrarModal}
                footer={null}
                destroyOnClose={true}
            >
                <Form layout="vertical" onFinish={handleOnFinishAgregarFirmante}>
                    <Form.Item
                        label="Seleccionar Firmante"
                        name="firmanteSeleccionado"
                        rules={[
                            {
                                required: true,
                                message: "Seleccione firmante",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{ width: 300 }}
                            placeholder="Seleccione Firmante"
                            optionFilterProp="children"
                            onChange={handleOnChangeUsuarioFirmante}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {usuariosFirmantes.length > 0 &&
                                usuariosFirmantes.map((usuario) => {
                                    return (
                                        <Option key={usuario.id} value={usuario.id}>
                                            {usuario.nombreCompleto}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Tipo Firma"
                        name="tipoFirma"
                        rules={[
                            {
                                required: true,
                                message: "Debe seleccionar Tipo Firma",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Seleccione Tipo Firma"
                            optionFilterProp="children"
                            onChange={handleOnChangeTipoFirma}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value={TipoFirmaElectronica.CON_APROBACION}>Firma con Aprobación</Option>
                            <Option value={TipoFirmaElectronica.AUTOMATIZADA}>Firma Automatizada</Option>
                        </Select>
                    </Form.Item>

                    <Row align="center">
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Seleccionar
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
            <InfoModal visible={visibleInfoModal} setVisible={setVisibleInfoModal} camposValidos={camposValidos} />
        </ContainerCrear>
    );
}
const InfoModal = ({ visible, setVisible, camposValidos }) => {
    const styleRojo = {
        fontSize: "20px",
        color: "red",
    };
    const styleVerde = {
        fontSize: "20px",
        color: "green",
    };

    return (
        <Modal
            width={700}
            getContainer={false}
            visible={visible}
            closable={false}
            footer={null}
            title={"Campos predefinidos"}
        >
            {/* <div style={{border: "1px solid gray", padding: "30px"}}> */}
            <div style={{ paddingLeft: "50px" }}>
                <Descriptions
                    title={"Lista de parámetros obligatorios en la plantilla de contrato"}
                    size="small"
                    column={1}
                    bordered={camposValidos !== undefined}
                    colon={false}
                >
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<COLABORADOR_NOMBRE_COMPLETO> "}</strong>: Nombre completo del colaborador.
                            </div>
                        }
                    >
                        {" "}
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[0] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<COLABORADOR_NUMERO_DOCUMENTO_IDENTIDAD> "}</strong>: Documento de identidad
                                del colaborador.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[1] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<COLABORADOR_CARGO> "}</strong>: Nombre del cargo.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[2] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<REMUNERACION> "}</strong>: Remuneración del colaborador.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[3] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    {/* <Descriptions.Item
            label={
              <div>
                <strong>{"<REMUNERACION_LETRAS> "}</strong>: Remuneración del colaborador en letras.
              </div>
            }
          >
            {camposValidos === undefined ? (
              ""
            ) : camposValidos.length == 0 ? (
              <LoadingOutlined />
            ) : camposValidos[4] ? (
              <CheckCircleOutlined style={styleVerde} />
            ) : (
              <CloseCircleOutlined style={styleRojo} />
            )}
          </Descriptions.Item> */}
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<FECHA_CONTRATO_INICIO> "}</strong>: Fecha inicio de contrato.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[4] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<FECHA_CONTRATO_FIN> "}</strong>: Fecha fin de contrato.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[5] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                </Descriptions>
                <br />
            </div>
            <div style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => setVisible(false)} disabled={camposValidos?.length == 0}>
                    {camposValidos === undefined ? "Cerrar" : "Aceptar"}
                </Button>
            </div>
            {/* </div> */}
        </Modal>
    );
};
