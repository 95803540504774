import React from "react";
import { BeneficiosExternosService } from "services/comunicacion-cultura/BeneficiosExternosService";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import useReconocimientos from "./useReconocimientos";
import { ReconocimientoService } from "services/comunicacion-cultura/ReconocimientoService";

const { confirm } = Modal;

const useModalEliminarReconocimiento = () => {

    const { fetchReconocimientos } = useReconocimientos();
    //const notificacion = useNotificacion((state) => state.notificacion);

    const abrirModal = (reconocimiento) => {
        confirm({
            title: `Desea eliminar el siguiente reconocimiento?`,
            icon: <ExclamationCircleFilled />,
            content: `${reconocimiento.titulo}`,
            okText: 'Sí, Eliminar!',
            okType: 'danger',
            onOk() {
                return ReconocimientoService.eliminar(reconocimiento.id)
                    .then(() => {
                        fetchReconocimientos();

                        notification.success({
                            message: "Reconocimiento eliminado",
                            description: "La acción se realizó exitosamente."
                        })
                    })
                    .catch((error) => {
                        console.error('Error al eliminar el reconocimiento:', error);
                        notification.error({
                            title: 'Error',
                            content: 'No se pudo eliminar el reconocimiento. Por favor, intente de nuevo.',
                        });
                    });
            },
        });
    }

    return {
        abrirModal
    };


}

export default useModalEliminarReconocimiento;