import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Breadcrumb, Button, Card, Dropdown, Input, Space, Table } from 'antd';
import { Provider as JotaiProvider, useSetAtom } from "jotai";

import {
    HomeOutlined,
    FilePdfOutlined,
    UserOutlined,
    MoreOutlined,
    UploadOutlined,
    EditOutlined,
    SearchOutlined
} from "@ant-design/icons"

import { useListarTareas } from './hooks';
import { documentoSeleccionadoAtom, visibleModalSubirDocumentoAtom, visibleModalVisorPdfAtom } from './store/Store';
import { ModalSubirDocumento, ModalVisorPdf } from './components';
import FormularioSeguimientoService from 'services/FormularioSeguimientoService';
import { TareasPendientesService } from 'services';
import Highlighter from 'react-highlight-words';

const BandejaTareasPage = () => {
    return (
        <JotaiProvider>
            <Navegacion />
            <Card bordered={false}>
                <ListaDocumentos />
                <ModalVisorPdf />
                <ModalSubirDocumento />
            </Card>
        </JotaiProvider>
    );
};

const ListaDocumentos = () => {

    const { documentos } = useListarTareas(true);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (confirm, clearFilters) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex, nestedKey) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Filtrar
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(confirm, clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const fieldValue = nestedKey ? record[dataIndex]?.[nestedKey] : record[dataIndex];
            return fieldValue?.toString().toLowerCase().includes(value.toLowerCase());
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columnas = [
        {
            title: 'Colaborador',
            dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
            // render: (documento) => <ColumnaColaborador documento={documento} />,
            // ...getColumnSearchProps('colaborador', 'nombreCompleto'),
        },
        {
            title: 'Colaborador',
            dataIndex: ["colaborador", "nombreCompleto"],
            // render: (documento) => <ColumnaColaborador documento={documento} />,
            ...getColumnSearchProps('colaborador', 'nombreCompleto'),
        },
        {
            title: 'Documento',
            render: (documento) => <ColumnaDocumento documento={documento} />
        },
        {
            width: '5px',
            render: (documento) => <MenuAcciones documento={documento} />
        }
    ];

    return (
        <Table
            rowKey={(item) => item.id}
            columns={columnas}
            dataSource={documentos}
            pagination={false}
            size="small"
        />
    );

}

const MenuAcciones = ({ documento }) => {

    const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
    const setVisibleModalSubirDocumento = useSetAtom(visibleModalSubirDocumentoAtom);

    let items = [];

    if (documento.tareaPendiente) {
        items.push({
            key: 'completar-tarea-pendiente',
            label: 'Completar Tarea',
            icon: <EditOutlined style={{ fontSize: "16px" }} />
        })
    } else {
        if (documento.interno) {
            items.push({
                key: 'subir-documento',
                label: 'Subir Documento',
                icon: <UploadOutlined style={{ fontSize: "16px" }} />
            })
        }

        if (!documento.interno) {
            items.push({
                key: 'ver-documento',
                label: 'Ver Documento',
                icon: <FilePdfOutlined style={{ fontSize: "16px" }} />
            })
        }
    }

    const onClick = ({ key }) => {
        if (key === "ver-documento") {
            verDocumento();
        } else if (key === "subir-documento") {
            subirDocumento();
        } else if (key === "completar-tarea-pendiente") {
            verTareaPendiente();
        }
    };

    const verTareaPendiente = async () => {
        //const response = await TareasPendientesService.buscarPorId(documento.tareaPendienteId);
        //console.log("response==>", response);
        // const respuesta = await TareasPendientesService.buscarPorId(formularioSeleccionado.tareaPendienteId);
        // respuesta.variablesFormulario = respuesta.variablesGlobales; //se hace esta conversión porque se está reutilizando el api de tareaspendientes y las variables se encuentran variablesGlobales
    }

    const verDocumento = async () => {
        const respuesta = await FormularioSeguimientoService.getUrlDocumentoPdf(documento.id);
        setDocumentoSeleccionado({ ...documento, urlDocumento: respuesta.urlDocumento });
        setVisibleModalVisorPdf(true);
    }

    const subirDocumento = async () => {
        setDocumentoSeleccionado(documento);
        setVisibleModalSubirDocumento(true);
    }

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{ fontSize: "18px", fontWeight: "bold", color: "gray" }} />
            </Button>
        </Dropdown>
    );


}

const ColumnaColaborador = ({ documento }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: "5px" }}>
                <Avatar size="large" icon={<UserOutlined />} />
            </span>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <span>{documento.colaborador.nombreCompleto}</span>
                <span>{documento.colaborador.numeroDocumentoIdentidad}</span>
            </div>
        </div>
    )
}

const ColumnaDocumento = ({ documento }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: "5px" }}>
                <FilePdfOutlined style={{ fontSize: "24px" }} />
            </span>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                {documento.nombreDocumento}
            </div>
        </div>
    )
}

const Navegacion = () => {
    return (
        <Breadcrumb style={{ marginBottom: "10px" }}>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Consultar
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Bandeja de Tareas
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default BandejaTareasPage;