import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { beneficiosExternosAtom } from "../store/store";
import { BeneficiosExternosService } from "services/comunicacion-cultura/BeneficiosExternosService";

const useBeneficiosExternos = () => {

    const [beneficiosExternos, setBeneficiosExternos] = useAtom(beneficiosExternosAtom);
    const [loading, setLoading] = useState(true);

    const fetchBeneficiosExternos = async () => {
        setLoading(true);
        const response = await BeneficiosExternosService.listar();
        setBeneficiosExternos(response);
        setLoading(false);
    }

    return {
        loading,
        beneficiosExternos,
        fetchBeneficiosExternos
    };
}

export default useBeneficiosExternos;
