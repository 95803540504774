import React, { useContext, useEffect, useState } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { useHistory, Route, Redirect } from "react-router-dom";
import { getPermiso } from "./Permisos";
import UsuarioService from "services/UsuarioService";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

const path = window.location.pathname;

export const PrivateRoute = ({ component: Component, ...rest }) => {
  //TODO: revisar el contexto SecurityContextProvider  
  const history = useHistory();
  const { estaAutenticado, setEmpresaSeleccionada } = useContext(SecurityContext);
  const [estaAutorizado, setEstaAutorizado] = useState(null);

  // if (estaAutenticado() === true) {

  //   // const opciones = await UsuarioService.listarOpciones();
  //   // console.log("opciones====>", opciones);

  //   console.log("uri===>", path);
  //   // const permiso = rutas[path];
  //   const permiso = getPermiso(path);
  //   console.log("permiso obtenido===>", permiso);

  //   setEmpresaSeleccionada(buscadorParametros.get("sesionId"));
  // }

  useEffect(() => {
    const inicializar = async () => {
      const opciones = await UsuarioService.listarOpciones();
      const permiso = getPermiso(path);

      const rutasPermitidas = [
        "/",
        "/not-found",
        "/dashboard",
        "/login",
        "/cuenta/cambiar-clave",
        "/cuenta/sesiones"
      ];

      setEstaAutorizado(rutasPermitidas.includes(path) || opciones.includes(permiso));

      // if (
      //   path === "/"
      //   || path === "/not-found"
      //   || path === "/dashboard"
      //   || path === "/login"
      //   || path === "/cuenta/cambiar-clave"
      //   || opciones.includes(permiso)
      // ) {
      //   setEstaAutorizado(true);
      // } else {
      //   setEstaAutorizado(false);
      // }

      setEmpresaSeleccionada(buscadorParametros.get("sesionId"));
    }

    if (estaAutenticado()) {
      inicializar();
    }
  }, [estaAutenticado])

  if (!estaAutenticado()) {
    return <Redirect to={"/login" + parametros} />;
  }

  if (estaAutenticado() && estaAutorizado === null) {
    return <div>Cargando...</div>; // Mostrar un loading mientras se valida
  }

  if (estaAutenticado() && estaAutorizado === false) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );


  // return (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       estaAutenticado() === true ? (
  //         <Component {...props} />
  //       ) : (
  //         <Redirect to={"/login" + parametros} />
  //       )
  //     }
  //   />
  // );
};
