import React, { useEffect, useState } from "react";
import { Button, Col, Input, Modal, Row, Space, Select, Form } from "antd";
import { useAtom } from "jotai";
import { loadAtom } from "../store/store";
import { useModalEditarReconocimiento } from "../hooks";

const ModalEditarReconocimiento = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  
  const [load, setLoad] = useAtom(loadAtom);
  const { open, setOpen, cargarDatosIniciales, actualizarReconocimiento, colaboradores,
    onSearchColaboradores } = useModalEditarReconocimiento();

  useEffect(() => {
    cargarDatosIniciales(open, form);
  }, [open]);

  
  return (
    <Modal
      title="Editar Reconocimiento"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      centered
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onFinish={actualizarReconocimiento}>
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="empresaId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="usuarioRegistro" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="estado" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Título"
              name="titulo"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el título ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese descripción",
                },
              ]}
            >
              <TextArea size="large" rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Colaborador"
              name="numeroDocumentoIdentidad"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el colaborador",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                placeholder="Ingrese al colaborador"
                onSearch={onSearchColaboradores}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                // onClear={() => setFiltroColaborador(undefined)}
              >
                {colaboradores !== undefined &&
                  colaboradores?.map((colaborador) => (
                    <Select.Option
                      key={colaborador.numeroDocumentoIdentidad}
                      value={colaborador.numeroDocumentoIdentidad}
                    >
                      {colaborador.nombreCompleto}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginBottom: 0 }}>
          <Space>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" loading={load}>
                  Aceptar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button onClick={() => setOpen(false)}> Cancelar</Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditarReconocimiento;
