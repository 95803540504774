import React from "react";
import { EstadoNotificacion } from "enums/EstadoNotificacion";
import { Tag } from "antd";
import { TagNotificado } from "./TagNotificado";
import { EstadoDocumento } from "enums/EstadoDocumento";

const EtiquetaEstadoNotificacion = ({ documento }) => {
    if(documento.estadoDocumento === EstadoDocumento.ERROR || documento.estadoProcesoNombre === "Incompleta")
        return <Tag color="red" className="milegajo-ant-tag">ERROR</Tag>;
    switch (documento.estadoNotificacion) {
        
        case EstadoNotificacion.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoNotificacion.ENVIADO:
            return (
                <TagNotificado documento={documento} color="green" className="milegajo-ant-tag pointer">
                    ENVIADO
                </TagNotificado>
            );

        case EstadoNotificacion.NO_APLICA:
                return <Tag color = "default" className="milegajo-ant-tag">NO APLICA</Tag>;

        case EstadoNotificacion.ERROR:
            return <Tag color="red" className="milegajo-ant-tag">ERROR</Tag>;

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }
}

export default EtiquetaEstadoNotificacion;