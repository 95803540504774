import { useAtom } from "jotai";
import { asistenciaSeleccionadaAtom, openModalVisorPdfAtom, urlPdfAtom } from "../store/Store";
import { AsistenciaService } from "services/notificacion";

const useModalPdf = () => {

    const [open, setOpen] = useAtom(openModalVisorPdfAtom);
    const [asistenciaSeleccionada, setAsistenciaSeleccionada] = useAtom(asistenciaSeleccionadaAtom);
    const [urlPdf, setUrlPdf] = useAtom(urlPdfAtom);

    const abrirModal = (asistencia) => {
        setAsistenciaSeleccionada(asistencia);
        setOpen(true);
    }

    const cerrarModal = () => {
        setAsistenciaSeleccionada(undefined);
        setOpen(false);
    }

    const fetchUrlPdf = async () => {
        const response = await AsistenciaService.getUrlPdf(asistenciaSeleccionada.id);
        setUrlPdf(response.urlPdf);
    }

    return {
        open,
        asistenciaSeleccionada,
        urlPdf,
        fetchUrlPdf,
        abrirModal,
        cerrarModal
    }

}

export default useModalPdf;