import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.BASE;

const BeneficiosExternosService = {
  async insertar(beneficioExterno) {
    const respuesta = await ApiService(URL_API).post("/comunicaciones-cultura/beneficioExterno", beneficioExterno);
    return respuesta.data;
  },
  async actualizar(beneficioExterno) {
    const respuesta = await ApiService(URL_API).put("/comunicaciones-cultura/beneficioExterno", beneficioExterno);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/comunicaciones-cultura/beneficiosExternos");
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await ApiService(URL_API).delete(`/comunicaciones-cultura/beneficioExterno/${id}`);
    return respuesta.data;
  },
  async buscarPorId(id) {
		const respuesta = await ApiService(URL_API).get(`/comunicaciones-cultura/beneficioExterno/${id}`);
		return respuesta.data;
	},
  // async cargarColaboradorExcel(formData) {
  //   const respuesta = await ApiService(URL_API).post("/comunicaciones-cultura/publicacion/cargarColaboradorExcel", formData);
  //   return respuesta.data;
  // },
};

export { BeneficiosExternosService };
