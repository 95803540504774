import { useAtom } from "jotai";
import { vacacionesAtom } from "../ControlVacacionalStorage";

const useCargarVacaciones = () => {
  const [vacaciones, setVacaciones] = useAtom(vacacionesAtom);

  return { vacaciones, setVacaciones };
};

export default useCargarVacaciones;
