export const rutas = {
    "/dashboard": "",
    "/personal/trabajador/editar/:idColaborador": "mantenimiento.colaborador",
    "/personal/trabajador/crear": "mantenimiento.colaborador",
    "/personal/trabajador": "mantenimiento.colaborador",
    "/personal/postulante": "reclutamiento-y-seleccion.postulantes",
    "/personal/postulante/crear": "",
    "/personal/postulante/editar/:postulanteId": "",
    "/colaborador/carga-masiva": "",
    "/cargo": "mantenimiento.cargo",
    "/cargo/crear": "",
    "/cargo/editar/:idCargo": "",
    "/seguridad/filtro": "",
    "/seguridad/filtro/crear": "",
    "/sede": "mantenimiento.sede",
    "/sede/crear": "",
    "/sede/editar/:idSede": "",
    "/seccion": "mantenimiento.seccion",
    "/seccion/crear": "",
    "/seccion/editar/:idSeccion": "",
    "/subsede": "mantenimiento.subsede",
    "/subsede/crear": "",
    "/subsede/editar/:idSubsede": "",
    "/perfil/crear": "",
    "/perfil": "mantenimiento.perfil",
    "/perfil/editar/:idPerfil": "",
    "/tipoPlanilla/crear": "",
    "/tipoPlanilla": "mantenimiento.tipoPlanilla",
    "/tipoPlanilla/editar/:idTipoPlanilla": "",
    "/documento-laboral/envio": "notificacion.crear",
    "/documento-laboral": "",
    "/documento-laboral/listar-detalle": "",
    "/listar-detalle/editar/:detalleEnvioId": "",
    "/contrato/crear": "contrato.crear",
    "/contrato/seguimiento": "contrato.seguimiento",
    "/contrato/seguimiento/:envioId/detalle": "",
    "/contrato/listarContratoDetalle": "",
    "/legajos": "legajos.consultas",
    "/v2/legajos": "legajo.v2.consultas",
    "/legajos/documento": "legajos.documentos",
    "/legajos/documento/crear": "",
    "/cuenta/cambiar-clave": "",
    "/cuenta/actualizar-clave": "",
    "/documento-personal/listar-detalle": "",
    "/documento-laboral-porAprobar": "notificacion.documentosPorAprobar",
    "/documento-laboral-porAprobar/listar-detalle": "",
    "/documento-laboral/listarEnvioNotificacion": "",
    "/generarClave": "",

    "/usuario": "mantenimiento.usuario",
    "/usuario/crear": "mantenimiento.usuario",
    "/usuario/editar/:idUsuario": "mantenimiento.usuario",

    "/rol/crear": "mantenimiento.rol",
    "/rol": "mantenimiento.rol",
    "/rol/editar/:idRol": "mantenimiento.rol",

    "/eliminarDatos": "configuracion.eliminarDatos",
    "/configuracion/formularios/listar": "configuracion.formularios",
    "/configuracion/formularios/crear": "configuracion.formularios",
    "/configuracion/formularios/:formularioId/disenar": "configuracion.formularios",
    "/configuracion/formularios/:formularioId/editar": "configuracion.formularios",

    "/configuracion/misCertificados": "configuracion.misCertificados",
    "/configuracion/certificadosDigitales": "configuracion.certificadosDigitales",
    "/configuracion/tipo-documentos/crear": "",
    "/configuracion/tipo-documentos/listar": "configuracion.tipoDocumentos",
    "/configuracion/tipo-documentos/:idTipoDocumento/editar": "",

    "/configuracion/carpetas-legajos/crear": "configuracion.carpetasLegajos",
    "/configuracion/carpetas-legajos/listar": "configuracion.carpetasLegajos",
    "/configuracion/carpetas-legajos/:idCarpeta/editar": "configuracion.carpetasLegajos",

    "/configuracion/firmas-holografas/listar": "configuracion.MiFirmaHolografa",
    "/configuracion/firmas-holografas/crear": "",
    "/configuracion/verificar-datos-colaborador": "configuracion.verficarDatosColaborador",
    "/configuracion/verificar-datos-colaborador/notificar": "",
    "/configuracion/plantilla-envio-documentos/listar": "configuracion.plantillaEnvioDocumentos",
    "/configuracion/plantilla-envio-documentos/crear": "",
    "/configuracion/plantilla-envio-documentos/:idPlantilla/editar": "",
    "/notificacion/onboarding": "configuracion.onBoardingIndividual",
    "/notificacion/onboarding-masivo": "notificacion.onboarding.masivo",
    "/notificacion/asistencia/laredo": "notificacion.asistencia.laredo",
    "/notificacion/flujo-firma": "notificacion.documento.flujo-firma",
    "/notificacion/flujo-firma/:flujoFirmaId/configurar": "",
    "/notificacion/comunicado/notificar": "notificacion.comunicados.crear",
    "/notificacion/formulario/seguimiento": "notificacion.seguimiento.formularios",
    "/notificacion/formulario/notificar": "notificacion.seguimiento.formularios",
    "/notificacion/formulario/seguimiento/:envioId/detalle": "",
    "/contrato/plantilla-contrato/crear": "",
    "/contrato/plantilla-contrato/listar": "contrato.registrarPlantilla",
    "/contrato/plantilla-contrato/editar/:idPlantillaContrato": "",
    "/contrato/listarEnvioContrato": "",
    "/contrato/seguimiento/renovacion-contratos": "contrato.seguimiento.renovacioncontratos",
    "/explomin/contrato/bandeja/aprobacion/administracion-personal": "explomin.contrato.seguimiento.administracionpersonal",
    "/explomin/contrato/bandeja/aprobacion/reclutamiento-y-seleccion": "explomin.contrato.seguimiento.rys",
    "/explomin/contrato/bandeja/seguimento/tiempo-servicio": "explomin.contrato.seguimiento.tiempo.servicio",
    "/notificacion/firma-documento-generico/crear": "notificacion.firma-documento-generico.crear",
    "/notificacion/firma-documento-generico/listar": "notificacion.firma-documento-generico.listar",
    "/notificacion/firma-documento-generico/mis-documentos": "notificacion.firma-documento-generico.bandeja-aprobador",
    "/notificacion/seguimiento/": "notificacion.seguimiento.v2",
    "/notificacion/seguimiento/detalle/:categoria/:envioId": "",
    "/consulta-documento": "consulta.documento",
    "/notificacion-por-completar": "notificacion.completar",
    "/notificacion-por-completar/:envioId": "",
    "/listar-observaciones-colaboradores": "legajos.observacionesColaborador",
    "/Onboarding/consulta": "legajos.consultaOnboarding",
    "/onboarding/seguimiento": "legajos.seguimientoOnboarding",
    "/onboarding/seguimiento/:envioId/:descripcion": "",
    "/listar-reportes": "legajos.reportes",
    "/listar-legajosCargados": "legajos.carga",
    "/notificacion/evaluacion/notificar": "notificacion.evaluacion",
    "/tipoAbsentismo/crear": "",
    "/tipoAbsentismo": "configuracion.tipoAbsentismo",
    "/tipoAbsentismo/editar/:idTipoAbsentismo": "",
    "/solicitud-vacaciones/consulta": "legajos.consultaVacaciones",
    "/ausencias/solicitudes": "ausencias.solicitudes",
    "/ausencias/vacaciones": "vacaciones.control-vacacional",
    "/consultar/descarga-masiva/crear": "",
    "/consultar/descarga-masiva": "legajos.descargamasiva",
    "/consultar/bandeja-tareas": "",
    "/v3/legajos/:colaboradorId": "",
    "/asistencia/horarios": "",
    "/asistencia/horarios/crear": "",
    "/asistencia/turnos": "",
    "/asistencia/asignacion-turnos": "",
    "/evaluacion/seguimiento": "legajos.seguimientoEvaluaciones",
    "/contratos-vencidos/seguimiento": "consulta.seguimientoContrato",
    "/comunicaciones-cultura/publicaciones": "",
    "/comunicaciones-cultura/publicaciones/crear": "",
    "/comunicaciones-cultura/publicaciones/editar/:formularioId": "",
    "/comunicaciones-cultura/reconocimientos": "",
    "/comunicaciones-cultura/beneficios-externos": ""
}

export const getPermiso = (ruta) => {
    for (const path in rutas) {
        // Convertimos los :params en regex dinámicos
        // const regex = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
        const regex = new RegExp(`^${path.replace(/:\w+/g, "([^/]+)")}$`);

        if (regex.test(ruta)) {
            return rutas[path];
        }
    }
    return undefined; // Si no encuentra coincidencia
};