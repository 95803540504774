import { BaseService } from "services/BaseService";

const AsistenciaService = {

    async listar(params) {
        const respuesta = await BaseService.get(`/admin/asistencia`, { params });
        return respuesta.data;
    },

    async listarParticipantes(asistenciaId) {
        const respuesta = await BaseService.get(`/admin/asistencia/${asistenciaId}/participantes`);
        return respuesta.data;
    },

    async buscarExpositor(asistenciaId) {
        const respuesta = await BaseService.get(`/admin/asistencia/${asistenciaId}/expositor`);
        return respuesta.data;
    },

    async getUrlPdf(asistenciaId) {
        const respuesta = await BaseService.get(`/admin/asistencia/${asistenciaId}/url-pdf`);
        return respuesta.data;
    },

    async regitrar(data) {
        const respuesta = await BaseService.post("/admin/asistencia", data);
        return respuesta.data;
    },

    async notificarExpositor(detalleAsistenciaId) {
        const respuesta = await BaseService.post(`/admin/asistencia/${detalleAsistenciaId}/expositor/notificar`);
        return respuesta.data;
    },



}
export default AsistenciaService;