import React, { useEffect } from "react";
import { Table } from "antd";
import { useAtom } from "jotai";
import * as atoms from "../../SeguimientevaluacionStore";
import EvaluacionService from "services/EvaluacionService";
import FiltroPlanAccion from "./FiltroPlanAccion";

const TablaPlanAccion = () => {

    const [planesAccion, setPlanesAccion] = useAtom(atoms.planesAccionAtom);
    const [filtroEstadoPlanAccion, setFiltroEstadoPlanAccion] = useAtom(atoms.filtroEstadoPlanAccionAtom);

    useEffect(() => {
        const cargarDatos = async () => {
            const respuesta = await EvaluacionService.listarPlanesAccion(filtroEstadoPlanAccion);
            setPlanesAccion(respuesta);
        }
        cargarDatos();
    }, [filtroEstadoPlanAccion])

    const columnas = [
        {
            title: "Colaborador",
            dataIndex: "colaboradorNombreCompleto",
            width: '20%',
            // align: "center"
        },
        {
            title: "Jefe Inmediato",
            dataIndex: "jefeInmediatoNombreCompleto",
            // align: "center"
        },
        {
            title: "Descripción del Plan",
            dataIndex: "descripcion",
            // align: "center",
        },
        {
            title: "Fecha Programada",
            align: "center",
            render: (registro) => {
                if (registro.fecha != null) {
                    var fecha = new Date(registro.fecha)
                    fecha.setDate(fecha.getDate() + 1)
                    return fecha.toLocaleDateString()
                }
                else {
                    return ""
                }
            }

        },
        {
            title: "Fecha Ejecución",
            align: "center",
            render: (registro) => {
                if (registro.fechaEjecucion != null) {
                    var fecha = new Date(registro.fechaEjecucion)
                    fecha.setDate(fecha.getDate() + 1)
                    return fecha.toLocaleDateString()
                }
                else {
                    return "-"
                }
            }

        },


    ]
    return (
        <React.Fragment>
            <FiltroPlanAccion />
            <Table
                rowKey={(planAccion) => (planAccion.id)}
                columns={columnas}
                dataSource={planesAccion}
                pagination={{
                    pageSize: 30
                }}
            />
        </React.Fragment>

    )
}
export default TablaPlanAccion;