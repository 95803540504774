import { atom } from "jotai";

export const beneficiosExternosAtom = atom([]);

export const openModalCrearBeneficioExternoAtom = atom(false);
export const loadingCrearBeneficioExternoAtom = atom(false);
export const openModalEditarBeneficioExternoAtom = atom(false);
export const beneficioExternoIdSelecionadoAtom = atom(undefined);
export const fileListAtom = atom([]);
export const loadAtom = atom(false);
export const formInstanceAtom = atom(undefined);