import React, { useState, useEffect } from "react";
import {
  Table,
  Spin,
  Tag,
  Form,
  Row,
  Col,
  Button,
  Select,
  notification,
} from "antd";
import {
  LoadingOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";
import { saveAs } from "file-saver";
import { useListarProyectos } from "../../hooks";
import {
  loadingContratosSeguimientos,
  paginaActualAtom,
  filtroColaboradorAtom,
  filtroProyectoAtom,
  filtroEstadoColaboradorAtom,
  contratosSeguimientoAtom,
} from "../../BandejaSeguimientoTiempoServicioStore";
import { BandejaSeguimientoAdministracionPersonalService } from "services/contratos/BandejaSeguimientoAdministracionPersonalService";
import ColaboradorService from "services/ColaboradorService";

const ListaContratosTiempoServicioSeguimiento = () => {
  const [contratosSeguimiento, setContratosSeguimiento] = useAtom(
    contratosSeguimientoAtom
  );
  const [loadingSeguimiento, setLoadingSeguimiento] = useAtom(
    loadingContratosSeguimientos
  ); // Manejo de loading global
  const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
  const [loading, setLoading] = useState(false);
  const [formFiltro] = Form.useForm();
  const [loadingExportar, setLoadingExportar] = useState(false);
  const { proyectos, setProyectos } = useListarProyectos();
  const [colaboradores, setColaboradores] = useState(undefined);
  const [filtroColaborador, setFiltroColaborador] = useAtom(
    filtroColaboradorAtom
  );
  const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
  const [filtroEstadoColaborador, setFiltroEstadoColaborador] = useAtom(
    filtroEstadoColaboradorAtom
  );

  const columnas = [
    {
      title: "Número Documento Identidad",
      dataIndex: "numeroDocumentoIdentidad",
      width: "15%",
    },
    {
      title: "Colaborador",
      width: "25%",
      render: (fila) => (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {fila.nombreCompleto}
          </div>
          {fila?.estadoRegistro === "CESADO" && <Tag color="red">CESADO</Tag>}
          {fila?.estadoRegistro === "ACTIVO" && <Tag color="green">ACTIVO</Tag>}
          {fila?.estadoRegistro === "ONBOARDING" && (
            <Tag color="blue">ONBOARDING</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Proyecto",
      dataIndex: "proyecto",
      width: "20%",
    },
    {
      title: "Tipo Contrato",
      width: "20%",
      render: (fila) => (
        <div>
          {fila?.tipoContrato =="INDETERMINADO" ?(
            <Tag color="green">INDETERMINADO</Tag>
          ):(<Tag color="blue">PLAZO FIJO</Tag>)}
        </div>
      ),
    },
    {
      title: "Tiempo Servicio Días",
      dataIndex: ["tiempoServicio", "dia"],
      width: "20%",
    },
    {
      title: "Tiempo Servicio Años",
      dataIndex: ["tiempoServicio", "anio"],
      width: "20%",
    },
  ];

  const fetchContratosSeguimiento = async (pagina) => {
    try {
      setLoading(true);
      const response =
        await BandejaSeguimientoAdministracionPersonalService.listarColaboradoresContratoTiempoServicio(
          {
            filtroColaborador: filtroColaborador,
            filtroProyecto: filtroProyecto,
            filtroEstadoColaborador: filtroEstadoColaborador,
            pagina,
            cantidad: 10,
          }
        );

      setContratosSeguimiento({
        contratos: response.colaboradores,
        paginaActual: response.paginaActual,
        totalRegistros: response.totalRegistros,
      });
    } catch (error) {
      console.error("Error al cargar contratos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPaginaActual(1);
    fetchContratosSeguimiento(0); // Cargar desde la primera página
  }, [filtroColaborador, filtroProyecto, filtroEstadoColaborador]);

  const loadContratosSeguimiento = (pagina) => {
    fetchContratosSeguimiento(pagina);
  };

  const onChangePagination = (page) => {
    setPaginaActual(page);
    const paginaBackend = page - 1;
    loadContratosSeguimiento(paginaBackend);
  };

  const handleOnclickExportarSeguimiento = async () => {
    try {
      setLoadingExportar(true);

      const respuesta =
        await BandejaSeguimientoAdministracionPersonalService.descargarReporteTiempoServicioExcel(
          {
            filtroColaborador: filtroColaborador,
            filtroProyecto: filtroProyecto,
            filtroEstadoColaborador: filtroEstadoColaborador,
          }
        );
      setLoadingExportar(false);
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);
    } catch (error) {
      setLoadingExportar(false);
      notification.error({
        message: "No hay Tiempo de Servicio para exportar",
        description: error.response.data.mensaje,
      });
    }
  };

  const onClickFiltrar = async () => {
    setLoading(true);
    setFiltroProyecto(formFiltro.getFieldValue("proyecto"));
    setFiltroColaborador(formFiltro.getFieldValue("colaborador"));
    setFiltroEstadoColaborador(formFiltro.getFieldValue("estadoColaborador"));
    loadContratosSeguimiento(0);
    setLoading(false);
  };

  const onSearchColaboradores = async (value) => {
    if (value === "") {
      setColaboradores([]);
      return;
    }
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
      filtro: value.trim(),
    });
    setColaboradores(respuesta.colaboradores);
  };

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 28 }} />}
      spinning={loadingSeguimiento}
      tip="Cargando..."
    >
      <div>
        <Form layout="vertical" form={formFiltro}>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label="Colaborador" name="colaborador">
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  showSearch
                  placeholder="Ingrese colaborador"
                  onSearch={onSearchColaboradores}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onClear={() => setFiltroColaborador(undefined)}
                >
                  {colaboradores !== undefined &&
                    colaboradores?.map((colaborador) => (
                      <Select.Option
                        key={colaborador.numeroDocumentoIdentidad}
                        value={colaborador.numeroDocumentoIdentidad}
                      >
                        {colaborador.nombreCompleto}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Proyecto" name="proyecto">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Ingrese el proyecto"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onClear={() => setFiltroProyecto(undefined)}
                >
                  {proyectos !== undefined &&
                    proyectos?.map((proyecto) => (
                      <Select.Option key={proyecto.id} value={proyecto.nombre}>
                        {proyecto.nombre}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Estado Colaborador" name="estadoColaborador">
                <Select
                  allowClear
                  placeholder="Seleccion el estado"
                  onClear={() => setFiltroEstadoColaborador(undefined)}
                >
                  <Select.Option value={"ACTIVO"}>ACTIVO</Select.Option>
                  <Select.Option value={"CESADO"}>CESADO</Select.Option>
                  <Select.Option value={"ONBOARDING"}>ONBOARDING</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item label=" "></Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={onClickFiltrar}
                  loading={loading}
                  block
                >
                  Filtrar
                </Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                <Button
                  icon={<DownloadOutlined />}
                  onClick={handleOnclickExportarSeguimiento}
                  loading={loadingExportar}
                  block
                >
                  Exportar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* <br /> */}
        <Table
          rowKey={(item) => item.id}
          columns={columnas}
          dataSource={contratosSeguimiento.contratos}
          pagination={{
            pageSize: 10,
            total: contratosSeguimiento.totalRegistros,
            current: paginaActual,
            showTotal: (total) => `Total ${total} colaboradores`,
            onChange: onChangePagination,
          }}
          size="small"
          bordered={true}
          style={{ marginTop: "10px" }}
        />
      </div>
    </Spin>
  );
};

export default ListaContratosTiempoServicioSeguimiento;
