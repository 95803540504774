import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const EntrevistaService = {
  async insertar(entrevista) {
    const respuesta = await ApiService(URL_API).post("/entrevistas", entrevista);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/entrevistas");
    return respuesta.data;
  },
  async listarPorPostulante(postulanteId, convocatoriaId) {
    const respuesta = await ApiService(URL_API).get("/entrevistas/postulantes", {
      params: {
        postulanteId: postulanteId,
        convocatoriaId: convocatoriaId
      },
    });
    return respuesta.data;
  },

  async listarConFiltros(fechaEntrevista, estado) {
    const respuesta = await ApiService(URL_API).get("/entrevistas/listar-con-filtros", {
      params: {
        fechaEntrevista: fechaEntrevista,
        estado: estado,
      }
    })
    return respuesta.data;
  },

  async actualizarEstado(entrevista) {
    const respuesta = await ApiService(URL_API).put("/entrevistas/actualizar-estado", entrevista);
    return respuesta.data;
  },

  async descargarHojaVida(entrevistaId) {
    const respuesta = await ApiService(URL_API).get(`/entrevistas/${entrevistaId}/download/hoja-vida`);
    return respuesta.data;
  },

  async descargarEvaluacionConocimiento(entrevistaId) {
    const respuesta = await ApiService(URL_API).get(`/entrevistas/${entrevistaId}/download/evaluacion-conocimiento`);
    return respuesta.data;
  },

  async descargarPsicotest(entrevistaId) {
    const respuesta = await ApiService(URL_API).get(`/entrevistas/${entrevistaId}/download/psicotest`);
    return respuesta.data;
  }

};

export { EntrevistaService };
