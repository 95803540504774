import React, { useEffect, useState, useContext } from "react";
import { Container } from "../../../components/Container";
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { Table, Space, Breadcrumb, Select, Form, DatePicker } from "antd";
import { columnas } from "./componentes/Columnas";
import TipoDocumentoService from "../../../services/TipoDocumentoService";
import { tipoDocumentosAtom, carpetasAtom, enviosAtom, codigosOpcionesAtom } from "./ListarSeguimientoStore";
import {
    categoriaSeguimientoAtom,
    tipoDocumentoSeguimientoAtom,
    periodoAtom,
    paginaSeguimientoAtom,
    totalPaginacionEnviosAtom,
    paginActualAtom,
    filtroTipoDocumentoAtom,
    filtroPeriodoAtom,
    botonAtrasDetalleSeguimientoAtom,
    filtroEstadoAtom,
} from "store/local/seguimiento/SeguimientoStore";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { EnvioService } from "services/EnvioService";
import CarpetaService from "services/CarpetaService";
import { BotonCrearNotificacion } from "./componentes/Botones";
import moment from "moment";
import { ModalAsignarCarpeta } from "./componentes/ModalAsignarCarpeta";
import { OpcionService } from "services";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

function ListarSeguimiento() {
    const [paginaSeguimiento, setPaginaSeguimiento] = useAtom(paginaSeguimientoAtom);
    const [totalPaginacion, setTotalPaginacion] = useAtom(totalPaginacionEnviosAtom);
    const [paginActual, setPaginActual] = useAtom(paginActualAtom);
    const { RangePicker } = DatePicker;
    const { Option, OptGroup } = Select;

    const [, setCodigosOpciones] = useAtom(codigosOpcionesAtom);
    const [envios, setEnvios] = useAtom(enviosAtom);
    const [, setCarpetas] = useAtom(carpetasAtom);
    const [tipoDocumentos, setTipoDocumentos] = useAtom(tipoDocumentosAtom);
    const [categoriaListar] = useAtom(categoriaSeguimientoAtom);
    const [tipoDocumentoListar, setTipoDocumentoListar] = useAtom(tipoDocumentoSeguimientoAtom);
    const [periodoListar] = useAtom(periodoAtom);
    const [loading, setLoading] = useState(false);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [filtroTipoDocumento, setFiltroTipoDocumento] = useAtom(filtroTipoDocumentoAtom);
    const [filtroPeriodo, setFiltroPeriodo] = useAtom(filtroPeriodoAtom);
    const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
    const [botonAtras, setBotonAtras] = useAtom(botonAtrasDetalleSeguimientoAtom);
    let paginacion = 30;
    const [form] = Form.useForm();

    useEffect(() => {
        const cargaInicial = async () => {
            setPaginActual(1);
            setPaginaSeguimiento(0);
            form.resetFields();

            const tipoDocumento = await TipoDocumentoService.listarPorUsuarioYCategorizado(
                getUsuarioAutenticado().login
            );
            const arrayTipo = Object.entries(tipoDocumento);
            setTipoDocumentos(arrayTipo);

            const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
            setCodigosOpciones(codigosOpciones);
            const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            setCarpetas(_carpetas);
        };
        cargaInicial();
        //cargarDatos();
        if (botonAtras === true) {
            setBotonAtras(false);
            form.setFieldsValue({
                periodo: filtroPeriodo,
                tipoDocumento: filtroTipoDocumento,
                estado: filtroEstado,
            });
            cargarDatos(
                form.getFieldValue("tipoDocumento"),
                form.getFieldValue("periodo")[0].format("MM/YYYY"),
                form.getFieldValue("periodo")[1].format("MM/YYYY"),
                form.getFieldValue("estado")
            );
        } else {
            cargarDatos();
        }
    }, []);

    const cargarDatos = async (
        tipoDocumento = "",
        periodoIinicio = moment().startOf("year").format("MM/YYYY"),
        periodoFin = moment().endOf("year").format("MM/YYYY"),
        estado = "TODOS",
        pagina = 0
    ) => {
        setLoading(true);
        const envios_ = await EnvioService.listarSeguimiento(
            getUsuarioAutenticado().empresaSeleccionada.id,
            "",
            tipoDocumento,
            periodoIinicio,
            periodoFin,
            pagina,
            paginacion,
            getUsuarioAutenticado().login,
            estado
        );
        //console.log(envios_)
        setEnvios(envios_.envios);
        setTotalPaginacion(envios_.totalEnvios);
        setLoading(false);
    };

    const onClickDate = async () => {
        setPaginActual(1);
        setPaginaSeguimiento(0);

        // if (form.getFieldValue("periodo")) {

        //   cargarDatos(form.getFieldValue("tipoDocumento"),form.getFieldValue("periodo")[0].format("MM/YYYY"),form.getFieldValue("periodo")[1].format("MM/YYYY"))

        // } else {
        //   cargarDatos(form.getFieldValue("tipoDocumento"))
        // }
        if (form.getFieldValue("periodo")) {
            cargarDatos(
                form.getFieldValue("tipoDocumento"),
                form.getFieldValue("periodo")[0].format("MM/YYYY"),
                form.getFieldValue("periodo")[1].format("MM/YYYY"),
                form.getFieldValue("estado")
            );
        } else {
            cargarDatos(form.getFieldValue("tipoDocumento"), null, null, form.getFieldValue("estado"));
        }
        setFiltroPeriodo(form.getFieldValue("periodo"));
    };

    const onSelectTipoDocumento = async () => {
        setPaginActual(1);
        setPaginaSeguimiento(0);
        setTipoDocumentoListar(form.getFieldValue("tipoDocumento"));
        // if (form.getFieldValue("periodo")) {
        //   cargarDatos(form.getFieldValue("tipoDocumento"),form.getFieldValue("periodo")[0].format("MM/YYYY"),form.getFieldValue("periodo")[1].format("MM/YYYY"))
        // } else {
        //   cargarDatos(form.getFieldValue("tipoDocumento"))
        // }
        if (form.getFieldValue("periodo")) {
            cargarDatos(
                form.getFieldValue("tipoDocumento"),
                form.getFieldValue("periodo")[0].format("MM/YYYY"),
                form.getFieldValue("periodo")[1].format("MM/YYYY"),
                form.getFieldValue("estado")
            );
        } else {
            cargarDatos(form.getFieldValue("tipoDocumento"), null, null, form.getFieldValue("estado"));
        }
        setFiltroTipoDocumento(form.getFieldValue("tipoDocumento"));
    };
    const onChangePagination = async (page) => {
        setPaginActual(page);
        setPaginaSeguimiento(page - 1);
        cargarDatos(
            form.getFieldValue("tipoDocumento"),
            form.getFieldValue("periodo")[0].format("MM/YYYY"),
            form.getFieldValue("periodo")[1].format("MM/YYYY"),
            form.getFieldValue("estado"),
            page - 1
        );
    };
    const onSelectEstado = async () => {
        setPaginActual(1);
        setPaginaSeguimiento(0);
        if (form.getFieldValue("periodo")) {
            cargarDatos(
                form.getFieldValue("tipoDocumento"),
                form.getFieldValue("periodo")[0].format("MM/YYYY"),
                form.getFieldValue("periodo")[1].format("MM/YYYY"),
                form.getFieldValue("estado")
            );
        } else {
            cargarDatos(form.getFieldValue("tipoDocumento"), null, null, form.getFieldValue("estado"));
        }
        setFiltroEstado(form.getFieldValue("estado"));
    };

    return (
        <Container
            icono={
                <Breadcrumb itemRender>
                    <Breadcrumb.Item href="/dashboard">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/notificacion/seguimiento">Seguimiento</Breadcrumb.Item>
                </Breadcrumb>
            }
            botonExtra={<BotonCrearNotificacion />}
        >
            <Space size={80}>
                <Form
                    layout="inline"
                    form={form}
                    initialValues={{
                        //categoria: categoriaListar ? categoriaListar : "",
                        tipoDocumento: "", //tipoDocumentoListar ? tipoDocumentoListar : "",
                        periodo:
                            periodoListar == null
                                ? [moment().startOf("year"), moment().endOf("year")]
                                : periodoListar.length != 0
                                    ? periodoListar
                                    : [moment().startOf("year"), moment().endOf("year")],
                        estado: "TODOS",
                    }}
                >
                    <Space size={30}>
                        <Form.Item label="Tipo de Documento" name="tipoDocumento">
                            <Select style={{ width: 300 }} onSelect={() => onSelectTipoDocumento()} showSearch>
                                <Option value="">Todos</Option>
                                {tipoDocumentos.map((t) => {
                                    if (t[1].length > 0) {
                                        return (
                                            <OptGroup label={t[0]} key={t[0]}>
                                                {t[1].map((data) => (
                                                    <Option key={data.id} value={data.id}>
                                                        {data.nombre}
                                                    </Option>
                                                ))}
                                            </OptGroup>
                                        );
                                    }
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Periodo" name="periodo">
                            <RangePicker
                                allowClear={false}
                                onChange={onClickDate}
                                format={"MM/YYYY"}
                                picker="month"
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item label="Estado" name="estado">
                            <Select style={{ width: 300 }} onSelect={() => onSelectEstado()}>
                                <Option value="TODOS">Todos</Option>
                                <Option value="PENDIENTES">Pendientes</Option>
                                <Option value="COMPLETADOS">Completados</Option>
                            </Select>
                        </Form.Item>
                    </Space>
                </Form>
            </Space>

            <br />
            <Table
                id="table"
                rowKey={(envio) => envio.id}
                dataSource={envios}
                columns={columnas}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 28 }} />,
                    tip: "Cargando...",
                }}
                pagination={{
                    size: "small",
                    defaultCurrent: paginActual ? paginActual : 1,
                    current: paginActual,
                    total: totalPaginacion,
                    showSizeChanger: false,
                    showTotal: (total) => `Total ${total} documentos`,
                    pageSize: paginacion,
                    onChange: onChangePagination,
                }}
            />
            <ModalAsignarCarpeta />
        </Container>
    );
}

export default ListarSeguimiento;
