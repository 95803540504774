import React, { useRef, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles
import "react-quill-emoji/dist/quill-emoji.css"; // Emoji styles
import Emoji from "react-quill-emoji";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Upload,
  Space,
  Form,
  Col,
  Row,
  Select,
  Modal,
  notification,
  message,
  Tag,
  List,
  Carousel,
  Spin,
  Divider,
  Tooltip
} from "antd";
import {
  UploadOutlined,
  TagOutlined,
  FilterOutlined,
  MessageOutlined,
  CheckOutlined,
  EyeOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
  BgColorsOutlined,
  LikeOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { TwitterPicker } from "react-color";
import { useAtom } from "jotai";
import { Headers, TipoUsuario } from "enums";
import { LoginService } from "services/LoginService";
import ImgCrop from "antd-img-crop";
import moment from "moment";
import "moment/locale/es";
import { Etiqueta } from "enums/comunicaciones-cultura/Etiqueta";
import { Filtro } from "enums/comunicaciones-cultura/Filtro";
import { PublicacionService } from "services/comunicacion-cultura/PublicacionService";
import ModalCargaExcel from "./ModalCargaExcel";
import { useHistory } from "react-router-dom";
import { colaboradoresAgregadosAtom } from "../../CrearPublicacionStore";
import { FormatoCarga } from "enums/FormatoCarga";
const { TextArea } = Input;
const { Text, Title } = Typography;
Quill.register("modules/emoji", Emoji);

const FormCrearPublicacion = () => {
  const [value, setValue] = useState("");
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }], // Headers
      ["bold", "italic", "underline"], // Text styles
      ["emoji"], // Emoji picker button
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      ["link"], // Link option
      ["clean"], // Clear formatting
    ],
    clipboard: {
      matchVisual: false, // Desactiva estilos visuales automáticos
    },
    "emoji-toolbar": true, // Enable emoji toolbar
    "emoji-textarea": false, // Disable emoji textarea
    "emoji-shortname": true, // Allow emoji shortnames like ":smile:"
  };

//   const formats = [
//     'header',
//     'font',
//     'size',
//     'bold',
//     'italic',
//     'underline',
//     'strike',
//     'blockquote',
//     'list',
//     'bullet',
//     'indent',
//     'link',
//     'image',
//     'color'
// ];
  const [formPublicacion] = Form.useForm();
  const [previewLinks, setPreviewLinks] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uidList, setUidList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [texto, setTexto] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [modalExcelVisible, setModalExcelVisible] = useState(false);
  const [estadoCarga, setEstadoCarga] = useState(undefined);
  const [listaRespuesta, setListaRespuesta] = useAtom(
    colaboradoresAgregadosAtom
  );
  const [archivoExcel, setArchivoExcel] = useState([]);
  const [modalElegirColor, setModalElegirColorVisible] = useState(false);
  const [color, setColor] = useState("#87D068");
  const [openPrevisualizar, setOpenPrevisualizar] = useState(false);
  const [dataPrevisualizar, setDataPrevisualizar] = useState([]);

  const datos = {
    uid: texto,
  };

  const cleanHTML = (html) => {
    return html.replace(/<p><br><\/p>/g, "");
  };

  const onChangeColor = (color) => {
    setColor(color.hex);
    setModalElegirColorVisible(false);
  };

  const props = {
    name: "file",
    multiple: true,
    action:
      process.env.REACT_APP_ENDPOINT +
      "comunicaciones-cultura/cargarDocumentos",
    headers: {
      ["authorization"]: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
      [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN,
    },
    data: datos,
    beforeUpload(file) {
      setTexto(file.uid);
      return file;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  function handleOnClickCancelar() {
    // history.push("/personal/trabajador");
  }

  const handleCancel = () => setPreviewVisible(false);

  // const htmlToText = (html) => {
  //   const tempElement = document.createElement("div"); // Crea un elemento temporal
  //   tempElement.innerHTML = html; // Asigna el HTML como contenido
  //   return tempElement.textContent || tempElement.innerText; // Devuelve solo el texto
  // };

  const htmlToPlainTextWithNewlines = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Reemplazar etiquetas `<br>` con saltos de línea
    tempElement.querySelectorAll("br").forEach((br) => br.replaceWith("\n"));

    // Reemplazar etiquetas `<p>` con texto seguido de saltos de línea
    tempElement.querySelectorAll("p").forEach((p) => {
      const textNode = document.createTextNode("\n");
      p.appendChild(textNode);
    });

    // Obtener el texto con saltos respetados
    return tempElement.textContent.trim();
  };

  const onClickBtnCrearPublicacion = async (form) => {
    try {
      setLoading(true);
      let usuarioRegistro = {
        nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
        numeroDocumentoIdentidad:
          LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
      };

      let filtroMapeado = {
        tipo: form.filtro,
        datos: null,
      };
      // form.filtro = filtroMapeado;
      // form.usuarioRegistro = usuarioRegistro;
      form.imagenes = fileList.map((c) => c.uid);

      console.log("ff____:", form);

      console.log("ff2____:", htmlToPlainTextWithNewlines(form.mensaje));

      const __publicacion = {
        //mensajeHTML: cleanHTML(form.mensaje),
        mensajeHTML: form.mensaje,
        mensaje: htmlToPlainTextWithNewlines(form.mensaje),
        etiqueta: form.etiqueta,
        usuarioRegistro: usuarioRegistro,
        filtro: filtroMapeado,
        imagenes: form.imagenes,
        colorEtiqueta: color,
        fijar:form.fijar
      };

      const formData = new FormData();
      if (form.filtro != null && form.filtro === Filtro.COLABORADOR) {
        formData.append("fileExcel", form.cargarArchivoExcel.file);
      }
      console.log("formulariofinal::", __publicacion);
      formData.append(
        "formulario",
        new Blob([JSON.stringify(__publicacion)], {
          type: "application/json",
        })
      );

      const respuesta = await PublicacionService.insertar(formData);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró la publicación exitosamente",
      });
      setLoading(false);
      history.push(`/comunicaciones-cultura/publicaciones`);
    } catch (error) {
      setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrio un error al registrar la publicación",
      });
    }
  };

  const handleOnClickVistaPrevia = async () => {
    try {
      const values = await formPublicacion.validateFields(); // Valida el formulario
      
      const formData = new FormData();
      values.imagenes = fileList.map((c) => c.uid);
      values.colorEtiqueta = color;
      formData.append(
        "formulario",
        new Blob([JSON.stringify(values)], {
          type: "application/json",
        })
      );

      const respuesta = await PublicacionService.guardarTemporalmente(formData);
      setDataPrevisualizar(respuesta);
      console.log("11111111:::", respuesta);
      // const mensajeHTML = typeof values.mensaje === "string"
      // ? values.mensaje
      // : values.mensaje.toString();
      // console.log("fileList:::", fileList);
      // setDataPrevisualizar([{
      //   mensajeHTML: values.mensaje,
      //   etiqueta: values.etiqueta,
      //   colorEtiqueta: color,
      //   imagenes: fileList,
      // }]);
      setOpenPrevisualizar(true); // Abre el modal
    } catch (error) {
      console.error("Error al cargar vista previa:", error);
      notification.error({
        message: "Sin información",
        description: "Debe completar la información obligatoria del formulario",
      });
    }
  };

  const onCerrarModalColor = () => {
    setModalElegirColorVisible(false);
  };

  const onChangeFiltro = (value) => {
    // if (Filtro.COLABORADOR === value) {
    //   setModalExcelVisible(true);
    // }
    // setEstadoCarga(undefined);
    // setModalExcelVisible(false);
    //setColaboradoresAgregados([]);
    setListaRespuesta([]);
    if (value !== null) {
      setEstadoCarga(value);
    }
  };

  const onClickCargarBoton = () => {
    if (estadoCarga === Filtro.COLABORADOR) {
      setModalExcelVisible(true);
    }
  };

  const handleOnClickVerListaColores = () => {
    setModalElegirColorVisible(true);
  };

  const propsCargaExcel = {
    onRemove: (file) => {
      setArchivoExcel([]);

      return {
        setArchivoExcel: [],
      };
    },
    beforeUpload: (file) => {
      if (
        file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setArchivoExcel(file);
        return false;
      }
    },
    archivoExcel,
  };

  const handleClosePreview = () => {
    setOpenPrevisualizar(false);
  };

  return (
    <>
      <style>
        {`
        .custom-quill .ql-container {
          height: 200px; /* Altura total del contenedor */
        }

        .custom-quill .ql-editor {
          min-height: 150px; /* Altura mínima del área de edición */
          max-height: 400px; /* Opcional: Altura máxima del área de edición */
          overflow-y: auto;  /* Habilita el scroll vertical si es necesario */
        }
        `}
      </style>
      <Form
        layout="vertical"
        size="medium"
        form={formPublicacion}
        onFinish={onClickBtnCrearPublicacion}
        initialValues={{ fijar: false }}
      >
        <Form.Item
          label={
            <span>
              <MessageOutlined style={{ marginRight: 5 }} />
              Escribir Mensaje
            </span>
          }
          name="mensaje"
          rules={[
            {
              validator: (_, value) => {
                const trimmedValue = value?.replace(/<[^>]*>?/gm, "").trim();
                if (!trimmedValue) {
                  return Promise.reject(
                    new Error("El campo Mensaje es obligatorio.")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ReactQuill
            value={value}
            onChange={(val) => {
              setValue(val);
              formPublicacion.setFieldsValue({ mensaje: val }); // Sincroniza con el formulario
            }}
            modules={modules}
            theme="snow"
            placeholder="Escribe algo..."
            className="custom-quill"
          />
        </Form.Item>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={16}>
            <Form.Item
              label={
                <span>
                  <TagOutlined style={{ marginRight: 5 }} />
                  Agregar Etiqueta
                </span>
              }
              name="etiqueta"
              rules={[
                {
                  required: true,
                  message: "El campo Etiqueta es obligatorio.",
                },
              ]}
            >
              <Input
                placeholder="Ingrese etiqueta"
                onChange={() => formPublicacion.validateFields(["etiqueta"])}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={" "} name="color">
              <Button
                type="default"
                icon={<BgColorsOutlined />}
                onClick={handleOnClickVerListaColores}
              >
                Color
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={" "}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.etiqueta !== curValues.etiqueta
              }
            >
              {({ getFieldValue }) => (
                <Tag
                  color={color}
                  style={{
                    alignSelf: "center",
                    marginRight: "16px",
                    borderRadius: "12px",
                  }}
                >
                  {getFieldValue("etiqueta") || "Color de Etiqueta"}
                </Tag>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 0 }}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  <FilterOutlined style={{ marginRight: 5 }} />
                  Filtrar
                </span>
              }
              name="filtro"
              rules={[
                {
                  required: false,
                  // message: "El campo Filtrar es obligatorio.",
                },
              ]}
            >
              <Select
                placeholder="Seleccione filtro"
                onChange={onChangeFiltro}
                allowClear
              >
                <Select.Option value={Filtro.COLABORADOR}>
                  {Filtro.COLABORADOR}
                </Select.Option>
                {/* <Select.Option value={Filtro.CARGO}>
                {Filtro.CARGO}
              </Select.Option>*/}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {estadoCarga != undefined && estadoCarga === Filtro.COLABORADOR && (
              <Form.Item
                label={
                  <span style={{ marginLeft: 10 }}>
                    <UsergroupAddOutlined style={{ marginRight: 5 }} />
                    Seleccionar colaboradores
                  </span>
                }
                name="cargarArchivoExcel"
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      archivoExcel.length !== 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Seleccione el Archivo Excel")
                          ),
                  },
                ]}
              >
                <Upload
                  {...propsCargaExcel}
                  maxCount={1}
                  accept={FormatoCarga.EXCEL.extension}
                >
                  <Button block style={{ whiteSpace: "normal", width: "100%" }}>
                    Cargar Archivo Excel&nbsp;
                  </Button>
                </Upload>
              </Form.Item>
              // <Form.Item
              //   label={
              //     <span style={{ marginLeft: 10 }}>
              //       <UsergroupAddOutlined style={{ marginRight: 5 }} />
              //       Seleccionar los colaboradores
              //     </span>
              //   }>
              //   <Button onClick={onClickCargarBoton}>
              //     Cargar archivo excel
              //   </Button>
              // </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item name="fijar" valuePropName="checked">
          <Checkbox>Fijar</Checkbox>
        </Form.Item>
        <Form.Item
          label={
            <span style={{ marginLeft: "4px" }}>
              <UploadOutlined style={{ marginRight: 5 }} />
              Agregar Imagenes
              {/* Agregar el texto informativo sobre las dimensiones */}
              <p
                style={{
                  marginTop: "8px",
                  fontSize: "12px",
                  color: "#8c8c8c", // color gris claro
                  textAlign: "left",
                }}
              >
                <strong>Dimensión ideal de las imagenes:</strong> ancho: 720px x
                alto: 500px
              </p>
            </span>
          }
        >
          <div style={{ marginLeft: "4px" }}>
            <ImgCrop
              rotationSlider
              aspectSlider
              minZoom={0.5}
              aspect={2 / 1}
              cropperProps={{ restrictPosition: false }}
              quality={1}
              showGrid
            >
              <Upload
                {...props}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                accept={undefined}
              >
                {"+ Subir"}
              </Upload>
            </ImgCrop>

            <Modal
              open={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
              destroyOnClose
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>
        </Form.Item>

        {/* Checkboxes */}
        {/* <div style={{ marginBottom: "16px" }}>
        <Checkbox
          checked={notification}
          onChange={(e) => setNotification(e.target.checked)}
        >
          Enviar notificación push
        </Checkbox>
        <Checkbox
          style={{ marginLeft: "16px" }}
          checked={previewLinks}
          onChange={(e) => setPreviewLinks(e.target.checked)}
        >
          Previsualizar links
        </Checkbox>
      </div> */}
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="default"
                  icon={<EyeOutlined />}
                  onClick={handleOnClickVistaPrevia}
                >
                  Vista Previa
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  htmlType="submit"
                  loading={loading}
                >
                  Publicar
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
      {/* <ModalCargaExcel visibleModal={modalExcelVisible} onCerrarModal={onCerrarModal} /> */}
      <Modal
        open={modalElegirColor}
        title="Selecciona el color de la etiqueta"
        footer={null}
        onCancel={onCerrarModalColor}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        destroyOnClose
      >
        <TwitterPicker
          colors={[
            "#FF6900",
            "#FCB900",
            "#7BDCB5",
            "#00D084",
            "#8ED1FC",
            "#0693E3",
            "#ABB8C3",
            "#EB144C",
            "#F78DA7",
            "#9900EF",
            "#87d068",
          ]}
          color={color}
          onChangeComplete={onChangeColor}
        />
      </Modal>

      {openPrevisualizar && (
        <>
          <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
            <Space style={{ justifyContent: 'space-between' }}>
              <span>
                <EyeOutlined /> Vista Previa
              </span>
              <Tooltip title="Cerrar" placement='bottom'>
                <CloseOutlined onClick={handleClosePreview} style={{ color: 'black' }} /> 
              </Tooltip>
              
            </Space>
          </Divider>
         
            <List
            itemLayout="vertical"
            size="large"
            dataSource={dataPrevisualizar}
            renderItem={(item) =>
              <PublicacionItem
              item={item}/>}
          />
        </>
      )}
    </>
  );
};

const PublicacionItem = ({item}) => {
  return (
    <List.Item
      key={item.id}
      actions={[
        <Space>
          <Button
            type="text"
            icon={
              <HeartOutlined
                style={{
                  color: item.heartedColaborador ? "red" : undefined,
                  marginRight: 4,
                }}
              />
            }
          >
            {item.hearts}
          </Button>
          <Button
            type="text"
            icon={
              <LikeOutlined
                style={{
                  color: item.likedColaborador ? "blue" : undefined,
                  marginRight: 4,
                }}
              />
            }
          >
            {item.likes}
          </Button>
        </Space>,
      ]}
      style={{
        marginBottom: "16px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        border: "1px solid #f0f0f0",
        background: "white",
      }}
    >
      <PublicacionContenido item={item} />
    </List.Item>
  );
}

const PublicacionContenido = ({ item }) => {
  const [compressedImages, setCompressedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);

  const carouselRef = useRef(null); // Referencia al carrusel

  const compressImage = (src, maxSize = 720, quality = 0.8) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = src;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height = (height * maxSize) / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width = (width * maxSize) / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const compressedDataUrl = canvas.toDataURL("image/jpeg", quality);
        resolve(compressedDataUrl);
      };

      img.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    const processImages = async () => {
      console.log("processImages::", processImages);
      setLoading(true);
      if (item.imagenes && item.imagenes.length > 0) {
        try {
          const processedImages = await Promise.all(
            item.imagenes.map((src) => compressImage(src, 720, 0.8))
          );
          setCompressedImages(processedImages);
        } catch (error) {
          console.error("Error al procesar imágenes:", error);
        }
      }
      setLoading(false);
    };
    processImages();
  }, [item.imagenes]);

  const renderImageGrid = () => {
    const baseStyle = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "4px",
      cursor: "pointer",
    };

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: compressedImages.length > 1 ? "1fr 1fr" : "1fr",
          gap: "8px",
        }}
      >
        {compressedImages.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`imagen-${index}`}
            style={baseStyle}
            onClick={(e) => {
              e.stopPropagation();
              setActiveImageIndex(index);
              setIsCarouselVisible(true);
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <img
          src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
          alt="Logotipo de la empresa"
          style={{ height: "32px", marginRight: "16px" }}
        />
        <div style={{ flex: 1 }}>
          <h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
          <p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
            {moment()
              .locale("es")
              .format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}
          </p>
        </div>
        <Tag color={item.colorEtiqueta}>{item.etiqueta}</Tag>
      </div>

      <div>
        <style>
					{`
          .custom-react-quill .ql-container {
            border: none !important; /* Quitar el borde */
            background-color: transparent; /* Fondo transparente */
          }
          `}
				</style>
      </div>
      <ReactQuill
					value={item.mensaje}//mensajeHTML
					readOnly={true}
					className="custom-react-quill"
					style={{
						backgroundColor: "transparent",
					}}
					theme="bubble"
					modules={{ toolbar: false }}
					placeholder="Escribe algo..."
				/>

      <div style={{ marginTop: "16px" }}>{loading ? <Spin /> : renderImageGrid()}</div>

      {isCarouselVisible && (
        <Modal
          open={isCarouselVisible}
          footer={null}
          onCancel={() => setIsCarouselVisible(false)}
          centered
          width={800}
          closeIcon={<span style={{ fontSize: "20px", fontWeight: "bold" }}>×</span>}
        >
          <div style={{ position: "relative" }}>
            <button
              onClick={() => carouselRef.current.prev()}
              style={{
                position: "absolute",
                left: "-45px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
                background: "rgba(0, 0, 0, 0.6)",
                border: "none",
                color: "white",
                fontSize: "20px",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              ◀
            </button>

            <button
              onClick={() => carouselRef.current.next()}
              style={{
                position: "absolute",
                right: "-45px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
                background: "rgba(0, 0, 0, 0.6)",
                border: "none",
                color: "white",
                fontSize: "20px",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              ▶
            </button>

            <Carousel
              ref={carouselRef}
              initialSlide={activeImageIndex}
              dots={true}
              infinite={true}
              swipeToSlide={true}
            >
              {compressedImages.map((img, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <img
                    src={img}
                    alt={`carousel-img-${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "contain",
                      margin: "0 auto",
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default FormCrearPublicacion;
