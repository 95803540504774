import React from "react";
//import { Container } from "components/Container";
import { TablaEvaluaciones } from "./componentes/tabla-evaluaciones";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { BotonesExtraVisorPdf, FiltroConsultaSolicitudes, ListaConsultaSolicitudes, ModalAprobarEvaluacion } from "./componentes";
import { Tabs } from "antd";
import { TablaPlanAccion } from "./componentes/tabla-plan-accion";

const SeguimientoEvaluacionPage = () => {
  return (
    <ContainerTabs>
      <Tabs destroyInactiveTabPane={true}>
        <Tabs.TabPane tab="Seguimiento Evaluación" key="solicitudes-pendientes-aprobacion">
          <TablaEvaluaciones />
          <ModalVisorPdf botonesExtra={<BotonesExtraVisorPdf />} />
          <ModalAprobarEvaluacion />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Consultar Evaluaciones" key="consultar-evaluaciones">
          <FiltroConsultaSolicitudes />
          <ListaConsultaSolicitudes />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Planes de Acción" key="planes-accion">
          <TablaPlanAccion />
        </Tabs.TabPane>
      </Tabs>
    </ContainerTabs>
  );
};

const ContainerTabs = ({ children }) => {
  return <div style={{ padding: "10px 20px", background: "white" }}>{children}</div>;
};

export default SeguimientoEvaluacionPage;
