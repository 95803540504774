import { useAtom } from "jotai";
import { codigoAtom, errorMessageAtom, loadingAtom, openModalDobleFactorAutenticacionAtom, usuarioIdAtom } from "../store/store";
import { LoginService } from "services/LoginService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { useHistory } from "react-router-dom";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

const useModalDobleFactorAutenticacion = () => {

    const { setSesionUsuario } = useContext(SecurityContext);
    const [open, setOpen] = useAtom(openModalDobleFactorAutenticacionAtom);
    const [usuarioId, setUsuarioId] = useAtom(usuarioIdAtom);
    const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom);
    const [codigo, setCodigo] = useAtom(codigoAtom);
    const [loading, setLoading] = useAtom(loadingAtom);
    const history = useHistory();


    const abrirModal = (usuarioId) => {
        setUsuarioId(usuarioId);
        setOpen(true);
    }

    const cerrarModal = () => {
        setOpen(false);
    }

    const confirmar = async () => {

        try {
            setLoading(true);
            const respuesta = await LoginService.verificarCodigo({ usuarioId: usuarioId, codigo: codigo });
            setSesionUsuario(respuesta);
            setLoading(false);

            const sesionUrl = buscadorParametros.get("sesionUrl");
            if (sesionUrl) {
                const sesionId = buscadorParametros.get("sesionId");
                const urlParametros = sesionId ? "?sesionId=" + sesionId : "";
                history.push(sesionUrl + urlParametros);
            } else {
                history.push("/dashboard");
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage(error.response.data.mensaje);
        }


    }

    return {
        open,
        codigo,
        setCodigo,
        errorMessage,
        loading,
        abrirModal,
        cerrarModal,
        confirmar
    };
};

export default useModalDobleFactorAutenticacion;