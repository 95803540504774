import { atom } from 'jotai';

export const visibleModalSeleccionarColaborador = atom(false);
export const colaboradoresSeleccionadosId = atom([]);
export const colaboradoresSeleccionados = atom([]);
export const colaboradorSeleccionadoAtom = atom([]);
export const visibleModalConfirmacionAtom = atom(false);
export const colaboradoresAtom = atom([]);
export const visibleTablaColaboradoresAtom = atom(false);
export const visibleTablaSedesAtom = atom(false);
export const sedesAtom = atom([]);
export const colaboradoresAgregadosAtom = atom([]);
export const colaboradoresAgregadosIdsAtom = atom([]);
export const sedeSelecionadaAtom = atom([]);
export const sedesAgregadasAtom = atom([]);
export const sedesAgregadasIdsAtom = atom([]);
export const respuestaCertificadoExpiradoAtom = atom("");
export const mostrarAlertaAtom = atom(false);
export const componentesAtom = atom([]);