import { useAtom } from "jotai";
import { expositoresAtom, formInstanceAtom, openModalRegistrarAsistenciaAtom, participantesAtom, tiposDocumentoAtom } from "../store/Store";
import ColaboradorService from "services/ColaboradorService";
import { AsistenciaService } from "services/notificacion";
import { message } from "antd";
import TipoDocumentoService from "services/TipoDocumentoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import useAsistencia from "./useAsistencia";

const useModalRegistrarAsistencia = () => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [open, setOpen] = useAtom(openModalRegistrarAsistenciaAtom);
    const [expositores, setExpositores] = useAtom(expositoresAtom);
    const [participantes, setParticipantes] = useAtom(participantesAtom);
    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [tiposDocumento, setTiposDocumento] = useAtom(tiposDocumentoAtom);
    const { fetchAsistencias } = useAsistencia();

    const abrirModal = () => {
        setOpen(true);
    }

    const cerrarModal = () => {
        setOpen(false);
    }

    const registrarAsistencia = () => {
        formInstance.validateFields()
            .then(async (formulario) => {
                formulario.expositorId = formulario.expositor.key;
                formulario.participantesId = formulario.participantes.map((participante) => participante.key);

                await AsistenciaService.regitrar(formulario);
                message.success("Se registró la asistencia correctamente");

                fetchAsistencias();
                cerrarModal();

            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    const fetchTiposDocumento = async () => {
        const tiposDocumento = await TipoDocumentoService.listarPorUsuarioYCategoria(
            getUsuarioAutenticado().empresaSeleccionada.id,
            getUsuarioAutenticado().login,
            CategoriaDocumento.REGISTRO_ASISTENCIA.id
        );
        setTiposDocumento(tiposDocumento.map((tipoDocumento) => ({
            label: tipoDocumento.nombre,
            value: tipoDocumento.id
        })));
    }

    const fetchExpositores = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim()
        });

        return respuesta.colaboradores.map((colaborador) => ({
            label: colaborador.nombreCompleto,
            value: colaborador.id,
        }));

    };

    const fetchParticipantes = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim()
        });

        return respuesta.colaboradores.map((colaborador) => ({
            label: colaborador.nombreCompleto,
            value: colaborador.id,
        }));

    };

    return {
        open,
        expositores,
        participantes,
        setFormInstance,
        tiposDocumento,
        registrarAsistencia,
        setExpositores,
        setParticipantes,
        fetchTiposDocumento,
        fetchExpositores,
        fetchParticipantes,
        abrirModal,
        cerrarModal
    }

}

export default useModalRegistrarAsistencia;