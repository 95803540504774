import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Space,
  notification,
  message,
  Dropdown,
  Table,
  Tooltip,
} from "antd";
import useReconocimientos from "./hooks/useReconocimientos";
import useModalCrearReconocimiento from "./hooks/useModalCrearReconocimiento";
import {
  ModalCrearReconocimiento,
  ModalEditarReconocimiento,
} from "./components";
import {
  useModalEditarReconocimiento,
  useModalEliminarReconocimiento,
} from "./hooks";

const ReconocimientoPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Bandeja de Reconocimientos" botonExtra={<Botones />}>
        <ListaReconocimientos />
        <ModalCrearReconocimiento />
        <ModalEditarReconocimiento />
      </Container>
    </JotaiProvider>
  );
};

const Botones = () => {
  const { setOpen: setOpenModalCrearReconocimiento } =
    useModalCrearReconocimiento();
  return (
    <Button
      key="crear-reconocimiento"
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setOpenModalCrearReconocimiento(true)}
    >
      Crear Reconocimiento
    </Button>
  );
};
const ListaReconocimientos = () => {
  const { loading, reconocimientos, fetchReconocimientos } =
    useReconocimientos();

  useEffect(() => {
    const inicializar = () => {
      // if (open) {
      fetchReconocimientos();
      // setFormInstance(form);
      // }
    };
    inicializar();
  }, []);

  const columnas = [
    {
      title: "Titulo",
      dataIndex: "titulo",
      width: "20%",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      width: "40%",
    },
    {
      title: "Colaborador",
      width: "30%",
      render: (text, fila) => {
        return (
          <div>
            <TrophyOutlined style={{ color: "#fadb14", marginRight: "3px" }} />
            {fila.colaborador.nombreCompleto}
          </div>
        );
      },
    },
    {
      title: "Acciones",
      align: "center",
      width: "10%",
      render: (fila) => <MenuAcciones reconocimiento={fila} />,
    },
  ];

  return (
    <Table
      rowKey={(reconocimientos) => reconocimientos.id}
      dataSource={reconocimientos}
      columns={columnas}
    />
  );
};

const MenuAcciones = ({ reconocimiento }) => {
  const { abrirModal: abrirModalEditar } = useModalEditarReconocimiento();
  const { abrirModal: abrirModalEliminar } = useModalEliminarReconocimiento();

  return (
    <Space>
      <Tooltip title="Editar" placement="bottom">
        <Button
          type="text"
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => abrirModalEditar(reconocimiento.id)}
        />
      </Tooltip>
      <Tooltip title="Eliminar" placement="bottom">
        <Button
          type="text"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => abrirModalEliminar(reconocimiento)}
        />
      </Tooltip>
    </Space>
  );
};

export default ReconocimientoPage;
