import React from "react";
import { Provider as JotaiProvider } from "jotai";
import {
  UnorderedListOutlined
} from "@ant-design/icons";
import { Container } from "../../../../components/Container";
import { ListaContratosTiempoServicioSeguimiento } from "./componentes/ListaContratosTiempoServicioSeguimiento";

const BandejaSeguimientoTiempoServicioPage = () => {
  return (
    <JotaiProvider>
      <Container
        icono={<UnorderedListOutlined />}
        titulo="Tiempo de servicio"
      >
        <ListaContratosTiempoServicioSeguimiento />
      </Container>
    </JotaiProvider>
  );
};
export default BandejaSeguimientoTiempoServicioPage;
