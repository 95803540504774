import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.BASE;

const BandejaSeguimientoAdministracionPersonalService={

    async listarContratos(data) {
        const respuesta = await ApiService(URL_API).get(`/admin/contratos/administracion-personal/listar`,{
            params: {
              ...data,
            },
          });
        return respuesta.data;
    },

    async listarContratosAndFiltro(data){
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/administracion-personal/listar-filtro`,data);
        return respuesta.data;
    },
 
    async descargarReporteSeguimientoExcel(params) {
      const respuesta = await ApiService(URL_API).get("/admin/contratos/administracion-personal/seguimiento/reporte", {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },

        responseType: "blob",
      });
      return respuesta;
    },

    async listarColaboradoresContratoTiempoServicio(data) {
      const respuesta = await ApiService(URL_API).get(`/admin/contratos/colaboradores/tiempoServicio`,{
          params: {
            ...data,
          },
        });
      return respuesta.data;
  },
  async descargarReporteTiempoServicioExcel(params) {
    const respuesta = await ApiService(URL_API).get("/admin/contratos/tiempo-servicio/reporte", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },

      responseType: "blob",
    });
    return respuesta;
  },
}
export {BandejaSeguimientoAdministracionPersonalService};