import React, { useState, useContext, useEffect } from "react";

import { Row, Col, Form, Typography, Input, Button, Alert, message, Modal } from "antd";
import { UserOutlined, LockOutlined, SecurityScanOutlined } from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import { useHistory } from "react-router-dom";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { useModalDobleFactorAutenticacion } from "./hooks";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

export function LoginPage() {
	const [autenticacionFallida, setAutenticacionFallida] = useState(false);
	const [mensajeError, setMensajeError] = useState("");
	const [loading, setLoading] = useState(false);
	const { estaAutenticado, setSesionUsuario } = useContext(SecurityContext);
	const { abrirModal } = useModalDobleFactorAutenticacion();

	const history = useHistory();

	useEffect(() => {
		const cargaInicial = () => {
			const sesionExpirada = localStorage.getItem("sesionExpirada");
			if (sesionExpirada === "si") {
				message.info("Su sesión ha expirado.", 8);
				localStorage.removeItem("sesionExpirada");
			}

			if (estaAutenticado()) {
				history.push("/dashboard");
			}

		}
		cargaInicial();
	}, []);

	async function handleOnFinish(formulario) {
		setAutenticacionFallida(false);
		setLoading(true);

		try {
			const respuesta = await LoginService.autenticar(formulario.usuario.replace(/\s+/g, ""), formulario.clave);

			if (respuesta.estado === "ok" && respuesta.dobleFactorAutenticacion) {
				abrirModal(respuesta.usuarioId);
			} else if (respuesta.estado === "ok" && !respuesta.dobleFactorAutenticacion) {
				setSesionUsuario(respuesta);
				setAutenticacionFallida(false);

				const sesionUrl = buscadorParametros.get("sesionUrl");
				if (sesionUrl) {
					const sesionId = buscadorParametros.get("sesionId");
					const urlParametros = sesionId ? "?sesionId=" + sesionId : "";
					history.push(sesionUrl + urlParametros);
				} else {
					history.push("/dashboard");
				}
			} else if (respuesta.error === "auth-1") {
				setLoading(false);
				setAutenticacionFallida(true);
				setMensajeError("Usuario y/o clave son incorrectos");
			} else if (respuesta.error === "auth-100") {
				setLoading(false);
				setAutenticacionFallida(true);
				setMensajeError("El usuario se encuentra bloqueado");
			}
		} catch (error) {
			if (error.response && error.response.data.mensaje) {
				setAutenticacionFallida(true);
				setMensajeError(error.response.data.mensaje);
			}
			setLoading(false);
		}
	}

	function handleOlvideClave() {
		history.push("/cuenta/actualizar-clave");
	}

	return (
		<>
			<Row className="container-login">
				<Col span={16} className="container-login__imagen" />

				<Col span={8} className="container-login__formulario">
					<div className="login">
						<div className="logotipo">
							<img
								className="imagen-derecha"
								src="https://storage.googleapis.com/milegajodigital.com/admin/publico/logotipo.png"
								alt="Logotipo"
							/>
						</div>

						<div className="texto">
							<Typography.Title level={3}>
								Iniciar Sesión{" "}
								{(window.location.hostname.includes("-cert") || window.location.hostname.includes("localhost")) &&
									"[TEST]"}
							</Typography.Title>
						</div>
						{autenticacionFallida && (
							<Alert
								style={{
									marginBottom: 24,
								}}
								message={mensajeError}
								type="error"
								showIcon
							/>
						)}

						<Form name="frmLogin" className="login-form" onFinish={handleOnFinish}>
							<Form.Item name="usuario" rules={[{ required: true, message: "Por favor, ingresa tu usuario." }]}>
								<Input
									size="large"
									maxLength={70}
									prefix={<UserOutlined className="site-form-item-icon" />}
									placeholder="Ingresa tu usuario"
								/>
							</Form.Item>
							<Form.Item name="clave" rules={[{ required: true, message: "Por favor, ingresa tu clave." }]}>
								<Input.Password
									size="large"
									prefix={<LockOutlined className="site-form-item-icon" />}
									type="password"
									placeholder="Ingresa tu clave"
								/>
							</Form.Item>
							<Form.Item>
								<a
									style={{ float: "right" }}
									// href="#/"
									onClick={handleOlvideClave}
								>
									Olvide mi clave
								</a>
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit" size="large" loading={loading} style={{ width: "100%" }}>
									Iniciar Sesión
								</Button>
							</Form.Item>
						</Form>
					</div>
				</Col>
			</Row>
			<ModalDobleFactorAutenticacion />
		</>
	);
}


const ModalDobleFactorAutenticacion = () => {

	// const [open, setOpen] = useAtom(openModalDobleFactorAutenticacionAtom);
	const { open, errorMessage, codigo, setCodigo, loading, confirmar } = useModalDobleFactorAutenticacion();

	return (
		<Modal
			title="Verificación de dos pasos"
			open={open}
			// onOk={handleOk}
			// onCancel={() => setOpen(false)}
			centered
			maskClosable={false}
			closable={false}
			width='350px'
			footer={null}
		>

			<Row align="center">
				<SecurityScanOutlined style={{ fontSize: "48px", color: '#ffb703' }} />
			</Row>

			<Row align='center'>

				<Typography.Title level={5} style={{ marginTop: "15px" }}>
					Ingresa el código de verificación
				</Typography.Title>

				<Typography.Text>
					Hemos enviado un código de verificación a tu correo electrónico. Por favor, revísalo e ingrésalo para continuar.
				</Typography.Text>

				{errorMessage
					&&
					<Alert
						message={errorMessage}
						type="error"
						style={{ width: '100%', marginTop: "5px", padding: '5px' }}
						showIcon
					/>
				}

				<Input
					placeholder="Ingresa el código"
					size="large"
					style={{ marginTop: "10px" }}
					value={codigo}
					onChange={(e) => setCodigo(e.target.value)}
				/>

				<Button
					type="primary"
					block onClick={confirmar}
					disabled={codigo === undefined || codigo?.length === 0}
					loading={loading}
					style={{ marginTop: "10px" }}
				>
					Confirmar
				</Button>
			</Row>

		</Modal >
	)
}