import { atom } from "jotai";

export const documentosAtom = atom([]);
export const documentoSeleccionadoAtom = atom(undefined);
export const visibleModalVisorPdfAtom = atom(false);
export const visibleModalSubirDocumentoAtom = atom(false);

//form
export const visibleVisorFormularioAtom = atom(false);
export const erroresFormularioAtom = atom({});
export const componentSelectedAtom = atom(undefined);
export const signaturesMetadataAtom = atom([]);
export const zoomVisorFormularioAtom = atom(1.25);
export const formularioSeleccionadoAtom = atom(undefined);

export const signatureModalVisibleAtom = atom(false);

export const urlFirmaHolografaAtom = atom(undefined);
export const urlHuellaDactilarAtom = atom(undefined);
export const urlFotoAtom = atom(undefined);

export const loadingPdfAtom = atom(true);

export const visibilidadComponentesAtom = atom({});
// export const visibleModalDeclaracionAtom = atom(false);
