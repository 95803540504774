import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Space,
  notification,
  Typography,
  Popover,
  Alert
} from "antd";

import { ContainerCrear } from "../../../components/ContainerCrear";
import FormItem from "antd/lib/form/FormItem";
import { CheckOutlined, KeyOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import GestionarClaveService from "../../../services/GestionarClaveService";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { LoginService } from "services/LoginService";

export function CambiarClavePage() {

  const [formularioCambiarClave] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [politicasActivas, setPoliticasActivas] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const subdominio = getUsuarioAutenticado().subdominio;
  const [mensajeError, setMensajeError] = useState("");
  const { Text } = Typography;
  let history = useHistory();
  const { Link } = Typography;

  useEffect(() => {
		async function cargarDatos() {
			const datos = await GestionarClaveService.obtenerPoliticas(LoginService.getUsuarioAutenticado().subdominio);
			setPoliticasActivas(datos);
		}
		cargarDatos();
	}, []);

  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      const newToken = await GestionarClaveService.cambiarClave(
        subdominio,
        getUsuarioAutenticado().login,
        formulario.clave,
        formulario.claveActual,
      );

      if(newToken.includes("[error]")){
        setMensajeError(newToken.replace("[error]", ""));
			  setLoading(false);
      }else {
        LoginService.setJwtToken(newToken);

        notification.success({
          message: "Operación exitosa",
          description: "Se actualizó su clave.",
        });
        setLoading(false);
        history.push("/dashboard");
      }
    } catch (error) {
      console.error(error.response);
      console.error(error.response.data.mensaje);
      notification.error({
        message: "No se puede guardar la clave",
        description: "Error-> " + error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/dashboard");
  }

  const content = (
		<div>
			{politicasActivas
				&& politicasActivas.length > 0
				&& politicasActivas.map((politica, index) => (
					<p key={index}>{politica}</p>
				))}
		</div>
	);

  return (
    <ContainerCrear titulo="Cambiar Clave" colSpan={12} icono={<KeyOutlined style={{ color: "#1B96DC" }} />}>
      {mensajeError && (
				<Alert
					style={{
						marginBottom: 24,
					}}
					message={mensajeError}
					type="error"
					showIcon
				/>
			)}
      <Form
        form={formularioCambiarClave}
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 12,
        }}
        layout="vertical"
        size="default"
        onFinish={handleOnFinish}
      >
        <Row align="center">
          <Col span={20}>
            <FormItem>
              <Text strong>Ingresa tú clave actual y una clave nueva para realizar el cambio de clave.
              </Text>
            </FormItem>
          </Col>
        </Row>
        <Row align="center">
          <Col span={20}>
            <FormItem
              label="Clave Actual"
              name="claveActual"
              rules={[
                {
                  required: true,
                  message: "Ingrese su clave Anterior",
                },
              ]}
            >
              <Input.Password />
            </FormItem>
          </Col>
        </Row>
        <Row align="center">
          <Col span={20}>
            <FormItem
              label="Clave Nueva"
              name="clave"
              rules={[
                { required: true, message: "Ingrese su clave" },
              ]}
            >
              <Input.Password />
            </FormItem>
          </Col>
        </Row>
        <Row align="center">
          <Col span={20}>
            <FormItem
              label="Repetir Clave Nueva"
              name="claveR"
              dependencies={["clave"]}
              hasFeedback
              rules={[
                { required: true, message: "Ingrese nuevamente su clave" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("clave") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Las claves deben ser iguales!");
                  },
                }),
              ]}
            >
              <Input.Password />
            </FormItem>
          </Col>
        </Row>

        <Row align="center">
					<Col span={20}>
						{politicasActivas != null &&
							<div style={{ paddingBottom: "10px" }}>
								<Popover content={content} title="Políticas de la clave">
									<Link>Políticas de la clave</Link>
								</Popover>
							</div>

						}
					</Col>

				</Row>

        <Row justify="center">
          <Space>
            <Col span={20}>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                htmlType="submit"
                loading={loading}
              >
                Guardar
              </Button>
            </Col>
            <Col>
              <Button type="default" onClick={handleOnClickCancelar}>
                Cancelar
              </Button>
            </Col>
          </Space>
        </Row>
      </Form>

    </ContainerCrear>
  );
}
