import { BaseService } from "./BaseService";
import qs from "qs";
import { EliminarDatosPage } from "pages/eliminarDatos";

const EvaluacionService = {
  async insertar(evaluacion) {
    const respuesta = await BaseService.post("/evaluacion/notificar", evaluacion);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await BaseService.get("/evaluacion");
    return respuesta.data;
  },
  async obtenerUrl(evaluacionId) {
    const respuesta = await BaseService.get(`/evaluacion/obtener-url/${evaluacionId}`);
    return respuesta.data;
  },
  async aprobarPorGestionHumana(data) {
    const respuesta = await BaseService.post("/evaluacion/aprobar/gestion-humana", data);
    return respuesta.data;
  },
  async obtenerUltimoContrato(numeroDocumentoIdentidad, tipoDocumentoId) {
    const respuesta = await BaseService.get(`/evaluacion/obtener-ultimo-contrato/${numeroDocumentoIdentidad}/${tipoDocumentoId}`);
    return respuesta.data;
  },
  async obtenerTipoDocumento() {
    const respuesta = await BaseService.get("/evaluacion/obtener-tipoDocumento");
    return respuesta.data;
  },
  async obtenerDatosColaboradorPredefinido(numeroDocumentoIdentidad) {
    const respuesta = await BaseService.get(`/evaluacion/obtener-datosColaboradorPredefinido/${numeroDocumentoIdentidad}`);
    return respuesta.data;
  },
  async listarConFiltro(numeroDocumentoIdentidad, fechaInicio, fechaFin, estadoAprobacionJefe, estadoAprobacionRH) {
    const respuesta = await BaseService.get("/evaluacion/listar-con-filtro", {
      params: {
        numeroDocumentoIdentidad: numeroDocumentoIdentidad,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        estadoAprobacionJefe: estadoAprobacionJefe,
        estadoAprobacionRH: estadoAprobacionRH
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
    );
    return respuesta.data;
  },

  async exportar(numeroDocumentoIdentidad, fechaInicio, fechaFin, estadoAprobacionJefe, estadoAprobacionRH) {
    const respuesta = await BaseService.get("/evaluacion/exportar", {
      params: {
        numeroDocumentoIdentidad: numeroDocumentoIdentidad,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        estadoAprobacionJefe: estadoAprobacionJefe,
        estadoAprobacionRH: estadoAprobacionRH
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
    );
    return respuesta.data;
  },

  async listarPlanesAccion(estado) {
    const respuesta = await BaseService.get("/evaluacion/admin/listar-planes-accion", {
      params: {
        estado: estado,
      }
    });
    return respuesta.data;
  },
  async listarPorcolaborador(numeroDocumentoIdentidad, estadoAprobacion) {
    const respuesta = await BaseService.get("evaluacion/listar-por-colaborador", {
      params: {
        numeroDocumentoIdentidad: numeroDocumentoIdentidad,
        estadoAprobacion: estadoAprobacion,
      }
    });
    return respuesta.data;
  },
  async eliminar(evaluacionId) {
    const respuesta = await BaseService.delete(`evaluacion/${evaluacionId}`);

    return respuesta.data;
  }
};
export default EvaluacionService;
