import React, { useEffect } from 'react';
import { Button, Table } from "antd";
import { useAtom } from 'jotai';
import * as atoms from "../../store/NotificarFormularioStore";

const TablaColaboradoresSeleccionados = () => {

    const [colaboradoresAgregados, setColaboradoresAgregados] = useAtom(atoms.colaboradoresAgregadosAtom);
    const [colaboradoresAgregadosIds, setColaboradoresAgregadosIds] = useAtom(atoms.colaboradoresAgregadosIdsAtom);
    useEffect(() => {


    }, [])
    const columnas = [
        {
            title: 'Documento',
            dataIndex: 'numeroDocumentoIdentidad',
            key: 'numeroDocumentoIdentidad',
            align: 'center',
            width: '25%'
        },
        {
            title: 'Apellidos y Nombres',
            dataIndex: 'nombreCompleto',
            key: 'nombreCompleto',
            width: '55%'
        },
        {
            title: 'Acción',
            dataIndex: 'acciones',
            key: 'acciones',
            align: 'center',
            width: '20%',
            render: (text, fila) => (
                <Button type="link" onClick={() => onClickBtnQuitar(fila.id)}>Quitar</Button>
            )
        },
    ];

    const onClickBtnQuitar = (colaboradorId) => {
        setColaboradoresAgregados(colaboradoresAgregados.filter(item => item.id !== colaboradorId));
        setColaboradoresAgregadosIds(colaboradoresAgregadosIds.filter(item => item !== colaboradorId));

    }

    return (
        <Table
            rowKey={(colaborador) => colaborador.id}
            dataSource={colaboradoresAgregados}
            columns={columnas}
            pagination={false}
            style={{ marginTop: "10px" }}
            scroll={{ y: 240 }} />
    )

};

export default TablaColaboradoresSeleccionados;