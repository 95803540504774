import React, { useContext } from "react";
import { Menu, Dropdown, Avatar, Space } from "antd";
import { UserOutlined, LogoutOutlined, SettingOutlined, DesktopOutlined } from "@ant-design/icons";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { useHistory } from "react-router-dom";

export function MenuPerfil() {
	const { getUsuarioAutenticado, cerrarSesion } = useContext(SecurityContext);
	const history = useHistory();

	// const menu = (
	// 	<Menu
	// 		selectedKeys={[]}
	// 		onClick={onMenuClick}
	// 	>
	// 		<div>{getUsuarioAutenticado().nombreCompleto}</div>
	// 		<Menu.Divider />
	// 		<Menu.Item key="clave" className="texto-menu-perfil">
	// 			<SettingOutlined />
	// 			Cambiar Clave
	// 		</Menu.Item>
	// 		<Menu.Divider />
	// 		<Menu.Item key="logout" className="texto-menu-perfil">
	// 			<LogoutOutlined />
	// 			Cerrar Sesión
	// 		</Menu.Item>
	// 	</Menu>
	// );

	// function onMenuClick(event) {
	// 	const { key } = event;

	// 	if (key === "logout") {
	// 		return cerrarSesion();
	// 	} else if (key === "clave") {
	// 		return cambiarClave();
	// 	}
	// }

	function cambiarClave() {
		history.push("/cuenta/cambiar-clave");
	}

	const items = [
		{ key: 'mis-sesiones', label: 'Mis Sesiones', icon: <DesktopOutlined /> },
		{ type: 'divider' },
		{ key: 'cambiar-clave', label: 'Cambiar Clave', icon: <SettingOutlined /> },
		{ type: 'divider' },
		{ key: 'cerrar-sesion', label: 'Cerrar Sesión', icon: <LogoutOutlined /> },
	];

	const onClick = ({ key }) => {
		switch (key) {
			case 'mis-sesiones':
				history.push("/cuenta/sesiones");
				break;

			case 'cambiar-clave':
				cambiarClave();
				break;

			case 'cerrar-sesion':
				cerrarSesion();
				break;

			default:
				break;
		}

	};

	return (
		<Dropdown menu={{ items, onClick }} placement="bottomRight" arrow>
			<Space>
				<Avatar style={{ backgroundColor: "#1678c1", color: "#fff" }} icon={<UserOutlined />} size="small" />
			</Space>
		</Dropdown>
	);
}
