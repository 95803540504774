import { useAtom } from "jotai";
import { useEffect } from "react";
import { BandejaTareasService } from "services";
import { documentosAtom } from "../store/Store";
import { usePermisos } from "hooks";

const useListarTareas = (cargaInicial = false) => {

  const [documentos, setDocumentos] = useAtom(documentosAtom);

  useEffect(() => {
    if (cargaInicial) {
      fetchTareas();
    }
  }, [cargaInicial]);

  const fetchTareas = async () => {
    const respuesta = await BandejaTareasService.listarTareas();
    setDocumentos(respuesta);
  };

  return {
    documentos,
    fetchTareas
  };
};

export default useListarTareas;
