import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Input, notification, Popover, Segmented, Select, Space, Table, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router';
import { Provider as JotaiProvider, useAtom, useAtomValue, useSetAtom } from "jotai";
import styled from '@emotion/styled';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, DeleteOutlined, FormOutlined, HighlightOutlined, MenuOutlined, UsergroupAddOutlined } from "@ant-design/icons"
import "./css/ConfigurarFlujoFirmaPage.css"
import { useFirmantes, useVisor } from './hooks';
import { TipoControl } from 'enums';
import { Document, Page } from 'react-pdf';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { componentesAtom, componentesSeleccionadosAtom, controlEditableAtom, targetsAtom } from './store/ConfigurarFlujoFirmaStore';
import produce from 'immer';
import Moveable from 'react-moveable';
import Selecto from 'react-selecto';
import { FlujoFirmaService } from 'services/notificacion';
import { useHistory } from 'react-router-dom';
import { CategoriaDocumento } from 'enums/CategoriaDocumento';

const ConfigurarFlujoFirmaPage = () => {
    return (
        <JotaiProvider>
            <DndProvider backend={HTML5Backend}>
                <div style={{ border: "1px solid #ccc", height: "calc(100vh - 180px)", display: "flex" }}>
                    <PanelConfiguracion />
                    <VisorDocumento />
                </div>
                <Botones />
            </DndProvider>
        </JotaiProvider >
    );
};

const PanelConfiguracion = () => {
    return (
        <div style={{ width: "25%", backgroundColor: "white", height: "100%" }}>
            <Tabs
                defaultActiveKey="1"
                tabPosition='left'
                size='small'
                tabBarGutter={0}
                style={{ height: "100%" }}
                items={[
                    {
                        key: 'controles',
                        label: <UsergroupAddOutlined style={{ fontSize: "18px", marginRight: "0px" }} />,
                        children: <ControlesTab />,
                        style: { paddingLeft: "12px", paddingRight: "12px" }
                    },
                    // {
                    //     key: 'propiedades',
                    //     label: <FormOutlined style={{ fontSize: "16px", marginRight: "0px" }} />,
                    //     children: `Content of Tab Pane 2`,
                    // }
                ]}
            />
        </div>
    )
}

const ControlesTab = () => {

    const { firmantes, firmanteSeleccionado, seleccionarFirmante, fetchFirmantes } = useFirmantes();
    const [controlEditable, setControlEditable] = useAtom(controlEditableAtom);
    const [componentesSeleccionados] = useAtom(componentesSeleccionadosAtom);
    const componentes = useAtomValue(componentesAtom);

    useEffect(() => {
        fetchFirmantes();
    }, [])

    // const onClickControlEditable = () => {
    //     console.log("controlEditable===>", controlEditable)
    //     console.log("componentes seleccionados==>", componentesSeleccionados)
    // }

    // const onClickControlQuitarEditable = () => {
    //     console.log("botooon===>", controlEditable)
    //     setControlEditable(undefined);
    // }

    const onClickBtnObtenerComponentes = () => {
        console.log("componentes===>", componentes);
    }

    return (
        <div>
            <div>Campos Generales</div>
            <div style={{ marginTop: "15px" }}>
                <Control
                    icono={<HighlightOutlined />}
                    tipo={TipoControl.ETIQUETA}
                    nombre="Texto"
                    isGenerico={true}
                />
            </div>

            <div style={{ marginTop: "10px" }}>Campos rellenables por</div>
            <Select
                bordered={false}
                style={{
                    width: '100%',
                    borderBottom: "1px solid #ccc"
                }}
                value={firmanteSeleccionado?.id}
                options={firmantes}
                onSelect={seleccionarFirmante}
            />
            <div style={{ marginTop: "15px" }}>
                <Control
                    icono={<HighlightOutlined />}
                    tipo={TipoControl.SIGNATURE}
                    nombre="Firma"
                />
            </div>
            {/* <div>
                <Button onClick={onClickBtnObtenerComponentes}>Obtener Componentes</Button>
            </div> */}
        </div>
    )
}

const Control = ({ icono, tipo, nombre, isGenerico }) => {

    const { firmanteSeleccionado } = useFirmantes();

    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: TipoControl.EFORM_PDF,
            item: { name: nombre, tipo: tipo, isGenerico },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [nombre]
    );

    return (
        <div
            ref={dragRef}
            style={{
                cursor: "move",
                background: isGenerico ? '#889696' : firmanteSeleccionado?.color,
                color: "#fff",
                borderRadius: "0.3em",
                fontSize: ".8em",
                padding: "5px 5px 5px 8px",
                marginTop: "0.2rem",
                opacity: opacity,
                width: 200,
                height: 30
            }}
        >
            {icono} {nombre}
        </div>
    );
};

const VisorDocumento = () => {
    let { flujoFirmaId } = useParams();
    const { urlDocumento, totalPaginas, componentes, fetchComponentes, fetchUrlDocumento, onLoadSuccess } = useVisor();
    const [controlEditable] = useAtom(controlEditableAtom);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchUrlDocumento();
        fetchComponentes();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!loading) {
            FlujoFirmaService.guardarComponentes(flujoFirmaId, { componentes });
        }
    }, [componentes]);

    useEffect(() => {
        if (controlEditable) {
            // console.log("controlEditable", controlEditable);
            controlEditable.ref.current.focus();
        }
    }, [controlEditable]);


    return (
        <div style={{
            flex: "1 1 75%",
            height: "100%", // Asegura que ocupe toda la altura de la ventana
            width: "100%", // Asegura que ocupe toda la anchura disponible
        }}>
            <div
                style={{
                    width: "100%",
                    height: "36px", // Altura del div horizontal
                    backgroundColor: "#f0f0f0", // Fondo para distinguirlo
                    borderBottom: "1px solid #ccc", // Línea inferior para separación
                }}
            >
                <TextToolbar />
                {/* Aquí puedes añadir contenido si lo necesitas */}
                {/* <p style={{ margin: 0, padding: "0 10px", fontSize: "12px" }}>Barra superior</p> */}
            </div>
            <div
                id="container"
                className='container-items'
                style={{
                    position: "relative",
                    width: "100%",
                    height: "calc(100% - 36px)",
                    overflow: "auto",
                    display: "flex",
                    justifyContent: "center",
                }}>

                <div className="visor-formulario-flujo-firma" style={{ height: "100%" }}>
                    <Document file={urlDocumento} onLoadSuccess={onLoadSuccess} loading={null}>
                        {Array.from(new Array(totalPaginas), (_, index) => (
                            <PaginaPdf key={`page_${index + 1}`} numeroPagina={index + 1} />
                        ))}
                    </Document>
                </div>
            </div>
        </div>
    )
}

const TextToolbar = () => {

    const [componentes, setComponentes] = useAtom(componentesAtom);
    const targets = useAtomValue(targetsAtom);
    const [fontSize, setFontSize] = useState('12');
    const [alineacion, setAlineacion] = useState('IZQUIERDA');

    const optionsValoresIniciales = [
        { value: '[COLABORADOR.NOMBRES_Y_APELLIDOS]', label: 'NOMBRES Y APELLIDOS' },
        { value: '[COLABORADOR.NUMERO_DOCUMENTO_IDENTIDAD]', label: 'NRO. DOC. IDENTIDAD' }
    ]
    // const [targets] = useAtom(targetsAtom);

    const onChangeFontSize = (value) => {
        if (targets.length > 0) {
            targets.forEach(target => {

                const controlKey = target.getAttribute("data-id");
                const controlType = target.getAttribute("data-type");

                if (controlType === TipoControl.ETIQUETA) {
                    setComponentes(
                        produce((draft) => {
                            const componenteEncontrado = draft.find((c) => c.key === controlKey);
                            if (componenteEncontrado) {
                                componenteEncontrado.size = value;
                            }
                        })
                    );
                    setFontSize(value);
                }
            });
        }
    }

    const onChangeAlineacion = (value) => {
        if (targets.length > 0) {
            targets.forEach(target => {
                const controlKey = target.getAttribute("data-id");
                const controlType = target.getAttribute("data-type");

                if (controlType === TipoControl.ETIQUETA) {
                    setComponentes(
                        produce((draft) => {
                            const componenteEncontrado = draft.find((c) => c.key === controlKey);
                            if (componenteEncontrado) {
                                componenteEncontrado.alineacionHorizontal = value;
                            }
                        })
                    );
                    setAlineacion(value);
                }
            });
        }
    }

    useEffect(() => {
        if (targets.length > 0) {
            const target = targets.find(target => {
                const controlType = target.getAttribute("data-type");
                return controlType === TipoControl.ETIQUETA;
            });


            const componente = componentes.find(componente => componente.key === target?.getAttribute("data-id"));
            if (componente) {
                setFontSize(componente.size);
                setAlineacion(componente.alineacionHorizontal);
            }

        }
    }, [targets]);

    return (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Select
                // defaultValue={'12'}
                value={fontSize}
                style={{
                    width: 60,
                }}
                bordered={false}
                onChange={onChangeFontSize}
                options={[
                    { value: 6, label: '6' },
                    { value: 7, label: '7' },
                    { value: 8, label: '8' },
                    { value: 9, label: '9' },
                    { value: 10, label: '10' },
                    { value: 11, label: '11' },
                    { value: 12, label: '12' },
                    { value: 14, label: '14' },
                    { value: 16, label: '16' },
                    { value: 18, label: '18' },
                    { value: 20, label: '20' },
                    { value: 24, label: '24' },
                    { value: 30, label: '30' },
                    { value: 36, label: '36' },
                    { value: 48, label: '48' },
                    { value: 60, label: '60' },
                ]}
            />
            <Divider type="vertical" />
            <Segmented
                value={alineacion}
                onChange={onChangeAlineacion}
                options={[
                    { value: 'IZQUIERDA', icon: <AlignLeftOutlined /> },
                    { value: 'CENTRO', icon: <AlignCenterOutlined /> },
                    { value: 'DERECHA', icon: <AlignRightOutlined /> }
                ]}
            />
            <Divider type="vertical" />

            <AgregarVariable />



            {/* <Select
                style={{ width: 200 }}
                showSearch
                allowClear={true}
                options={optionsValoresIniciales}
                onSelect={onSelectValorInicial}
            /> */}
        </div >
    )
}

const AgregarVariable = () => {
    const [open, setOpen] = useState(false);
    const setComponentes = useSetAtom(componentesAtom);
    const targets = useAtomValue(targetsAtom);

    const dataSource = [
        {
            key: '${COLABORADOR_NOMBRES_Y_APELLIDOS}',
            name: 'Nombres y Apellidos',
        },
        {
            key: '${COLABORADOR_DNI}',
            name: 'Número Documento Identidad',
        },
    ];

    const columns = [
        {
            title: 'Variable',
            dataIndex: 'name',
        },
    ];

    const onClick = (record) => {
        const etiqueta = targets.find(
            (target) => target.getAttribute("data-type") === TipoControl.ETIQUETA
        );

        if (etiqueta) {
            setComponentes(
                produce((draft) => {
                    const componenteEncontrado = draft.find((c) => c.key === etiqueta.getAttribute("data-id"));
                    if (componenteEncontrado) {
                        componenteEncontrado.value += record.key;
                    }
                })
            );
            setOpen(false);
        }
    }

    return (
        <Popover
            content={
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    showHeader={false}
                    size='small'
                    onRow={(record) => ({
                        onClick: () => onClick(record),
                        style: { cursor: 'pointer' }
                    })}
                />}
            trigger="click"
            open={open}
            onOpenChange={setOpen}
        >
            <Button type="text">Agregar Variable</Button>
        </Popover>
    )
}

const PaginaPdf = ({ numeroPagina }) => {

    const { totalPaginas, zoom, getKeyComponente } = useVisor();
    const [componentes, setComponentes] = useAtom(componentesAtom);
    const [componentesSeleccionados, setComponentesSeleccionados] = useAtom(componentesSeleccionadosAtom);
    const paginaRef = useRef();
    // const [targets, setTargets] = useState([]);
    const [targets, setTargets] = useAtom(targetsAtom);
    const moveableRef = useRef();
    const selectoRef = useRef();
    const { firmanteSeleccionado } = useFirmantes();
    const [controlEditable, setControlEditable] = useAtom(controlEditableAtom);

    const eliminarComponentes = () => {
        setComponentes(
            produce((draft) => {
                componentesSeleccionados.forEach((id) => {
                    const index = draft.findIndex((c) => c.key === id);
                    if (index !== -1) {
                        draft.splice(index, 1);
                    }
                });
            })
        );
        setComponentesSeleccionados([]);
    }

    const [, dropRef] = useDrop(() => ({
        accept: TipoControl.EFORM_PDF,
        drop: (item, monitor) => {
            const offset = monitor.getSourceClientOffset();
            if (offset && paginaRef.current) {
                const paginPdfBound = paginaRef.current.getBoundingClientRect();

                let relativeLeft = (offset.x - paginPdfBound.left) / zoom;
                let relativeTop = (offset.y - paginPdfBound.top) / zoom;

                relativeLeft = Math.max(relativeLeft, 0);
                relativeTop = Math.max(relativeTop, 0);

                setComponentes(
                    produce((draft) => {
                        const propiedadesAdicionales = {};
                        if (item.tipo === TipoControl.SIGNATURE) {
                            propiedadesAdicionales.signatureType = "IMAGEN_Y_DESCRIPCION";
                            // propiedadesAdicionales.signaturePerson = "PERSONALIZADO";
                        }

                        if (item.tipo === TipoControl.ETIQUETA) {
                            propiedadesAdicionales.size = 14;
                            propiedadesAdicionales.alineacionHorizontal = 'IZQUIERDA';
                            propiedadesAdicionales.value = "";
                        }

                        if (!item.isGenerico) {
                            propiedadesAdicionales.colaboradorId = firmanteSeleccionado.id;
                        }

                        draft.push({
                            key: getKeyComponente(item.tipo),
                            label: getKeyComponente(item.tipo),
                            signaturePerson: item.isGenerico ? "GENERICO" : "PERSONALIZADO",
                            type: item.tipo,
                            required: true,
                            overlay: {
                                page: numeroPagina,
                                left: relativeLeft,
                                top: relativeTop,
                                width: 200,
                                height: 30,
                            },
                            color: item.isGenerico ? '#889696' : firmanteSeleccionado.color,
                            ...propiedadesAdicionales
                        });
                    })
                );

            }

        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }), [componentes, firmanteSeleccionado]);

    const SeparadorPaginas = () => {
        return (
            <div style={{ width: "100%", position: "relative" }}>
                <div style={{ height: "2em", backgroundColor: "#f4f4f4" }}></div>
            </div>
        );
    };

    useEffect(() => {
        // console.log("cada cuanto cambio esto===>", targets);

        // targets.forEach(el => {
        //     console.log("id componente seleccioando =>", el.getAttribute('data-id'));
        // });

        if (targets.length === 0) {
            setControlEditable(undefined);
        }

    }, [targets]);

    return (
        <React.Fragment>
            <SeparadorPaginas />
            <div
                ref={(elem) => {
                    paginaRef.current = elem;
                    dropRef(elem);
                }}
                style={{
                    position: "relative",
                    pointerEvents: "auto",
                    backgroundColor: "white",
                }}
                className={`container-visor${numeroPagina}`}
            >
                <Page pageNumber={numeroPagina} loading={null} scale={zoom} />

                <Moveable
                    ref={moveableRef}
                    target={targets}
                    ables={[Editable]}
                    props={{
                        editable: true,
                        eliminarComponentes: eliminarComponentes
                    }}
                    draggable={true}
                    throttleDrag={1}
                    edgeDraggable={false}
                    startDragRotate={0}
                    throttleDragRotate={0}
                    resizable={true}
                    keepRatio={false}
                    snappable={true}
                    bounds={{ "left": 0, "top": 0, "right": 0, "bottom": 0, "position": "css" }}
                    edge={[]}
                    onClickGroup={e => {
                        selectoRef.current.clickTarget(e.inputEvent, e.inputTarget);
                    }}
                    onDrag={e => {
                        const [x, y] = e.beforeTranslate;
                        e.target.style.transform = `translate(${x}px, ${y}px)`;
                    }}
                    onDragGroup={e => {
                        e.events.forEach(ev => {
                            const [x, y] = ev.beforeTranslate;
                            ev.target.style.transform = `translate(${x}px, ${y}px)`;
                        });
                    }}
                    onDragEnd={({ target, isDrag, clientX, clientY }) => {
                        const key = target.getAttribute('data-id');

                        const matrix = new DOMMatrix(target.style.transform);

                        // Obtener las posiciones x e y
                        const x = matrix.m41; // Posición horizontal
                        const y = matrix.m42; // Posición vertical

                        setComponentes(
                            produce((draft) => {
                                const componenteEncontrado = draft.find((c) => c.key === key);
                                if (componenteEncontrado) {
                                    componenteEncontrado.overlay.left = x;
                                    componenteEncontrado.overlay.top = y;
                                }
                            })
                        );

                    }}
                    onResize={e => {
                        e.target.style.width = `${e.width}px`;
                        e.target.style.height = `${e.height}px`;
                        e.target.style.transform = e.drag.transform;
                    }}
                    onResizeEnd={({ target }) => {
                        const key = target.getAttribute('data-id'); // Obtener el atributo data-id
                        const width = parseFloat(target.style.width);  // Obtener el ancho desde el atributo style
                        const height = parseFloat(target.style.height); // Obtener el alto desde el atributo style

                        setComponentes(
                            produce((draft) => {
                                const componenteEncontrado = draft.find((c) => c.key === key);
                                if (componenteEncontrado) {
                                    componenteEncontrado.overlay.width = width;
                                    componenteEncontrado.overlay.height = height;
                                }
                            })
                        );

                    }}
                />

                <Selecto
                    ref={selectoRef}
                    dragContainer={`.container-visor${numeroPagina}`}
                    selectableTargets={[".area-seleccionable .componente"]}
                    hitRate={0}
                    selectByClick={true}
                    selectFromInside={false}
                    toggleContinueSelect={["shift"]}
                    ratio={0}
                    onDragStart={e => {
                        const moveable = moveableRef.current;
                        const target = e.inputEvent.target;

                        // console.log("double click==>", e.originalEvent.detail);

                        const controlKey = target.getAttribute('data-id');
                        if (target.tagName === "INPUT" && controlKey === controlEditable?.key) {
                            e.stop();
                            // e.inputEvent.target.focus();
                            target.focus({
                                cursor: 'start',
                            });

                            // setTimeout(() => {
                            //     e.inputEvent.target.focus();
                            // }, 2);

                            return;
                        }

                        if (moveable.isMoveableElement(target)
                            || targets.some(t => t === target || t.contains(target))) {
                            e.stop();
                        }
                    }}
                    onSelectEnd={e => {
                        const moveable = moveableRef.current;
                        if (e.isDragStart) {
                            e.inputEvent.preventDefault();

                            moveable.waitToChangeTarget().then(() => {
                                moveable.dragStart(e.inputEvent);
                            });
                        }
                        setTargets(e.selected);
                    }}
                    onSelect={e => {
                        if (e.added && e.added.length > 0) {
                            let ids = [];
                            e.added.forEach(el => {
                                ids.push(el.getAttribute('data-id'));
                                setComponentesSeleccionados(ids);
                            });
                        }

                        // if (e.selected.length === 0) {
                        //     setControlEditable(undefined);
                        // }

                    }}
                >

                </Selecto>


                <div
                    className='componentes area-seleccionable'
                    style={{
                        position: "absolute", // Absoluto para ajustarse al contenedor
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        pointerEvents: "auto", // Deshabilita la interacción con el clic
                        zoom: zoom,
                    }}>
                    {componentes?.length > 0 &&
                        componentes
                            .filter((componente) => componente.overlay.page === numeroPagina)
                            .map((componente, index) => {
                                return <Componente key={componente.key} componente={componente} />;
                            })}
                </div>
            </div>
            {numeroPagina === totalPaginas
                && <SeparadorPaginas />}
        </React.Fragment>
    );
};

const Editable = {
    name: "editable",
    props: [],
    events: [],
    render(moveable, React) {
        const rect = moveable.getRect();
        const { pos2 } = moveable.state;
        const { eliminarComponentes } = moveable.props;

        const EditableViewer = moveable.useCSS("div", `
            {
                position: absolute;
                left: 0px;
                top: 0px;
                will-change: transform;
                transform-origin: 0px 0px;
            }
        `);

        return (
            <EditableViewer
                key={"editable-viewer"}
                className={"moveable-editable"}
                style={{
                    transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(10px)`,
                }}
            >
                <Tooltip title="Eliminar Control">
                    <Button type="primary" danger style={{ padding: "0px 8px" }} onClick={eliminarComponentes}>
                        <DeleteOutlined />
                    </Button>
                </Tooltip>
            </EditableViewer>
        );
    },
};

const Componente = ({ componente }) => {

    const inputRef = useRef();
    const [, setControlEditable] = useAtom(controlEditableAtom);
    const setComponentes = useSetAtom(componentesAtom);

    const onDoubleClick = (e) => {
        setControlEditable({
            key: componente.key,
            ref: inputRef
        });
    };

    const actualizarTexto = (texto) => {
        setComponentes(
            produce((draft) => {
                const componenteEncontrado = draft.find((c) => c.key === componente.key);
                if (componenteEncontrado) {
                    componenteEncontrado.value = texto;
                }
            })
        );
    }

    switch (componente.type) {
        case "etiqueta":
            return (
                <div
                    data-id={componente.key}
                    data-type={componente.type}
                    className="componente"
                    style={{
                        border: `1px solid #f8edeb`,
                        position: "absolute",
                        width: `${componente.overlay.width}px`,
                        height: `${componente.overlay.height}px`,
                        transform: `translate(${componente.overlay.left}px, ${componente.overlay.top}px)`,
                        pointerEvents: "auto",
                        display: "flex",
                        justifyContent: "center",
                        //alignItems: "center",
                        color: "#333",
                        fontSize: `${componente.size}px`,
                        fontWeight: "bold",
                        textAlign: "center",
                        "--color": "#4af"
                    }}>

                    <Input.TextArea
                        data-id={componente.key}
                        data-type={componente.type}
                        placeholder="Escribe aquí"
                        style={{
                            padding: "0px",
                            width: "100%",
                            height: "100%",
                            lineHeight: 1.5,
                            textAlign: getTextAlign(componente.alineacionHorizontal),
                            fontFamily: 'times',
                            border: "none",
                            outline: "none",
                            background: "transparent",
                            fontSize: `${componente.size}px`,
                            overflow: "hidden",
                            resize: "none"
                        }}
                        onDoubleClick={onDoubleClick}
                        onChange={(e) => actualizarTexto(e.target.value)}
                        value={componente.value}
                        ref={inputRef}
                    />
                </div >
            )

        default:
            return (
                <div
                    data-id={componente.key}
                    data-type={componente.type}
                    className="componente"
                    style={{
                        border: `1px solid ${componente.color}`,
                        position: "absolute",
                        width: `${componente.overlay.width}px`,
                        height: `${componente.overlay.height}px`,
                        transform: `translate(${componente.overlay.left}px, ${componente.overlay.top}px)`,
                        pointerEvents: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: hexToRgba(componente.color, 0.15),
                        color: "#333",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textAlign: "center",
                        "--color": "#4af"
                    }}>
                    {getNombre(componente.type)}
                </div >
            )
    }

};

const getNombre = (type) => {
    if (type === TipoControl.SIGNATURE) {
        return "Firma";
    }
    return type;
}

const Botones = () => {

    const history = useHistory();
    let { flujoFirmaId } = useParams();
    const { componentes } = useVisor();
    const { firmantes } = useFirmantes();
    const [loading, setLoading] = useState(false);


    const onClickBtnNotificar = async () => {
        setLoading(true);

        let firmantesTienenFirma = true;
        for (const firmante of firmantes) {
            // Buscar si existe un componente de tipo 'signature' con el colaboradorId igual al value del firmante
            const tieneFirma = componentes.some(
                (componente) => componente.type === 'signature' && componente.colaboradorId === firmante.value
            );

            // Si no tiene firma, retornar el mensaje
            if (!tieneFirma) {
                firmantesTienenFirma = false;
                setLoading(false);
                notification.error({
                    message: "Alerta!",
                    description: "Todos los firmantes deben tener su firma"
                })
            }
        }

        if (firmantesTienenFirma) {
            const envioId = await FlujoFirmaService.notificar(flujoFirmaId);
            history.push(`/notificacion/seguimiento/detalle/${CategoriaDocumento.FLUJO_FIRMA.nombre}/${envioId}`);
        }
    }

    return (
        <FooterContainer>
            <Button size='large' type='primary' onClick={onClickBtnNotificar} loading={loading}>
                Notificar
            </Button>
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
    background-color: #fff;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0px 20px
`

const hexToRgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((c) => parseInt(c, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getTextAlign = (value) => {
    switch (value) {
        case 'CENTRO':
            return 'center';
        case 'IZQUIERDA':
            return 'left';
        case 'DERECHA':
            return 'right';
        default:
            return 'left'; // Valor por defecto, si el valor no es válido
    }
}

export default ConfigurarFlujoFirmaPage;