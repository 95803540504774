import { atom } from "jotai";

export const contratosAtom = atom([]);
export const contratoSeleccionadoAtom = atom(undefined);
export const contratosSeguimientoAtom = atom({});
export const loadingContratosSeguimientos = atom(false);
export const filtroColaboradorAtom = atom(undefined);
export const filtroProyectoAtom = atom(null);
export const filtroEstadoColaboradorAtom = atom(null);
export const filtroAtom = atom(undefined);
export const paginaActualAtom = atom(0);
export const proyectosAtom = atom([]);