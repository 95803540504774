import React, { useEffect } from 'react';
import { Breadcrumb, Button, Col, DatePicker, Descriptions, Dropdown, Form, Input, InputNumber, Modal, Row, Select, Steps, Table, Tag, Typography } from 'antd';
import { Provider as JotaiProvider } from "jotai";
import { FileSearchOutlined, HomeOutlined, MoreOutlined, TeamOutlined } from "@ant-design/icons"
import { useModalDetalleRegistroAsistencia, useModalPdf, useModalRegistrarAsistencia, useAsistencia } from './hooks';
import { DebounceSelect } from 'components/DebounceSelect';
import { ModalVisorPdf } from 'components/common';

const NotificarAsistenciaLaredoPage = () => {
    return (
        <JotaiProvider>
            <Navegacion />
            <Botones />
            <ListaAsistencia />
            <ModalRegistrarAsistencia />
            <ModalDetalleRegistroAsistencia />
            <ModalPdf />
        </JotaiProvider>
    );
};

const ListaAsistencia = () => {

    const { asistencias, fetchAsistencias } = useAsistencia();

    useEffect(() => {
        fetchAsistencias();
    }, []);

    const columnas = [
        {
            title: 'Tipo',
            dataIndex: 'tipo',
        },
        {
            title: 'Organizador Por',
            dataIndex: 'organizadoPor',
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
        },
        {
            title: 'Horario',
            dataIndex: 'horario',
        },
        {
            title: 'Horas',
            dataIndex: 'totalHoras',
        },
        {
            title: 'Tema',
            dataIndex: 'tema',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            render: (_, { estado }) => {
                let color = 'red';
                if (estado === 'COMPLETADO') {
                    color = 'green';
                }
                return (
                    <Tag color={color} key={estado}>
                        {estado}
                    </Tag>
                );
            }
        },
        {
            title: 'Acciones',
            width: '5%',
            render: (asistencia) => <MenuAcciones asistencia={asistencia} />
        }
    ];

    return (
        <Table
            rowKey={(asistencia) => asistencia.id}
            columns={columnas}
            dataSource={asistencias}
            size='small'
            style={{ marginTop: "10px" }}
        />
    )
}

const MenuAcciones = ({ asistencia }) => {

    const { abrirModal: abrirModalParticipantes } = useModalDetalleRegistroAsistencia();
    const { abrirModal: abrirModalPdf } = useModalPdf();

    const items = [
        {
            key: 'ver-participantes',
            label: 'Ver Participantes',
            icon: <TeamOutlined />
        }
    ];

    if (asistencia?.estado === "COMPLETADO") {
        items.push({
            key: 'ver-formato',
            label: 'Ver Formato',
            icon: <FileSearchOutlined />
        })
    }

    const onClick = ({ key }) => {
        if (key === "ver-participantes") {
            abrirModalParticipantes(asistencia);
        } else if (key === "ver-formato") {
            abrirModalPdf(asistencia);
        }
    };

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{ fontSize: "18px", fontWeight: "bold", color: "gray" }} />
            </Button>
        </Dropdown>
    );
}

const Botones = () => {
    const { abrirModal } = useModalRegistrarAsistencia();

    return (
        <Row>
            <Button type="primary" onClick={abrirModal}>+ Registrar</Button>
        </Row>
    )
}

const ModalDetalleRegistroAsistencia = () => {
    const {
        open,
        asistenciaSeleccionada,
        participantes,
        expositor,
        notificarExpositor,
        fetchExpositor,
        fetchParticipantes,
        cerrarModal
    } = useModalDetalleRegistroAsistencia();

    useEffect(() => {
        if (open && asistenciaSeleccionada) {
            fetchParticipantes();
            fetchExpositor();
        }
    }, [open])

    const columnas = [
        {
            title: 'N°',
            render: (_, __, indice) => indice + 1,
            width: '10%',
        },
        {
            title: 'DNI',
            dataIndex: 'numeroDocumentoIdentidad',
        },
        {
            title: 'Apellidos y Nombres',
            render: (participante) =>
                <>{participante.apellidoPaterno} {participante.apellidoMaterno} {participante.nombres}</>
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            render: (_, { estado }) => {
                let color = 'red';
                if (estado === 'FIRMADO') {
                    color = 'green';
                }
                return (
                    <Tag color={color} key={estado}>
                        {estado}
                    </Tag>
                );
            }
        },
    ];

    return (
        <Modal
            title="Detalle Registro Asistencia"
            open={open}
            onCancel={cerrarModal}
            footer={null}
            width="50%"
            bodyStyle={{ height: "calc(100vh - 300px)", overflowY: "scroll" }}
        >

            <Typography.Title level={5}>Expositor</Typography.Title>
            <Descriptions column={4} layout="vertical" size='small' bordered style={{ marginBottom: "15px" }}>
                <Descriptions.Item label="DNI">
                    {expositor?.numeroDocumentoIdentidad}
                </Descriptions.Item>
                <Descriptions.Item label="Apellidos y Nombres">
                    {expositor?.apellidoPaterno} {expositor?.apellidoMaterno} {expositor?.nombres}
                </Descriptions.Item>
                <Descriptions.Item label="Estado">
                    {expositor?.estado === "SIN_NOTIFICAR" && <Tag>Notificación Pendiente</Tag>}
                    {expositor?.estado === "PENDIENTE" && <Tag color="warning">Pendiente Firma</Tag>}
                    {expositor?.estado === "FIRMADO" && <Tag color="success">Firmado</Tag>}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Acción">
                    {expositor?.estado !== "FIRMADO"
                        && (
                            <Button type='link' onClick={notificarExpositor}>
                                Notificar
                            </Button>
                        )
                    }
                </Descriptions.Item> */}
            </Descriptions>

            <Typography.Title level={5}>Participantes</Typography.Title>
            <Table
                rowKey={(detalleAsistencia) => detalleAsistencia.numeroDocumentoIdentidad}
                columns={columnas}
                dataSource={participantes}
                size='small'
                pagination={false}
                bordered
            />
        </Modal>
    )
}

const ModalRegistrarAsistencia = () => {

    const [form] = Form.useForm();

    const {
        open,
        expositores,
        participantes,
        tiposDocumento,
        setFormInstance,
        registrarAsistencia,
        fetchTiposDocumento,
        fetchParticipantes,
        fetchExpositores,
        setParticipantes,
        setExpositores,
        cerrarModal
    } = useModalRegistrarAsistencia();

    useEffect(() => {
        if (open) {
            setFormInstance(form);
            fetchTiposDocumento();
        }
    }, [open]);

    return (
        <Modal
            title="Registrar Asistencia"
            open={open}
            onOk={registrarAsistencia}
            onCancel={cerrarModal}
            okText="Registrar Asistencia"
            bodyStyle={{ height: "calc(100vh - 300px)", overflowY: "scroll" }}
        >
            <Form
                layout='vertical'
                form={form}
                autoComplete="off"
            >
                <Form.Item
                    label="Documento"
                    name="tipoDocumentoId"
                    rules={[{ required: true, message: 'Documento es obligatorio!' }]}
                >
                    <Select
                        options={tiposDocumento}
                    />
                </Form.Item>

                <Form.Item
                    label="Tipo"
                    name="tipo"
                    rules={[
                        {
                            required: true,
                            message: 'Tipo asistencia es obligatorio.',
                        },
                    ]}
                >
                    <Select
                        options={[
                            { value: 'Capacitación', label: 'Capacitación' },
                            { value: 'Charla', label: 'Charla' },
                            { value: 'Conferencia', label: 'Conferencia' },
                            { value: 'Curso', label: 'Curso' },
                            { value: 'Reunión de Trabajo', label: 'Reunión de Trabajo' },
                            { value: 'Taller', label: 'Taller' },
                            { value: 'Visita', label: 'Visita' },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label="Organizado Por"
                    name="organizadoPor"
                    rules={[
                        {
                            required: true,
                            message: 'Campo obligatorio!',
                        },
                    ]}
                >
                    <Select
                        options={[
                            { value: 'Gerencia General', label: 'Gerencia General' },
                            { value: 'Gerencia Administrativa', label: 'Gerencia Administrativa' },
                            { value: 'Gerencia Fábrica', label: 'Gerencia Fábrica' },
                            { value: 'Gerencia Campo', label: 'Gerencia Campo' },
                            { value: 'Seguridad, Salud Ocupacional', label: 'Seguridad, Salud Ocupacional' },
                            { value: 'Gerencia RRHH', label: 'Gerencia RRHH' },
                            { value: 'Calidad y/o Medio Ambiente', label: 'Calidad y/o Medio Ambiente' },
                        ]}
                    />
                </Form.Item>

                <Row gutter={16}>
                    <Col span="8">
                        <Form.Item
                            label="Fecha"
                            name="fecha"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es obligatorio!',
                                },
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span="8">
                        <Form.Item
                            label="Horario"
                            name="horario"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es obligatorio!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span="8">
                        <Form.Item
                            label="Total Horas"
                            name="totalHoras"
                            rules={[{ required: true, message: 'Este campo es obligatorio!' }]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="Tema"
                    name="tema"
                    rules={[
                        {
                            required: true,
                            message: 'Este campo es obligatorio!',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label="Objetivo"
                    name="objetivo"
                    rules={[
                        {
                            required: true,
                            message: 'Este campo es obligatorio!',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label="Lugar de Capacitación"
                    name="lugar"
                    rules={[
                        {
                            required: true,
                            message: 'Este campo es obligatorio!',
                        },
                    ]}
                >
                    <Select
                        options={[
                            { value: 'RR. HH.', label: 'RR. HH.' },
                            { value: 'campo', label: 'CAMPO' },
                            { value: 'FÁBRICA', label: 'FÁBRICA' },
                            { value: 'SISO', label: 'SISO' },
                            { value: 'EXOFICINAS', label: 'EXOFICINAS' },
                            { value: 'OTROS', label: 'OTROS' }

                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label="Expositor"
                    name="expositor"
                    rules={[
                        {
                            required: true,
                            message: "Debe seleccionar un expositor.",
                        },
                    ]}
                >
                    <DebounceSelect
                        showSearch
                        allowClear
                        value={expositores}
                        placeholder="Escribe el nombre del colaborador"
                        fetchOptions={fetchExpositores}
                        onChange={(newValue) => {
                            setExpositores(newValue);
                        }}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>


                <Form.Item
                    label="Participantes"
                    name="participantes"
                    rules={[
                        {
                            required: true,
                            message: "Debe seleccionar un expositor.",
                        },
                    ]}
                >
                    <DebounceSelect
                        showSearch
                        allowClear
                        mode="multiple"
                        value={participantes}
                        placeholder="Escribe el nombre del colaborador"
                        fetchOptions={fetchParticipantes}
                        onChange={(newValue) => {
                            setParticipantes(newValue);
                        }}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>

            </Form>
        </Modal>
    )
}

const ModalPdf = () => {

    const { open, asistenciaSeleccionada, urlPdf, fetchUrlPdf, cerrarModal } = useModalPdf();

    useEffect(() => {
        if (open && asistenciaSeleccionada) {
            fetchUrlPdf();
        }
    }, [open, asistenciaSeleccionada]);

    return (
        <ModalVisorPdf
            visible={open}
            urlPdf={urlPdf}
            onCerrar={cerrarModal}
        />
    )
}

const Navegacion = () => {
    return (
        <Breadcrumb style={{ marginBottom: "10px" }}>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Notificación
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Asistencia
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default NotificarAsistenciaLaredoPage;