import React, { useEffect } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { useSesionesPage } from './hooks';
import { Button, Col, List, Popconfirm, Row, Tooltip, Typography } from 'antd';
import { CloseOutlined, DesktopOutlined } from "@ant-design/icons"

const SesionesPage = () => {

    return (
        <JotaiProvider>
            <Row justify='center'>
                <Col span={8}>
                    <Typography.Title level={4}>Sesiones</Typography.Title>
                    <ListaSesiones />
                </Col>
            </Row>
        </JotaiProvider>
    );
};

const ListaSesiones = () => {
    const { sesiones, cerrarSesion, fetchSesiones } = useSesionesPage();

    useEffect(() => {
        fetchSesiones();
    }, []);

    return (
        <List
            itemLayout="horizontal"
            dataSource={sesiones}
            renderItem={(sesion) => (
                <List.Item
                    actions={[
                        <Popconfirm
                            placement="bottomRight"
                            title='Está seguro de cerrar sesión?'
                            onConfirm={() => cerrarSesion(sesion)}
                            okText="Si, Cerrar Sesión"
                            cancelText="No"
                        >
                            <Tooltip title="Cerrar Sesión" placement='right'>
                                <Button danger icon={<CloseOutlined />} shape="circle" />
                            </Tooltip>
                        </Popconfirm>
                    ]}
                    style={{
                        backgroundColor: 'white',
                        paddingLeft: '24px',
                        marginBottom: '12px',
                        borderRadius: '8px'
                    }}>
                    <List.Item.Meta
                        avatar={<DesktopOutlined style={{ fontSize: "32px", color: 'gray' }} />}
                        title={`Navegador ${sesion.navegador.nombre}`}
                        description={`1 sesión en una computadora con ${sesion.sistemaOperativo.nombre}`}
                    // title={`1 sesión en una computadora con ${sesion.sistemaOperativo.nombre}`}
                    // description={`Navegador ${sesion.navegador.nombre}`}
                    />
                </List.Item>
            )}
        />
    )

}

export default SesionesPage;