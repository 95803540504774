import { useAtom } from "jotai";
import { asistenciasAtom } from "../store/Store";
import { AsistenciaService } from "services/notificacion";

const useAsistencia = () => {

    const [asistencias, setAsistencias] = useAtom(asistenciasAtom);

    const fetchAsistencias = async () => {
        const asistencias = await AsistenciaService.listar({});
        setAsistencias(asistencias);
    };

    return {
        asistencias,
        fetchAsistencias
    }

}

export default useAsistencia;