import { useEffect } from "react";
import { useAtom } from "jotai";
import SeccionService from "services/SeccionService";
import { proyectosAtom } from "../BandejaSeguimientoTiempoServicioStore";
import { useContext } from "react";
import { SecurityContext } from "../../../../../context/SecurityContextProvider";

const useListarProyectos=()=>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [proyectos, setProyectos] = useAtom(proyectosAtom);
    useEffect(() => {
        const cargarDatos = async () => {
            const respuesta = await SeccionService.listarPorNombreSede(getUsuarioAutenticado().empresaSeleccionada.id, "PROYECTOS");
            const agregarPrincipal = {id: '651e25d758909a28d6141f56', nombre: 'PRINCIPAL'};

            const proyectosActualizados = [...respuesta, agregarPrincipal];

            setProyectos(proyectosActualizados);
        };
        cargarDatos();
    }, []);

    return { proyectos, setProyectos};

}
export default useListarProyectos;
