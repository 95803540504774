import React,{useEffect, useState} from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Upload,
  Form,
} from "antd";
import ImgCrop from "antd-img-crop";
import { Headers, TipoUsuario } from "enums";
import { useAtom } from "jotai";
import { useModalEditarBeneficioExterno } from "../hooks";
import { fileListAtom, loadAtom} from "../store/store";
import { LoginService } from "services/LoginService";

const ModalEditarBeneficioExterno = () => {
  const [form] = Form.useForm();
  const [texto, setTexto] = useState("");
  const [fileList, setFileList] = useAtom(fileListAtom);
  const [load, setLoad] = useAtom(loadAtom);
  const { open, setOpen, cargarDatosIniciales, actualizarBeneficioExterno } = useModalEditarBeneficioExterno();

  useEffect(() => {
      cargarDatosIniciales(open, form); 
  }, [open]);

  const datos = {
    uid: texto,
  };

  const props = {
    name: "file",
    multiple: true,
    action:
      process.env.REACT_APP_ENDPOINT +
      "comunicaciones-cultura/beneficioExterno/cargarLogo",
    headers: {
      ["authorization"]: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
      [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN,
    },
    data: datos,
    beforeUpload(file) {
      setTexto(file.uid);
      return file;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Modal
      title="Editar Beneficio Externo"
      open={open}
      onCancel={() => setOpen(false)}
      // modalProps={{
      //   destroyOnClose: true,
      //   okText: "Actualizar Proveedor"
      // }}
      footer={null}
      centered
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onFinish={actualizarBeneficioExterno}>
        <Form.Item name="id" noStyle>
						<Input type="hidden" />
				</Form.Item>
        <Form.Item name="empresaId" noStyle>
						<Input type="hidden" />
				</Form.Item>
        <Form.Item name="usuarioRegistro" noStyle>
						<Input type="hidden" />
				</Form.Item>
        <Form.Item name="estado" noStyle>
						<Input type="hidden" />
				</Form.Item>
          <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese el nombre ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label="Url"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingrese url",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
          <Form.Item
              label="Logo"
              name="logo"
            >
            <ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
                  <Upload {...props} maxCount={1} onChange={onChange} accept={["image/*"]}>
                    <Button block>
                      Subir
                    </Button>
                  </Upload>
            </ImgCrop>
            </Form.Item>
            
          </Col>
         </Row> 
        <Row justify="center" style={{ marginBottom: 0 }}>
          <Space>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={load}
                >
                  Aceptar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button onClick={() => setOpen(false)}> Cancelar</Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};


export default ModalEditarBeneficioExterno;
