import { useAtom } from "jotai";
import { openModalEditarReconocimientoAtom, loadAtom,
    reconocimientoIdSelecionadoAtom, colaboradoresAtom
 } from "../store/store";
import { ReconocimientoService } from "services/comunicacion-cultura/ReconocimientoService";
import useReconocimientos from "./useReconocimientos";
import {  notification } from "antd"
import { LoginService } from "services/LoginService";
import ColaboradorService from "services/ColaboradorService";

const useModalEditarReconocimiento = () => {
    const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);
    const [open, setOpen] = useAtom(openModalEditarReconocimientoAtom);
    const [load, setLoad] = useAtom(loadAtom);
    const [reconocimientoIdSeleccionado, setReconocimientoIdSeleccionado] = useAtom(reconocimientoIdSelecionadoAtom);
    const { fetchReconocimientos } = useReconocimientos();

    const actualizarReconocimiento = async (formulario) => {
        setLoad(true);
        let usuarioModificacion = {
            nombreCompleto:LoginService.getUsuarioAutenticado().nombreCompleto,
            numeroDocumentoIdentidad:LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
        } 

        formulario.usuarioModificacion = usuarioModificacion;
        console.log("actualizarReconocimient<oformulario_::",formulario);
        await ReconocimientoService.actualizar(formulario);
        
        setReconocimientoIdSeleccionado(undefined);
        fetchReconocimientos();

        notification.success({
            message: "Reconocimiento actualizado",
            description: "El reconocimiento ha sido modificado con los cambios proporcionados."
        })
        setLoad(false);
        setOpen(false);
        return true;
    }

    const cargarDatosIniciales = async (isOpen, form) => {
        setOpen(isOpen)
        if (isOpen && reconocimientoIdSeleccionado) {
            const response = await ReconocimientoService.buscarPorId(reconocimientoIdSeleccionado);
            console.log("response:::",response);
            if(response?.colaborador != null){
                var colaboradorEncontrado = [];
				colaboradorEncontrado.push(
					{
                        numeroDocumentoIdentidad: response?.colaborador.numeroDocumentoIdentidad,
                        nombreCompleto:response?.colaborador.nombreCompleto
					}
				);
				setColaboradores(colaboradorEncontrado);
            }
            form.setFieldsValue({ ...response, numeroDocumentoIdentidad:response?.colaborador.numeroDocumentoIdentidad });
        }
    }

    const abrirModal = async (reconocimientoId) => {
        setReconocimientoIdSeleccionado(reconocimientoId);
        setOpen(true);
    }

    const onSearchColaboradores = async (value) => {
        if (value === "") {
          setColaboradores([]);
          return;
        }
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
          filtro: value.trim(),
        });
        setColaboradores(respuesta.colaboradores);
      };

    return {
        open,
        setOpen,
        abrirModal,
        cargarDatosIniciales,
        actualizarReconocimiento,
        colaboradores,
        onSearchColaboradores
    };


}

export default useModalEditarReconocimiento;