import React from "react";
import { LoginService } from "services/LoginService";

export const SecurityContext = React.createContext();

export default function SecurityContextProvider({ children }) {
  function estaAutenticado() {
    if (localStorage.getItem("jwt-token")) {
      return true;
    }
    return false;
  }

  function getEmpresaSeleccionada() {
    return getUsuarioAutenticado().empresaSeleccionada;
  }

  function getOrganizacion() {
    return {
      id: getUsuarioAutenticado().organizacionId,
      notificarPorSms: getUsuarioAutenticado().notificarPorSms
    }
  }

  function setSesionUsuario(datos) {
    localStorage.setItem("jwt-token", datos.token);

    localStorage.setItem(
      "jwt-sesion",
      JSON.stringify({
        id: datos.usuario.id,
        nombreCompleto: datos.usuario.nombreCompleto,
        nombres: datos.usuario.nombres,
        subdominio: datos.usuario.subdominio,
        organizacionId: datos.usuario.organizacionId,
        notificarPorSms: datos.usuario.notificarPorSms,
        empresaSeleccionada: datos.usuario.empresaFavorita,
        empresas: datos.usuario.empresas,
        login: datos.usuario.login,
        correoElectronico: datos.usuario.correoElectronico,
        numeroDocumentoIdentidad: datos.usuario.numeroDocumentoIdentidad,
      })
    );
  }

  async function cerrarSesion() {
    await LoginService.cerrarSesion();
    //TODO: consultar con endpoint para invalidar token
    // localStorage.removeItem("jwt-token");
    // localStorage.removeItem("jwt-sesion");
    // localStorage.removeItem("datosEnvio");
    // window.location.href = "/login";
  }

  function getUsuarioAutenticado() {
    return JSON.parse(localStorage.getItem("jwt-sesion"));
  }

  // function getEmpresas() {
  //   return getUsuarioAutenticado().empresas;
  // }

  function setEmpresaSeleccionada(idEmpresa) {
    let empresa = getUsuarioAutenticado().empresas.find(
      (empresa) => empresa.id === idEmpresa
    );

    if (empresa === undefined) {
      empresa = getUsuarioAutenticado().empresas[0];
    }

    localStorage.setItem(
      "jwt-sesion",
      JSON.stringify({
        ...getUsuarioAutenticado(),
        empresaSeleccionada: empresa,
      })
    );
  }

  // function getOpciones() {
  //   return getUsuarioAutenticado().empresaSeleccionada.opciones;
  // }

  //function setDatosEnvio(id, nombre, descripcion,tipoDocumentoId,aprobacion,formatoCargaId,sedeNombre) {
  function setDatosEnvio(id, nombre, descripcion, tipoDocumentoId, flujo, formatoCargaId, sedeNombre, listaTipoDocumentos) {
    //localStorage.setItem("documentoLaboralNombre", documentoLaboralNombre);
    //localStorage.setItem("documentoLaboralDescripcion", documentoLaboralDescripcion);

    localStorage.setItem(
      "datosEnvio",
      JSON.stringify({
        id: id,
        documentoNombre: nombre,
        descripcion: descripcion,
        documentoId: tipoDocumentoId,
        //aprobacion:aprobacion,
        flujo: flujo,
        formatoCargaId: formatoCargaId,
        sedeNombre: sedeNombre,
        listaTipoDocumentos: listaTipoDocumentos
      })
    );
  }
  function setDatosEnvioCargarDocumento(id, tipoDocumentoId, nombre, aprobacion, descripcion, formatoCargaId) {
    //localStorage.setItem("documentoLaboralNombre", documentoLaboralNombre);
    //localStorage.setItem("documentoLaboralDescripcion", documentoLaboralDescripcion);

    localStorage.setItem(
      "datosEnvioCargarDocumento",
      JSON.stringify({
        id: id,
        documentoId: tipoDocumentoId,
        documentoNombre: nombre,
        aprobacion: aprobacion,
        descripcion: descripcion,
        formatoCargaId: formatoCargaId,
      })
    );
  }

  function getDatosEnvio() {
    return JSON.parse(localStorage.getItem("datosEnvio"));
  }
  function getDatosEnvioCargarDocumento() {
    return JSON.parse(localStorage.getItem("datosEnvioCargarDocumento"));
  }

  function setDatosTipoDocumento(id, nombre, descripcion, aprobacion) {
    localStorage.setItem("datosTipoDocumento", JSON.stringify({
      id: id,
      nombre: nombre,
      descripcion: descripcion,
      aprobacion: aprobacion,
    }));
  }
  function getDatosTipoDocumento() {
    return JSON.parse(localStorage.getItem("datosTipoDocumento"));
  }

  return (
    <SecurityContext.Provider
      value={{
        estaAutenticado,
        setSesionUsuario,
        cerrarSesion,
        getUsuarioAutenticado,
        getEmpresaSeleccionada,
        getOrganizacion,
        // getEmpresas,
        setEmpresaSeleccionada,
        // getOpciones,
        setDatosEnvio,
        getDatosEnvio,
        setDatosTipoDocumento,
        getDatosTipoDocumento,
        setDatosEnvioCargarDocumento,
        getDatosEnvioCargarDocumento
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
}
