import React from 'react';
import { Provider as JotaiProvider } from "jotai";
import { Breadcrumb, Button, Card, Row } from 'antd';

const FiltroListarPage = () => {
    return (
        <JotaiProvider>
            <Ruta />
            <Card style={{ borderRadius: "8px" }}>
                <Botones />
                <ListaFiltros />
            </Card>
        </JotaiProvider>
    );
};

const Botones = () => {
    return (
        <Row justify="end">
            <Button type='primary'>+ Crear Filtro</Button>
        </Row>
    )
}

const ListaFiltros = () => {
    return "Tabla Filtros"
}

const Ruta = () => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>Seguridad</Breadcrumb.Item>
            <Breadcrumb.Item>Filtros</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default FiltroListarPage;