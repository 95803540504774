import React from "react";
import { BeneficiosExternosService } from "services/comunicacion-cultura/BeneficiosExternosService";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import useBeneficiosExternos from "./useBeneficiosExternos";

const { confirm } = Modal;

const useModalEliminarBeneficioExterno = () => {

    const { fetchBeneficiosExternos } = useBeneficiosExternos();
    //const notificacion = useNotificacion((state) => state.notificacion);

    const abrirModal = (beneficioExterno) => {
        confirm({
            title: `Desea eliminar el siguiente beneficio externo?`,
            icon: <ExclamationCircleFilled />,
            content: `${beneficioExterno.nombre}`,
            okText: 'Sí, Eliminar!',
            okType: 'danger',
            onOk() {
                return BeneficiosExternosService.eliminar(beneficioExterno.id)
                    .then(() => {
                        fetchBeneficiosExternos();

                        notification.success({
                            message: "Beneficio Externo eliminado",
                            description: "La acción se realizó exitosamente."
                        })
                    })
                    .catch((error) => {
                        console.error('Error al eliminar el beneficio externo:', error);
                        notification.error({
                            title: 'Error',
                            content: 'No se pudo eliminar el beneficio externo. Por favor, intente de nuevo.',
                        });
                    });
            },
        });
    }

    return {
        abrirModal
    };


}

export default useModalEliminarBeneficioExterno;