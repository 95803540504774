import React, { useEffect, useRef } from "react";
import { Table, Button, Modal, Tag, Popover, Spin, Row, Space, Input, Col, Card, Statistic, Descriptions, Tooltip } from "antd";
import {
	MessageOutlined,
	FilePdfOutlined,
	CheckOutlined,
	CloseOutlined,
	SyncOutlined,
	SearchOutlined,
	ArrowUpOutlined,
	ArrowDownOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useListarOnboardingIngreso } from "../hooks";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

import { EstadoVistaColaborador } from "enums/EstadoVistaColaborador";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";
import { useState } from "react";
import MenuAcciones from "./MenuAcciones";
import { useAtom, useSetAtom } from "jotai";
import {
	colaboradorAtom,
	documentoSeleccionadoAtom,
	envioSeleccionadoAtom,
	enviosAtom,
	eventoAtom,
	permisosDocumentosAtom,
	opcionesAtom,
	urlPdfAtom,
	visibleModalVisorPdfAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import UsuarioService from "services/UsuarioService";
import { AccionDocumento, EstadoAprobacionDocumento } from "enums";
import { usePermisos } from "hooks";

const tagStyle = {
	borderRadius: "15px",
};

const TabOnboarding = () => {

	const [, setPermisosDocumentos] = useAtom(permisosDocumentosAtom);
	const [, setOpciones] = useAtom(opcionesAtom);
	// const { opciones, fetchOpciones, existeOpcion } = usePermisos();

	useEffect(() => {
		const fetchData = async () => {
			const permisosDocumentos = await UsuarioService.listarPermisosDocumentos();
			console.log("permisosDocumentos===>", permisosDocumentos)
			setPermisosDocumentos(permisosDocumentos);

			const opciones = await UsuarioService.listarOpciones();
			setOpciones(opciones);

		}
		fetchData();
	}, []);

	return (
		<>
			<Estadisticas />
			<ListaEnviosOnboarding />
		</>
	);
};

const Estadisticas = () => {

	const [envios] = useAtom(enviosAtom);
	const [completados, setCompletados] = useState(0);
	const [pendientes, setPendientes] = useState(0);
	const [pendientesAprobacion, setPendientesAprobacion] = useState(0);

	useEffect(() => {
		if (envios && envios.length > 0) {

			let completados = 0;
			let pendientes = 0;
			let pendientesAprobacion = 0;

			for (const envio of envios) {
				completados += envio.resumenOnboarding.completados;
				pendientes += envio.resumenOnboarding.pendientes;
				pendientesAprobacion += envio.resumenOnboarding.pendientesAprobacion;
			}

			setCompletados(completados);
			setPendientes(pendientes);
			setPendientesAprobacion(pendientesAprobacion);

		}
	}, [envios]);

	return (
		<Row gutter={16}>
			<Col span={8}>
				<Card>
					<Statistic
						title="Completados"
						value={completados}
						valueStyle={{
							color: '#3f8600',
						}}
						prefix={<ArrowUpOutlined />}
					/>
				</Card>
			</Col>
			<Col span={8}>
				<Card>
					<Statistic
						title="Pendientes de Completar"
						value={pendientes}
						// precision={2}
						valueStyle={{
							color: '#cf1322',
						}}
						prefix={<ArrowDownOutlined />}
					/>
				</Card>
			</Col>
			<Col span={8}>
				<Card>
					<Statistic
						title="Pendientes de Aprobación"
						value={pendientesAprobacion}
						// precision={2}
						valueStyle={{
							color: '#cf1322',
						}}
						prefix={<ArrowDownOutlined />}
					/>
				</Card>
			</Col>
		</Row>
	)
}

const ListaEnviosOnboarding = () => {

	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [colaborador] = useAtom(colaboradorAtom);
	// const [envios, setEnvios] = useAtom(enviosAtom);
	const { envios, fetchEnvios } = useListarOnboardingIngreso();

	useEffect(() => {
		fetchEnvios();
	}, [])

	const columnas = [
		{
			title: "Proceso",
			dataIndex: "descripcion"
		},
		{
			title: "Periodo",
			dataIndex: "periodo",
			align: "center"
		},
		{
			title: "Completados",
			dataIndex: ["resumenOnboarding", "completados"],
			align: "center",
			width: "15%"
		},
		{
			title: "Pendientes",
			dataIndex: ["resumenOnboarding", "pendientes"],
			align: "center",
			width: "15%"
		},
		{
			title: "Pendientes Aprobación",
			dataIndex: ["resumenOnboarding", "pendientesAprobacion"],
			align: "center",
			width: "15%"
		},
	];

	return (
		<Table
			rowKey="id"
			columns={columnas}
			expandable={{
				expandedRowRender: (record) => <ListaDocumentos envio={record} />,
			}}
			dataSource={envios}
			pagination={false}
			size="middle"
			style={{ marginTop: "15px" }}
		/>
	)

}

const ListaDocumentos = ({ envio }) => {

	const { documentos, fetchDocumentos, fetchEnvios } = useListarOnboardingIngreso();
	const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const setUrlPdf = useSetAtom(urlPdfAtom);
	const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [evento, setEvento] = useAtom(eventoAtom);
	const [permisosDocumentos] = useAtom(permisosDocumentosAtom);

	useEffect(() => {
		fetchDocumentos(envio.id);
	}, [envio]);

	useEffect(() => {
		if (evento && envio.id === evento.envioId) {
			fetchEnvios();
			fetchDocumentos(envio.id);
			setEvento(undefined);
		}
	}, [evento])

	const onClickVerDocumento = async (fila) => {

		const permisoDocumento = permisosDocumentos.find(permiso => permiso.tipoDocumentoId === fila.envio.tipoDocumentoId);

		if (permisoDocumento && !permisoDocumento.acciones.includes(AccionDocumento.VER)) {
			return;
		}

		setDocumentoSeleccionado(fila);
		if (fila?.estadoVistaColaborador !== "FIRMADO" && fila?.estadoVistaColaborador !== "SUBIDO") {
			if (fila.categoriaDocumento.id === CategoriaDocumento.FORMULARIOS.id) {
				const respuesta = await FormularioSeguimientoService.getUrlPlantillaDocumento(
					fila.envio.tipoDocumentoId
				);
				setUrlPdf(respuesta.urlPlantilla);
				setVisibleModalVisorPdf(true);
			} else if (fila.categoriaDocumento.id === CategoriaDocumento.CARGA_DOCUMENTO.id) {
				Modal.error({
					title: "Falta completar",
					content: "El documento no ha sido cargado.",
				});
			}
		} else {
			try {
				const respuesta = await FormularioSeguimientoService.getUrlDocumento(
					getUsuarioAutenticado().empresaSeleccionada.id,
					fila.id
				);
				setUrlPdf(respuesta.urlDocumento);
				setVisibleModalVisorPdf(true);
			} catch (error) {
				console.error(error);
			}
		}
	};

	var adicionarColumnasFiltro = function getColumns(
		dataIndex,
		placeholder,
		propiedad
	) {
		return {
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={searchInput}
						placeholder={
							placeholder !== undefined
								? placeholder
								: `Buscar por ${dataIndex}`
						}
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							Buscar
						</Button>
						<Button
							onClick={() => handleReset(clearFilters)}
							size="small"
							style={{ width: 90 }}
						>
							Limpiar
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			onFilter: (value, record) =>
				propiedad === undefined
					? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
					: record[dataIndex][propiedad]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => searchInput.current.select());
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={
							propiedad === undefined
								? text?.toString()
								: text[propiedad]?.toString()
						}
					/>
				) : propiedad === undefined ? (
					text
				) : (
					text[propiedad]
				),
		};
	};

	function handleSearch(selectedKeys, confirm, dataIndex) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	}

	function handleReset(clearFilters) {
		clearFilters();
		setSearchText("");
	}

	const columnas = [
		{
			title: "Documento",
			width: "50%",
			...adicionarColumnasFiltro("nombreDocumento"),
			render:
				(documento) => (
					<ColumnaDocumento
						documento={documento}
						onClickVerDocumento={onClickVerDocumento} />
				)
		},
		{
			title: "Estado",
			align: "center",
			render: (documento) => <TagEstadoDocumento documento={documento} />
		},
		{
			title: "Aprobación",
			align: "center",
			render: (documento) => <TagEstadoAprobacion documento={documento} />
			// render: (fila) =>
			// 	fila.estadoRegistro !== EstadoRegistro.INACTIVO ? (
			// 		<EstadoAprobacion estado={fila?.estadoAprobacion} />
			// 	) : (
			// 		<Tag color="red" style={tagStyle}>
			// 			ANULADO
			// 		</Tag>
			// 	),
		},
		// {
		// 	title: "Aprobador",
		// 	width: "20%",
		// 	render: (documento) =>
		// 		<>
		// 			{documento.informacionAdicional?.usuarioAprobador?.nombreCompleto}
		// 		</>
		// },
		{
			title: "Acciones",
			align: "center",
			render: (fila) => <MenuAcciones documento={fila} />,
		},
	];

	return (
		<Table
			rowKey={(item) => item.id}
			columns={columnas}
			dataSource={documentos}
			pagination={false}
			size="small"
		/>
	);
};

const ColumnaDocumento = ({ documento, onClickVerDocumento }) => {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<span style={{ paddingRight: "5px" }}>
				<FilePdfOutlined style={{ fontSize: "24px" }} />
			</span>
			<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
				<span style={{ cursor: "pointer" }} onClick={() => onClickVerDocumento(documento)}>
					{
						documento.estadoVistaColaborador !== EstadoVistaColaborador.OMITIDO
						&& <span>{documento.nombreDocumento}</span>
					}

					{
						documento.estadoVistaColaborador === EstadoVistaColaborador.OMITIDO
						&& <span style={{ textDecoration: "line-through" }}>{documento.nombreDocumento}</span>
					}
				</span>

				{documento.interno && (
					<div style={{ alignItems: "center" }}>
						<Tag color="blue" style={{ borderRadius: "8px", fontSize: "10px" }}>
							Documento Interno
						</Tag>
					</div>
				)}
				{documento.declaracion && (
					<div style={{ alignItems: "center" }}>
						<Tooltip title={documento.declaracion}>
							<Tag color="#2db7f5" style={{ borderRadius: "8px", fontSize: "10px" }}>
								Tiene declaración
							</Tag>
						</Tooltip>
					</div>
				)}
			</div>
		</div>
	)
}

const TagEstadoAprobacion = ({ documento }) => {
	// if (documento.estadoRegistro === EstadoRegistro.INACTIVO) {
	// 	return (
	// 		<Tag color="red" style={tagStyle}>
	// 			ANULADO
	// 		</Tag>
	// 	)
	// } else 
	if (documento.estadoAprobacion === null
		|| documento.estadoAprobacion === EstadoAprobacionDocumento.SIN_APROBACION
		|| documento.estadoVistaColaborador === EstadoVistaColaborador.OMITIDO
		|| documento.estadoRegistro === EstadoRegistro.INACTIVO) {
		return (
			<Tag style={tagStyle}>
				NO REQUIERE
			</Tag>
		);
	} else if (documento.estadoAprobacion === EstadoAprobacionDocumento.PENDIENTE) {
		return (
			<Tag color="gold" style={tagStyle}>
				PENDIENTE
			</Tag>
		);
	} else if (documento.estadoAprobacion === EstadoAprobacionDocumento.APROBADO) {
		return (
			<Tag color="green" style={tagStyle}>
				<CheckOutlined /> APROBADO
			</Tag>
		);
	} else if (documento.estadoAprobacion === EstadoAprobacionDocumento.RECHAZADO) {
		return (
			<Tag color="red" style={tagStyle}>
				<CloseOutlined /> RECHAZADO
			</Tag>
		);
	}

	return null;
}

const TagEstadoDocumento = ({ documento }) => {
	const [firmantes, setFirmantes] = useState([]);
	const [loading, setLoading] = useState(false);

	const onOpenChange = async (visible) => {
		if (visible) {
			setLoading(true);
			const response = await OnboardingIngresoColaboradorService.obtenerEstadoDocumento({
				detalleEnvioId: documento.id,
			});
			setFirmantes(response);
			setLoading(false);
		}
	};

	if (documento.estadoRegistro === EstadoRegistro.INACTIVO) {
		return (
			<Popover
				title={null}
				content={
					<Descriptions
						bordered
						column={1}
						size="small"
					>
						<Descriptions.Item label="Motivo">
							{documento.motivoAnulacion}
						</Descriptions.Item>
						<Descriptions.Item label="Usuario">
							{documento.informacionAdicional?.usuarioAnulacion?.nombreCompleto}
						</Descriptions.Item>
					</Descriptions>
				}
				trigger="click"
				placement="bottom"
			>
				<Tag color="red" style={{ ...tagStyle, cursor: "pointer" }}>
					ANULADO
				</Tag>
			</Popover>
		)
	} else if (documento.estadoVistaColaborador === EstadoVistaColaborador.FIRMADO
		&& documento.estadoAprobacion !== "RECHAZADO") {
		return (
			<Tag color="green" className="milegajo-ant-tag" style={tagStyle}>
				COMPLETADO
			</Tag>
		);
	} else if (documento.estadoVistaColaborador === EstadoVistaColaborador.SUBIDO) {
		return (
			<Tag color="green" className="milegajo-ant-tag" style={tagStyle}>
				SUBIDO
			</Tag>
		);
	} else if (documento.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE
		|| documento.estadoAprobacion === "RECHAZADO") {
		return (
			<Popover
				title={null}
				content={loading ? <Spin /> : <TablaFirmantesPendientes firmantes={firmantes} />}
				trigger="click"
				placement="bottom"
				onOpenChange={onOpenChange}
			>
				<Space>
					{documento.requeridoFlujoFirmas && <SyncOutlined style={{ color: "green", fontWeight: "bold" }} />}
					<Tag color="gold" className="milegajo-ant-tag" style={{ cursor: "pointer", ...tagStyle }}>
						PENDIENTE
					</Tag>
				</Space>
			</Popover>
		);
	} else if (documento.estadoVistaColaborador === EstadoVistaColaborador.OMITIDO) {
		return (
			<Popover
				title={null}
				content={
					<Descriptions
						bordered
						column={1}
						size="small"
					>
						<Descriptions.Item label="Motivo">
							{documento.motivoOmision}
						</Descriptions.Item>
						<Descriptions.Item label="Usuario">
							{documento.informacionAdicional?.usuarioOmision?.nombreCompleto}
						</Descriptions.Item>
					</Descriptions>
				}
				trigger="click"
				placement="bottom"
			>
				<Space>
					<Tag className="milegajo-ant-tag" color="red" style={{ cursor: "pointer", ...tagStyle }}>
						OMITIDO
					</Tag>
				</Space>
			</Popover>
		);
	}

	return null;
};

const TablaFirmantesPendientes = ({ firmantes }) => {
	if (firmantes && firmantes.length === 0) {
		return <span style={{ fontSize: "14px", color: "#778da9" }}>Sin Firmantes</span>;
	}

	const columnas = [
		{
			title: "Firmantes",
			render: (fila) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span>{fila.colaborador}</span>
					<span>{fila.numeroDocumentoIdentidad}</span>
				</div>
			),
		},
		{
			title: "Estado",
			align: "center",
			// dataIndex: "estado",
			key: "estado",
			render: (fila) =>
				fila.estado === "COMPLETADO" ? (
					<Tag color="success" style={tagStyle}>
						{fila.estado}
					</Tag>
				) : (
					<Tag color="gold" style={tagStyle}>
						{fila.estado}
					</Tag>
				),
		},
	];

	return (
		<>
			<Table
				rowKey={(fila) => fila.numeroDocumentoIdentidad}
				dataSource={firmantes}
				columns={columnas}
				pagination={false}
				className="onboarding-firmantes"
			/>
		</>
	);
};

const EstadoAprobacion = ({ estado }) => {
	if (estado === null || estado === "SIN_APROBACION") {
		return <Tag style={tagStyle}>NO REQUIERE</Tag>;
	} else if (estado === "APROBADO") {
		return (
			<Tag color="green" style={tagStyle}>
				<CheckOutlined /> APROBADO
			</Tag>
		);
	} else if (estado === "RECHAZADO") {
		return (
			<Tag color="red" style={tagStyle}>
				<CloseOutlined /> RECHAZADO
			</Tag>
		);
	}
	return (
		<Tag color="gold" style={tagStyle}>
			PENDIENTE
		</Tag>
	);
};

export default TabOnboarding;
