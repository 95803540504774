import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.BASE;

const ReconocimientoService = {
  async insertar(reconocimiento) {
    const respuesta = await ApiService(URL_API).post("/comunicaciones-cultura/reconocimiento", reconocimiento);
    return respuesta.data;
  },
  async actualizar(reconocimiento) {
    const respuesta = await ApiService(URL_API).put("/comunicaciones-cultura/reconocimiento", reconocimiento);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/comunicaciones-cultura/reconocimientos");
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await ApiService(URL_API).delete(`/comunicaciones-cultura/reconocimiento/${id}`);
    return respuesta.data;
  },
  async buscarPorId(id) {
		const respuesta = await ApiService(URL_API).get(`/comunicaciones-cultura/reconocimiento/${id}`);
		return respuesta.data;
	},
};

export { ReconocimientoService };
