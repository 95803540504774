import React from "react";
import { Container } from "components/Container"
import { TablaCarpetasLegajos } from "./tabla-carpetas-legajos";
import { Botones } from "./botones"

const ListarCarpetasLegajosPage = () => {
    return (
        <Container titulo="Carpetas Legajos" botonExtra={<Botones />}  >
            <TablaCarpetasLegajos />
        </Container>
    )
}

export default ListarCarpetasLegajosPage;