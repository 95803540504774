import React, { useEffect } from "react";
// import { Container } from "components/Container";
import { Formulario } from "./formulario";
import { ModalSeleccionarColaboradores } from "./modal-seleccionar-colaboradores";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { Breadcrumb, Card, Col, Row } from "antd";
import { HomeOutlined } from "@ant-design/icons"
import styled from "@emotion/styled";

const NotificarFormularioPage = () => {
    return (
        <JotaiProvider>
            <Container>
                <Navegacion />
                <Formulario />
            </Container>
            <ModalSeleccionarColaboradores />
        </JotaiProvider >
    );
};

const Container = styled.div`
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
`

const Navegacion = () => {
    return (
        <Breadcrumb style={{ marginBottom: "10px" }}>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Notificaciones
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Notificar Formulario
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default NotificarFormularioPage;
