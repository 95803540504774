import { useAtom } from "jotai";
import { filtro } from "pages/legajo/consultas/ConsultarLegajoColaboradorStore";
import { useEffect } from "react";
import { VacacionesService } from "services/VacacionesService";
import {
  filtrosSeleccionadosAtom,
  loadingAtom,
  paginaActualAtom,
  tamanioPaginadoAtom,
  totalRegistrosAtom,
  vacacionesAtom,
} from "../ControlVacacionalStorage";

const usePaginacion = () => {
  const [, setLoading] = useAtom(loadingAtom);
  const [vacaciones, setVacaciones] = useAtom(vacacionesAtom);
  const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
  const [tamanioPaginado] = useAtom(tamanioPaginadoAtom);
  const [totalRegistros, setTotalRegistros] = useAtom(totalRegistrosAtom);
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);

  // useEffect(() => {
  //   const cargarDatos = async () => {
  //     // setLoading(true);
  //     const respuesta = await VacacionesService.listarConPaginacion({
  //       colaborador: filtrosSeleccionados["colaborador"],
  //       documentoIdentidad: filtrosSeleccionados["documentoIdentidad"],
  //       codigoColaborador: filtrosSeleccionados["codigoColaborador"],
  //       pagina: paginaActual - 1,
  //       cantidad: tamanioPaginado,
  //     });
  //     setVacaciones(respuesta.data.vacaciones);
  //     setTotalRegistros(respuesta.data.totalRegistros);
  //     setLoading(false);
  //   }

  //   cargarDatos();

  // }, [filtrosSeleccionados, paginaActual]);


  const listarVacaciones = async (filtrosSeleccionados, paginaActual) => {
    setLoading(true);
    const respuesta = await VacacionesService.listarConPaginacion({
      colaborador: filtrosSeleccionados["colaborador"],
      documentoIdentidad: filtrosSeleccionados["documentoIdentidad"],
      codigoColaborador: filtrosSeleccionados["codigoColaborador"],
      pagina: paginaActual - 1,
      cantidad: tamanioPaginado,
    });
    setVacaciones(respuesta.data.vacaciones);
    setTotalRegistros(respuesta.data.totalRegistros);
    setFiltrosSeleccionados(filtrosSeleccionados);
    setLoading(false);
  }

  const onCambiarPaginacion = (page, pageSize) => {
    listarVacaciones(filtrosSeleccionados, page);
    // setPaginaActual(page);
  };

  const onFiltrar = async (filtros) => {
    console.log("porque se llama este método....")
    // setLoading(true);
    // setPaginaActual(1);
    // setFiltrosSeleccionados(filtros);
    listarVacaciones(filtros, 1);
  };

  return {
    vacaciones,
    paginaActual,
    tamanioPaginado,
    totalRegistros,
    filtrosSeleccionados,
    listarVacaciones,
    setTotalRegistros,
    onCambiarPaginacion,
    onFiltrar,
  };
};

export default usePaginacion;
