import React, { useEffect, useState, useContext } from "react";
import { Form, notification, message, Input, Row, Col, Select, Button, Table, Typography, Tag, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { tipoDocumentoIdentidad } from "enums/TipoDocumentoIdentidad";
import { useHistory, useParams } from "react-router-dom";
import { SecurityContext } from "context/SecurityContextProvider";
import UsuarioService from "services/UsuarioService";
import RolService from "services/RolService";
import TipoDocumentoService from "services/TipoDocumentoService";
import CarpetaService from "services/CarpetaService";
import { EmpresaService } from "services/EmpresaService";
import { useAtom } from "jotai";
import {
  empresasAgregadasAtom,
  empresaSeleccionadaAtom,
  empresasEnviadasAtom,
  visibleModalFiltrarAtom,
  visibleModalRolAtom,
} from "../../UsuarioEditarStore";

const { Option, OptGroup } = Select;
const { Text, Title } = Typography;

const FormularioEditar = () => {
  const history = useHistory();
  const { idUsuario } = useParams();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [formularioEditar] = Form.useForm();
  const [empresasAgregadas, setEmpresasAgregadas] = useAtom(empresasAgregadasAtom);
  const [empresasEnviadas, setEmpresasEnviadas] = useAtom(empresasEnviadasAtom);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useAtom(empresaSeleccionadaAtom);
  const [loading, setLoading] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  //const [empresaSeleccionada, setEmpresaSeleccionada] = useState();
  const [roles, setRoles] = useState([]);
  const [rolesSeleccionados, setRolesSeleccionados] = useState([]);
  const [rolesSeleccionadosNombre, setRolesSeleccionadosNombre] = useState([]);
  const [carpetas, setCarpetas] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [, setVisibleModalFiltrar] = useAtom(visibleModalFiltrarAtom);
  const [, setVisibleModalRol] = useAtom(visibleModalRolAtom);

  useEffect(() => {
    async function cargarDatos() {
      const usuario = await UsuarioService.buscarPorId(idUsuario);
      const _tipoDocumento = await TipoDocumentoService.listarPorEmpresaYCategoria(getUsuarioAutenticado().empresaSeleccionada.id, "");
      setTipoDocumento(_tipoDocumento);

      const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
      setCarpetas(_carpetas);

      formularioEditar.setFieldsValue({
        id: usuario.id,
        apellidos: usuario.apellidos,
        nombres: usuario.nombres,
        login: usuario.login,
        tipoDocumentoIdentidad: usuario.tipoDocumentoIdentidad,
        numeroDocumentoIdentidad: usuario.numeroDocumentoIdentidad,
      });

      let rolesRecuperados = [];
      let empresasRecuperadas = [];
      let empresasRecuperadasOrigin = [];
      for (let i = 0; i < usuario.empresas.length; i++) {
        const roles_ = usuario.empresas[i].rolesId;
        rolesRecuperados = [];
        for (let j = 0; j < roles_.length; j++) {
          const rol = await RolService.buscarPorId(roles_[j]);
          rolesRecuperados.push(rol.nombre);
        }
        const carpetasRecuperadas = [];
        if (usuario.empresas[i].filtroCarpeta != null) {
          for (let j = 0; j < usuario.empresas[i].filtroCarpeta.length; j++) {
            carpetasRecuperadas.push(usuario.empresas[i].filtroCarpeta[j].id);
          }
        }

        const empresaRecuperada_ = {
          id: usuario.empresas[i].id,
          razonSocial: usuario.empresas[i].razonSocial,
          roles: rolesRecuperados,
          filtroSede: usuario.empresas[i].filtroSede != null ? usuario.empresas[i].filtroSede : undefined,
          filtroCargo: usuario.empresas[i].filtroCargo != null ? usuario.empresas[i].filtroCargo : undefined,
          filtroUsuarioNotificador:
            usuario.empresas[i].filtroUsuarioNotificador != null ? usuario.empresas[i].filtroUsuarioNotificador : "todos",
          filtroTipoDocumento: usuario.empresas[i].filtroTipoDocumento != null ? usuario.empresas[i].filtroTipoDocumento : undefined,
          filtroCarpeta: usuario.empresas[i].filtroCarpeta != null ? carpetasRecuperadas : undefined,
          filtroPlantillaOnboarding: usuario.empresas[i].filtroPlantillaOnboarding != null ? usuario.empresas[i].filtroPlantillaOnboarding : undefined,
          filtroSubSede: usuario.empresas[i].filtroSubSede != null ? usuario.empresas[i].filtroSubSede : undefined,
          filtroSeccion: usuario.empresas[i].filtroSeccion != null ? usuario.empresas[i].filtroSeccion : undefined,
          filtroTipoPlanilla: usuario.empresas[i].filtroTipoPlanilla != null ? usuario.empresas[i].filtroTipoPlanilla : undefined,
          filtroPerfil: usuario.empresas[i].filtroPerfil != null ? usuario.empresas[i].filtroPerfil : undefined,
          permisosDocumentos: usuario.empresas[i].permisosDocumentos != null ? usuario.empresas[i].permisosDocumentos : undefined,
        };

        const empresaOrigin_ = {
          id: usuario.empresas[i].id,
          razonSocial: usuario.empresas[i].razonSocial,
          rolesId: usuario.empresas[i].rolesId,
          urlImagenLogotipo: usuario.empresas[i].urlImagenLogotipo,
          filtroSede: usuario.empresas[i].filtroSede != null ? usuario.empresas[i].filtroSede : null,
          filtroCargo: usuario.empresas[i].filtroCargo != null ? usuario.empresas[i].filtroCargo : null,
          filtroUsuarioNotificador:
            usuario.empresas[i].filtroUsuarioNotificador != null ? usuario.empresas[i].filtroUsuarioNotificador : "todos",
          filtroTipoDocumento: usuario.empresas[i].filtroTipoDocumento != null ? usuario.empresas[i].filtroTipoDocumento : null,
          filtroCarpeta: usuario.empresas[i].filtroCarpeta != null ? usuario.empresas[i].filtroCarpeta : null,
          filtroPlantillaOnboarding: usuario.empresas[i].filtroPlantillaOnboarding != null ? usuario.empresas[i].filtroPlantillaOnboarding : null,
          filtroSubSede: usuario.empresas[i].filtroSubSede != null ? usuario.empresas[i].filtroSubSede : null,
          filtroSeccion: usuario.empresas[i].filtroSeccion != null ? usuario.empresas[i].filtroSeccion : null,
          filtroTipoPlanilla: usuario.empresas[i].filtroTipoPlanilla != null ? usuario.empresas[i].filtroTipoPlanilla : null,
          filtroPerfil: usuario.empresas[i].filtroPerfil != null ? usuario.empresas[i].filtroPerfil : null,
          permisosDocumentos: usuario.empresas[i].permisosDocumentos != null ? usuario.empresas[i].permisosDocumentos : undefined
        };

        empresasRecuperadasOrigin.push(empresaOrigin_);
        empresasRecuperadas.push(empresaRecuperada_);
      }

      setEmpresasAgregadas(empresasRecuperadas);
      setEmpresasEnviadas(empresasRecuperadasOrigin);

      const empresas = await EmpresaService.listarPorOrganizacionId(getUsuarioAutenticado().organizacionId);
      setEmpresas(empresas);
    }
    cargarDatos();
  }, []);

  const columnasTabla = [
    {
      title: "Empresa",
      dataIndex: "razonSocial",
      key: "razonSocial",
      align: "left",
    },

    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          <Button type="link" size="middle" onClick={() => onClickConfigurarFiltros(fila)}>
            Configurar Filtros
          </Button>
          <Button type="link" size="middle" onClick={() => onClickConfigurarRoles(fila)}>
            Configurar Roles
          </Button>
          <Button type="link" size="middle" onClick={() => handleOnClickQuitar(fila)}>
            Quitar
          </Button>
          {/* <AgregarRolModal /> */}
        </React.Fragment>
      ),
    },
  ];

  const expanded = {
    expandedRowRender: (record, i) => {
      return (
        <React.Fragment>
          {record.roles ? (
            <React.Fragment>
              <Text>{"Roles: "}</Text>
              {record.roles.map((tag) => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))}
            </React.Fragment>
          ) : (
            "No se han asignado Roles"
          )}
        </React.Fragment>
      );
    },
  };

  const onClickConfigurarFiltros = (empresa) => {
    setEmpresaSeleccionada(empresa);
    setVisibleModalFiltrar(true);
  };

  const onClickConfigurarRoles = async (empresa) => {
    setEmpresaSeleccionada(empresa);
    setVisibleModalRol(true);
  };

  const handleOnClickQuitar = (fila) => {
    setEmpresasAgregadas(empresasAgregadas.filter((item) => item.id !== fila.id));
    setEmpresasEnviadas(empresasEnviadas.filter((item) => item.id !== fila.id));
  };

  const handleOnFinish = async (usuario) => {
    try {
      if (empresasAgregadas.length > 0) {
        setLoading(true);
        usuario.empresas = empresasEnviadas;

        usuario.organizacion = {
          id: getUsuarioAutenticado().organizacionId,
          subdominio: getUsuarioAutenticado().subdominio,
        };

        const indexEmpresas = empresasEnviadas.find((item) => item.rolesId == undefined);

        if (indexEmpresas == undefined) {
          await UsuarioService.actualizar(usuario);
          notification.success({
            message: "Operación exitosa",
            description: "Se actualizó al usuario correctamente.",
          });
          setLoading(false);
          history.push("/usuario");
        } else {
          setLoading(false);
          message.error("La(s) empresa(s) seleccionada(s) debe tener al menos 1 rol asignado");
        }
      } else {
        message.error("No hay empresas agregadas para este usuario.");
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "No se puede actualizar el usuario",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  };

  const handleOnClickCancelar = () => {
    history.push("/usuario");
  };

  const handleOnSelectEmpresa = async (string, option) => {
    setEmpresaSeleccionada(option.value);
    const roles = await RolService.listar(option.value, 0);
    setRoles(roles);
    setRolesSeleccionados([]);
    setRolesSeleccionadosNombre([]);
    formularioEditar.setFieldsValue({
      roles: [],
    });
  };

  const handleOnClickAgregar = () => {
    if (empresaSeleccionada !== undefined) {
      const empresa = empresasAgregadas.find((empresa) => empresa.id === empresaSeleccionada);
      if (empresa === undefined) {
        const empresaBuscada = empresas.find((empresa) => empresa.id === empresaSeleccionada);
        const empresaAgregada = {
          id: empresaBuscada.id,
          razonSocial: empresaBuscada.razonSocial,
          filtroSede: null,
          filtroCargo: null,
          filtroUsuarioNotificador: "todos",
          filtroTipoDocumento: null,
          filtroCarpeta: null,
          filtroPlantillaOnboarding: null,
          filtroPerfil: null,
          filtroSubSede: null,
          filtroSeccion: null,
          filtroTipoPlanilla: null,
        };
        const empresaEnviada = {
          id: empresaBuscada.id,
          razonSocial: empresaBuscada.razonSocial,
          urlImagenLogotipo: empresaBuscada.urlImagenLogotipo,
          filtroUsuarioNotificador: "todos",
          rolesId: undefined,
          filtroSede: null,
          filtroCargo: null,
          filtroTipoDocumento: null,
          filtroCarpeta: null,
          filtroPlantillaOnboarding: null,
          filtroPerfil: null,
          filtroSubSede: null,
          filtroSeccion: null,
          filtroTipoPlanilla: null,
        };
        setEmpresasAgregadas([...empresasAgregadas, empresaAgregada]);
        setEmpresasEnviadas([...empresasEnviadas, empresaEnviada]);
        //setEmpresaSeleccionada();
        setRoles([]);
        setRolesSeleccionados([]);
        setRolesSeleccionadosNombre([]);
        formularioEditar.setFieldsValue({
          roles: [],
          empresas: "",
        });
      } else {
        message.error("La empresa ya ha sido agregada");
      }
    } else {
      message.error("No ha seleccionado la empresa ");
    }
  };

  return (
    <Form form={formularioEditar} layout="vertical" size="large" onFinish={handleOnFinish}>
      <Form.Item name="id" label="id" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Tipo Documento Identidad"
            name="tipoDocumentoIdentidad"
            rules={[
              {
                required: true,
                message: "Selecciona tu tipo de documento de identidad",
              },
            ]}
          >
            <Select placeholder="Seleccionar el Tipo Documento Identidad" allowClear>
              <Option value={tipoDocumentoIdentidad.DNI.valor}>{tipoDocumentoIdentidad.DNI.nombre}</Option>
              <Option value={tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
                {tipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
              </Option>
              <Option value={tipoDocumentoIdentidad.PASAPORTE.valor}>{tipoDocumentoIdentidad.PASAPORTE.nombre}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Número de Documento Identidad"
            name="numeroDocumentoIdentidad"
            rules={[
              {
                required: true,
                message: "Escribe el número de tu documento de identidad",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Apellidos"
            name="apellidos"
            rules={[
              {
                required: true,
                message: "Escribe tus apellidos",
              },
            ]}
          >
            <Input placeholder="Escribe aquí los apellidos" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Nombres"
            name="nombres"
            rules={[
              {
                required: true,
                message: "Escribe los nombres completos del usuario",
              },
            ]}
          >
            <Input placeholder="Escribe aquí los nombres completos" autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Login"
            name="login"
            rules={[
              {
                required: true,
                message: "Escribe el login para acceder a tu cuenta",
              },
              {
                type: "email",
                message: "Correo electrónico ingresado no es válido",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Empresas" name="empresas">
            <Select placeholder="Seleccionar la empresa" onSelect={handleOnSelectEmpresa}>
              {empresas.length > 0 &&
                empresas.map((empresa) => {
                  return (
                    <Option key={empresa.id} value={empresa.id}>
                      {empresa.razonSocial}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="">
            <Button size="middle" onClick={handleOnClickAgregar} shape="round">
              Agregar Empresa
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="">
            <Table
              rowKey={(empresa) => empresa.id}
              columns={columnasTabla}
              dataSource={empresasAgregadas}
              expandable={{
                ...expanded,
              }}
              pagination={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Space>
          <Col>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="default" onClick={handleOnClickCancelar}>
                Cancelar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                Actualizar Usuario
              </Button>
            </Form.Item>
          </Col>
        </Space>
      </Row>
      {/* <ModalFiltrar documento={empresaFiltrar} /> */}
    </Form>
  );
};

export default FormularioEditar;
