import { useAtom } from "jotai";
import { openModalCrearBeneficioExternoAtom, loadingCrearBeneficioExternoAtom, fileListAtom } from "../store/store";

import { BeneficiosExternosService } from "services/comunicacion-cultura/BeneficiosExternosService";
import useBeneficiosExternos from "./useBeneficiosExternos";
import { Button, Col, Input, Modal, Row, Space, Form, Typography, notification } from "antd"
import { LoginService } from "services/LoginService";

const useModalCrearBeneficioExterno = () => {
    const [open, setOpen] = useAtom(openModalCrearBeneficioExternoAtom);
    const [loadingCrearBeneficioExterno, setLoadingCrearBeneficioExterno] = useAtom(loadingCrearBeneficioExternoAtom);
    const [fileList, setFileList] = useAtom(fileListAtom);
    const { fetchBeneficiosExternos } = useBeneficiosExternos();

    const crearBeneficioExterno = async (datosFormulario) => {
        setLoadingCrearBeneficioExterno(true);
        let usuarioRegistro = {
            nombreCompleto: LoginService.getUsuarioAutenticado().nombreCompleto,
            numeroDocumentoIdentidad:
              LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
          };
          console.log("fileList[0]", fileList[0]);
        datosFormulario.logo = fileList[0]?.uid;
        datosFormulario.usuarioRegistro = usuarioRegistro;
        
        console.log("datosFormulario", datosFormulario);
        await BeneficiosExternosService.insertar({ ...datosFormulario });
        notification.success({
            message: "Beneficio externo creado",
            description: "El Beneficio externo ahora se encuentra disponible para su uso."
        })
        fetchBeneficiosExternos();
        setOpen(false);
        setLoadingCrearBeneficioExterno(false);
        return true;
    }

    return {
        open,
        setOpen,
        crearBeneficioExterno,
        loadingCrearBeneficioExterno
    };


}

export default useModalCrearBeneficioExterno;