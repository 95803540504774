import React, { useState, useContext, useEffect } from "react";
import { ModalVisorPdf as VisorPdf } from "components/common";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { saveAs } from "file-saver";
import {
    documentoSeleccionadoAtom,
    eventoAtom,
    nombreDocumentoAtom,
    onboardingsIngresoAtom,
    permisosDocumentosAtom,
    urlPdfAtom,
    visibleModalVisorPdfAtom,
} from "../../LegajoColaboradorStore";
import { Button, Popconfirm, Space, notification, Popover, Form, Input } from "antd";
import { documentos } from "pages/legajo/consultas/ConsultarLegajoColaboradorStore";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { SecurityContext } from "context/SecurityContextProvider";
import produce from "immer";
import { Evento } from "../CardDocumentosColaborador/TabOnboarding/Evento";
import { AccionDocumento } from "enums";

const ModalVisorPdf = () => {
    const [visible, setVisible] = useAtom(visibleModalVisorPdfAtom);
    const urlPdf = useAtomValue(urlPdfAtom);
    const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);

    useEffect(() => {
        if (visible) {
            console.log("documentoSeleccionado", documentoSeleccionado);
        }
    }, [visible]);

    const onCerrar = () => {
        setVisible(false);
    };

    return (
        <VisorPdf
            titulo={documentoSeleccionado?.nombreDocumento}
            visible={visible}
            urlPdf={urlPdf}
            botonesExtra={<Botones />}
            onCerrar={onCerrar}
        />
    );
};

const Botones = () => {
    const [, setVisible] = useAtom(visibleModalVisorPdfAtom);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const setDocumentos = useSetAtom(onboardingsIngresoAtom);
    const [loading, setLoading] = useState(false);
    const [formRechazar] = Form.useForm();
    const [openPop, setOPenPop] = useState(false);
    const [loadingDescarga, setLoadingDescarga] = useState(false);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [permisosDocumentos] = useAtom(permisosDocumentosAtom);
    const [, setEvento] = useAtom(eventoAtom);

    const permisoDocumento = permisosDocumentos.find(permiso => permiso.tipoDocumentoId === documentoSeleccionado.envio.tipoDocumentoId);
    console.log("permisDocumento==>", permisoDocumento)
    console.log("mostrar aprobar: ", permisoDocumento &&
        permisoDocumento.acciones.includes(AccionDocumento.APROBAR))


    const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
        ? true
        : false;

    const onClickBtnAprobar = async () => {
        try {
            setLoading(true);
            const respuesta = await OnboardingColaboradorService.aprobar(documentoSeleccionado.id);
            // if (respuesta) {
            //     setDocumentoSeleccionado({ ...documentoSeleccionado, estadoAprobacion: "APROBADO" });
            //     setDocumentos(
            //         produce((draft) => {
            //             const documento = draft.find((documento) => documento.id === documentoSeleccionado.id);
            //             documento.estadoAprobacion = "APROBADO";
            //         })
            //     );
            // }
            setEvento({ envioId: documentoSeleccionado.envio.id, accion: Evento.ACTUALIZAR_LISTA_DOCUMENTOS });
            notification.success({
                message: "Éxito",
                description: "Documento aprobado correctamente.",
                placement: "bottomRight",
            });
            setVisible(false);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onFinishRechazar = async (formulario) => {
        try {
            setLoading(true);
            const respuesta = await OnboardingColaboradorService.rechazar({
                detalleId: documentoSeleccionado.id,
                motivoRechazo:
                    formulario.motivoRechazo !== undefined ? formulario.motivoRechazo.trim() : formulario.motivoRechazo,
            });
            // if (respuesta) {
            //     setDocumentoSeleccionado({ ...documentoSeleccionado, estadoAprobacion: "RECHAZADO" });
            //     setDocumentos(
            //         produce((draft) => {
            //             const documento = draft.find((documento) => documento.id === documentoSeleccionado.id);
            //             documento.estadoAprobacion = "RECHAZADO";
            //         })
            //     );
            // }
            setOPenPop(false);
            setEvento({ envioId: documentoSeleccionado.envio.id, accion: Evento.ACTUALIZAR_LISTA_DOCUMENTOS });
            formRechazar.resetFields();
            notification.success({
                message: "Éxito",
                description: "Documento rechazado.",
                placement: "bottomRight",
            });
            setVisible(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onClickCancelar = () => {
        setOPenPop(false);
        formRechazar.resetFields();
    };

    const handleOpenChange = (newOpen) => {
        console.log("activandooooo", newOpen)
        setOPenPop(newOpen);
        formRechazar.resetFields();
    };

    const handleDescargarPdf = async () => {
        setLoadingDescarga(true);
        try {
            const respuesta = await FormularioSeguimientoService.getDescargarDocumento(documentoSeleccionado.id);
            setLoadingDescarga(false);
            const filename = respuesta.headers.filename;
            console.log("filename", filename);
            saveAs(respuesta.data, filename);
        } catch {
            setLoadingDescarga(false);
            notification.error({
                message: "Error al intentar descargar el documento. No se encontraron resultados."
            })
        }

    };

    const contenidoRechazar = (
        <Form layout="vertical" form={formRechazar} onFinish={onFinishRechazar}>
            <Form.Item label="Indica el motivo del rechazo" name="motivoRechazo">
                <Input.TextArea maxLength={160} style={{ height: 80 }} autoSize={false} />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={onClickCancelar} style={{ borderRadius: "15px", marginRight: "10px" }}>
                    Cancelar
                </Button>
                <Button type="danger" style={{ borderRadius: "15px" }} htmlType="submit">
                    Rechazar
                </Button>
            </div>
        </Form>
    );

    if (
        documentoSeleccionado.estadoVistaColaborador === "FIRMADO" &&
        documentoSeleccionado.requiereAprobacion &&
        documentoSeleccionado.estadoAprobacion === "PENDIENTE"
    ) {
        return (
            <>
                <Space>
                    {isAceros && (
                        <Button onClick={handleDescargarPdf} loading={loadingDescarga}>Descargar</Button>
                    )}

                    {((permisoDocumento &&
                        permisoDocumento.acciones.includes(AccionDocumento.APROBAR))
                        || (permisoDocumento === undefined || permisoDocumento.length === 0))
                        && (
                            <>
                                <Popover
                                    content={contenidoRechazar}
                                    //  title="¿Está seguro de rechazar el documento?"
                                    trigger="click"
                                    open={openPop}
                                    onOpenChange={handleOpenChange}
                                    placement="bottomRight"
                                >
                                    <Button type="danger">Rechazar</Button>
                                </Popover>

                                <Popconfirm
                                    placement="bottomRight"
                                    title="¿Está seguro de aprobar el documento?"
                                    okButtonProps={{
                                        size: "middle",
                                        style: { borderRadius: "15px", padding: "0px 15px" },
                                        loading: loading,
                                    }}
                                    cancelButtonProps={{
                                        size: "middle",
                                        style: { borderRadius: "15px", padding: "0px 15px" },
                                        loading: loading,
                                    }}
                                    onConfirm={onClickBtnAprobar}
                                    okText="Aprobar"
                                    cancelText="Cancelar"
                                >
                                    <Button type="primary">Aprobar</Button>
                                </Popconfirm>
                            </>
                        )}
                </Space >
            </>
        );
    }
    return "";
};

export default ModalVisorPdf;
