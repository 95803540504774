import { atom } from "jotai";

export const openModalRegistrarAsistenciaAtom = atom(false);
export const openModalParticipantesAtom = atom(false);
export const openModalVisorPdfAtom = atom(false);
export const expositoresAtom = atom([]);
export const participantesAtom = atom([]);
export const tiposDocumentoAtom = atom([]);
export const formInstanceAtom = atom(undefined);
export const asistenciasAtom = atom([]);
export const asistenciaSeleccionadaAtom = atom(undefined);
export const expositorAtom = atom(undefined);

export const urlPdfAtom = atom(undefined);