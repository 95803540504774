import React from "react";
import "./App.less";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import SecurityContextProvider from "./context/SecurityContextProvider";
import { LoginPage } from "./pages/login";
import { ActualizarClavePage } from "./pages/cuenta/actualizar-clave";
import { PrivateRoute } from "./components/PrivateRouter";
import { RutasApp } from "./router/RutasApp.js";
import { GenerarClavePage } from "./pages/cuenta/generar-clave";
import { pdfjs } from "react-pdf";
import { Provider as JotaiProvider } from "jotai";
import { ConfigProvider } from "antd";
import "moment/locale/es";
import locale from "antd/lib/locale/es_ES";
import { NotFoundPage } from "pages/not-found";

function App() {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  console.log("pdfjs.version: " + pdfjs.version);
  pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.js";

  return (
    <SecurityContextProvider>
      <JotaiProvider>
        <ConfigProvider locale={locale}>
          <BrowserRouter>
            <CompatRouter>
              <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/cuenta/actualizar-clave" component={ActualizarClavePage} />
                <Route exact path="/generarClave" component={GenerarClavePage} />
                <Route exact path="/not-found" component={NotFoundPage} />
                <PrivateRoute exact component={RutasApp} />
              </Switch>
            </CompatRouter>
          </BrowserRouter>
        </ConfigProvider>
      </JotaiProvider>
    </SecurityContextProvider>
  );
}

export default App;
