import React from "react";
import { Button, Dropdown, Modal, message } from "antd";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import {
    UnorderedListOutlined,
    CaretDownOutlined,
    FilePdfOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    documentoSeleccionadoAtom,
    onboardingsIngresoAtom,
    urlPdfAtom,
    visibleModalVisorPdfAtom,
    visibleModalCargarDocumentoAtom,
    permisosDocumentosAtom,
    opcionesAtom,
    eventoAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import { useAtom, useSetAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { EstadoRegistro } from "enums/EstadoRegistro";
import DetalleEnvioService from "services/DetalleEnvioService";
import { isArguments } from "lodash";
import { Evento } from "./Evento";
import { AccionDocumento } from "enums";

const MenuAcciones = ({ documento }) => {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
    const setUrlPdf = useSetAtom(urlPdfAtom);
    const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
    const [, setOnboardings] = useAtom(onboardingsIngresoAtom);

    const mostrarModal = useModalConfirmar((state) => state.mostrarModal);
    const [, setVisibleModalCargarDocumento] = useAtom(visibleModalCargarDocumentoAtom);
    const [permisosDocumentos] = useAtom(permisosDocumentosAtom);
    const [opciones] = useAtom(opcionesAtom);

    const [, setEvento] = useAtom(eventoAtom);


    const onClick = ({ key }) => {
        switch (key) {
            case "ver-documento":
                onClickVerDocumento();
                break;
            case "anular-documento":
                setDocumentoSeleccionado(documento);
                mostrarModal();
                break;
            case "habilitar-documento":
                onHabilitar(documento.id);
                break;
            case "subir-documento":
                subirDocumento();
                break;
            default:
                break;
        }
    };

    const onHabilitar = async (id) => {
        try {
            await DetalleEnvioService.habilitar(documento.id);
            setOnboardings((onboardings) => {
                return onboardings.map((onboarding) => {
                    if (onboarding.id === id) {
                        return { ...onboarding, estadoRegistro: "ACTIVO" };
                    }
                    return onboarding;
                });
            });
            setEvento({ envioId: documento.envio.id, accion: Evento.ACTUALIZAR_LISTA_DOCUMENTOS });
            message.success("El documento se ha habilitado correctamente.");
        } catch (error) {
            message.error("Ocurrio un error al intentar actualizar el documento");
        }
    };

    let items = [];

    if (documento?.estadoRegistro !== EstadoRegistro.INACTIVO) {
        items.push({
            label: "Ver Documento",
            key: "ver-documento",
            icon: <FilePdfOutlined style={{ fontSize: "16px" }} />,
        });

        items.push({
            label: "Subir Documento",
            key: "subir-documento",
            icon: <UploadOutlined style={{ fontSize: "16px" }} />,
        });

        items.push({
            label: "Anular Documento",
            key: "anular-documento",
            icon: <DeleteOutlined style={{ fontSize: "16px" }} />,
            danger: true,
        })
    }

    if (documento?.estadoRegistro === EstadoRegistro.INACTIVO) {
        items.push({
            label: "Habilitar Documento",
            key: "habilitar-documento",
            icon: <CheckCircleOutlined style={{ fontSize: "16px" }} />,
        })
    }

    if (!opciones.includes("mantenimiento.colaborador.tab-onboarding.subir-documento")) {
        items = items.filter(item => item.key !== "subir-documento");
    }

    if (!opciones.includes("mantenimiento.colaborador.tab-onboarding.anular-documento")) {
        items = items.filter(item => item.key !== "anular-documento");
        items = items.filter(item => item.key !== "habilitar-documento");
    }

    const permisoDocumento = permisosDocumentos.find(permiso => permiso.tipoDocumentoId === documento.envio.tipoDocumentoId);
    if (permisoDocumento) {

        if (!permisoDocumento.acciones.includes(AccionDocumento.VER)) {
            items = items.filter(item => item.key !== "ver-documento");
        }

        if (!permisoDocumento.acciones.includes(AccionDocumento.SUBIR)) {
            items = items.filter(item => item.key !== "subir-documento");
        }

        if (!permisoDocumento.acciones.includes(AccionDocumento.ANULAR)) {
            items = items.filter(item => item.key !== "anular-documento");
            items = items.filter(item => item.key !== "habilitar-documento");
        }

    }

    const onClickVerDocumento = async () => {
        setDocumentoSeleccionado(documento);
        if (documento?.estadoVistaColaborador !== "FIRMADO" && documento?.estadoVistaColaborador !== "SUBIDO") {
            if (documento.categoriaDocumento.id === CategoriaDocumento.FORMULARIOS.id) {
                const respuesta = await FormularioSeguimientoService.getUrlPlantillaDocumento(
                    documento.envio.tipoDocumentoId
                );
                setUrlPdf(respuesta.urlPlantilla);
                setVisibleModalVisorPdf(true);
            } else if (documento.categoriaDocumento.id === CategoriaDocumento.CARGA_DOCUMENTO.id) {
                Modal.error({
                    title: "Falta completar",
                    content: "El documento no ha sido cargado.",
                });
            }
        } else {
            try {
                const respuesta = await FormularioSeguimientoService.getUrlDocumento(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                    documento.id
                );
                setUrlPdf(respuesta.urlDocumento);
                setVisibleModalVisorPdf(true);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const subirDocumento = () => {
        setVisibleModalCargarDocumento(true);
        setDocumentoSeleccionado(documento);
    };

    return (
        <Dropdown menu={{ items, onClick }} placement="bottomRight" trigger="click">
            <Button type="link" className="ant-dropdown-link">
                <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
                <CaretDownOutlined
                    style={{
                        paddingLeft: "2px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#1678c1",
                    }}
                />
            </Button>
        </Dropdown>
    );
};

export default MenuAcciones;
